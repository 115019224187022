import React, { Component } from 'react';
import axios from 'axios'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap'
import UserContext from '../../../../UserContext';
import apis from './../../../../../apis';
import config from '../../../../../auth_config';
import '../../../error.css'
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
class DataListStandinginstructiontypeForm
    extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props)

        this.state = {
            SI_Action_Type: '',
            SI_Action_Typereset: false
        }
        this.ChangeHandler = this.ChangeHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
    }

    ChangeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    clearHandler = e => {
        e.preventDefault();
        this.setState({
            SI_Action_Typereset: true,
            SI_Action_Type: '',
        });
    }
    validate = () => {
        let StandinginstructiontypeError = '';
        if (!this.state.SI_Action_Type) {
            StandinginstructiontypeError = "Standing Instructiontype can not be blank";
        }
        if (StandinginstructiontypeError) {
            this.setState({ StandinginstructiontypeError })
            return false;
        }
        else {
            this.setState(
                (prevState) => ({
                    StandinginstructiontypeError: ''
                }));
        }
        return true;
    }

    confirmation_msg = (msg, cls) => {

        confirmAlert({
            customUI: ({ onClose }) => {
                return (

                    <div className="react-confirm-alert-body-msg">
                        <div className={cls}><h6 >{msg}</h6></div>


                    </div>
                );
            }
        });
    };

    submitHandler(e) {
        const isvalid = this.validate();
        if (isvalid) {
            e.preventDefault();
            const registerData = JSON.stringify(this.state);
            let updatedValues = { 'SI_Action_Type': this.state.SI_Action_Type, "user_email": this.context.user_email }
            let alertmessage = 'Submitted Successfully.';
            //console.log(updatedValues)
            axios({
                method: 'POST',
                url: config.api.SIAT,
                // 'https://fuw9emc6dj.execute-api.us-east-1.amazonaws.com/prod/proxy+:SIAT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: this.context.token
                },
                data: updatedValues,
                body: updatedValues
            })
                .then(response => {
                    //console.log(response)
                    let alertmessage = 'Record has been successfully added.';
                    this.setState({ alertmessage })
                    this.setState({ error: false })
                    this.confirmation_msg(alertmessage, "isa_success")

                })
                .catch(error => {
                    //console.log(error.response)
                    if (error.response.status === 304) {
                        let alertmessage = 'Data already exists ...Try again';
                        this.setState({ alertmessage })
                        this.setState({ error: true })
                        //console.log('Data already exists ...Try again');
                        this.confirmation_msg(alertmessage, "isa_warning")
                    }
                });
        }
        else {
            e.preventDefault();
            this.setState({ showForm: true });
        }
    }

    tohomepage = () => {
        //console.log('Redirect user to home --->');
        window.location.assign(config.azure.LOGIN_URL);
    };

    render() {
        if (this.context == undefined) {
            //console.log('Context is undefined --->');
            this.tohomepage();
            return false;
        }
        const { SI_Action_Type
        } = this.state
        return (
            <div className="container"  >
                <div role="tab" style={{ textAlign: 'center' }}>
                    <h1 className="panel-title " style={{ fontSize: 20 }}>
                        Create XSP DataList - Standing Instruction Action Type
                  </h1>
                </div>
                <Form className="form-group" style={{ alignContent: "center", width: 600, padding: 30 }} onSubmit={this.submitHandler}>
                    {/* <div className={this.state.error ? 'error' : 'success'}>{this.state.alertmessage}</div> */}
                    <FormGroup>
                        <Label>XSP DataList - Standing Instruction Action Type</Label>
                        <Input
                            type='text'
                            name='SI_Action_Type'
                            value={SI_Action_Type}
                            onChange={this.ChangeHandler} >
                        </Input>
                        <div style={{ color: "red", fontSize: 15 }}>{this.state.StandinginstructiontypeError}</div>
                    </FormGroup>
                    <Link style={{ alignItems: 'center', marginRight: 2 }} className="btn btn-primary" to="/datalist_StandingInstructionType">Back</Link>

                    <Button style={{ outline: "none", marginRight: 2 }} type='submit' className="btn btn-primary" onSubmit={this.submitHandler} >Save</Button>
                    <Button style={{ outline: "none" }} type='submit' className="btn btn-primary" onClick={this.clearHandler}>Clear</Button>

                </Form>
            </div>

        )
    }
}

export default DataListStandinginstructiontypeForm;