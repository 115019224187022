import React, { Component } from 'react';
import Header from '../Header/Header';
import HomePage from '../Home/Home';

class LandingPage extends Component {
    constructor(props) {
        super(props);
        //console.log(props);
    }

    render() {
        return (
            <div>
                <Header />
            </div>
        )
    }
}

export default LandingPage;