import React from "react";
import { Table, Container, Button, FormGroup, Input, Form, Label } from "reactstrap";
import "react-dropdown/style.css";
import "./Auditlog.css";
import config from "../../auth_config.js";
import { BeatLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
// import apis from '../../apis.js';
import UserContext from "../UserContext";
import AsyncSelect from 'react-select/async';
import ScrollToTop from 'react-scroll-up';

class Auditlog extends React.Component { 
	static contextType = UserContext;

	constructor(props) {
		super(props);
		this.state = {
			//   token: null,
			dataresponse: [],
			responsedata: {},
			isAddModel: false,
			errorresponse: null,
			loading: false,
			statusValue: { 0: "UPDATEMODEL" },
			selectedIndex: 0,
			editing: false,
			parms1: '',
			parms2: '',
			moreData: true,
			loadmore: true,
			limit: 25,
			F_limit: 25,
			isFilter: false,
			issort: false,
			filtersort: true,
			sortIcon: "▼",
		};


		// this.handleChange = this.handleChange.bind(this);
		this.ChangeHandler = this.ChangeHandler.bind(this);
		this.ChangeHandlerform = this.ChangeHandlerform.bind(this);
		// this.default_InvType_list = []
	}

	ChangeHandler = e => {
		this.setState({ [e.target.name]: e.target.value })
		this.parms1 = e.target.value
		this.setState({ F_limit: 25 })
	}
	handleChangeRole = event => {
		// console.log("kk")
		// console.log('event.target::', event.target);
		const name = event.target.name;
		const value = 'no';
		this.setState({
			[name]: value,
		});
	}


	ChangeHandlerRole = event => {
		// console.log('event.target::', event.target.value);
		const name = event.target.name;
		const value = event.target.value;
		this.setState({
			[name]: value,
		});
		if (event.target.value == 'Read Only') {
			if (this.state.NotificationCreate === 'yes' || this.state.NotificationTypeIRR === 'yes' || this.state.NotificationTypeANN === 'yes') {
				alert("NotificationCreate, NotificationTypeIRR ,NotificationTypeANN will be set to NO as role name is Read Only")
			}
			this.state.isDisable = true
			this.state.NotificationCreate = 'no'
			this.state.NotificationTypeIRR = 'no'
			this.state.NotificationTypeANN = 'no'

		}
		else {

			this.setState({ isDisable: false })
		}
	}


	componentWillUnmount() {
		// remove event listener
		window.removeEventListener('scroll', this.handleScroll, false);

	}

	ChangeHandlerform = e => {
		this.setState({ [e.target.name]: e.target.value })
		this.parms2 = e.target.value
		this.setState({ F_limit: 25 })
	}

	handleChange(event) {
		//console.log('event.target::', event.target);
		const name = event.target.name;
		const value = event.target.value;
		this.setState({
			[name]: value,
		});
	}

	async filteredloadModelData(closeModal) {
		this.setState({ limit: 25 })
		//console.log("RESET LIMIT - ", this.state.F_limit)
		try {
			let url = config.api.audit + ':' + this.state.issort + ':' + this.state.F_limit
			this.setState({ loading: true });

			//console.log(this.parms1, this.parms2, this.parms1 !== 'Select', this.parms1 !== undefined & this.parms2 !== undefined)
			if (this.parms1 !== undefined & this.parms2 !== undefined & this.parms1 !== 'Select' & this.parms1 !== '' & this.parms2 !== '') {
				this.setState({ isFilter: true })
				//console.log('filteredloadModelData', this.state.isFilter)
				const key = (this.parms1).replace(/ /g, "_");
				let params = {
					"filters": key,
					"param2": this.parms2
				};
				//console.log(this.param1, this.parms2)
				let query = Object.keys(params)
					.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
					.join('&');
				//console.log(params)
				url = config.api.audit + ':' + this.state.issort + ':' + this.state.F_limit + "?" + query
			}
			else {
				this.setState({ isFilter: false })
			}
			const api_response = await fetch(url,
				// `https://uzro6za8jc.execute-api.us-east-1.amazonaws.com/new/proxy:RIS?`+query,
				{
					method: "GET",
					headers: {
						Authorization: this.context.token,
					},
				}
			);
			const json_response = await api_response.json();
			console.table("json_response --> ", json_response);
			json_response['Count'] === 0 ? this.setState({ moreData: false }) : this.setState({ moreData: true })
			//console.log('LastEvaluatedKey', json_response['LastEvaluatedKey'], json_response['Count'])
			'LastEvaluatedKey' in json_response ? this.setState({ loadmore: true }) : this.setState({ loadmore: false })
			const items = json_response['Items']
			//console.log("Json Items---->>", items)
			//console.log(items.length)
			if (items.length < 25 && this.state.loadmore) {
				this.handlefilter()
			}
			if (closeModal) {
				this.setState({ dataresponse: items, isAddModel: false }, () => {
					window.$('#add-modal').modal('hide')
				});
			} else {
				this.setState({ dataresponse: items });
			}
			this.setState({ loading: false });
		} catch (errorresponse) {
			console.table("Exception : ", errorresponse);
			this.setState({ loading: false });
		}
	}

	async loadModelData(closeModal) {
		try {
			this.setState({ loading: true });

			const api_response = await fetch(config.api.audit + ':' + this.state.issort + ':' + this.state.limit,
				// `https://uzro6za8jc.execute-api.us-east-1.amazonaws.com/new/proxy:RIS?`,
				{
					method: "GET",
					headers: {
						Authorization: this.context.token,
					},
				}
			);
			const json_response = await api_response.json();
			console.table("json_response --> ", json_response);
			//console.log('LastEvaluatedKey', json_response['LastEvaluatedKey'])
			'LastEvaluatedKey' in json_response ? this.setState({ loadmore: true }) : this.setState({ loadmore: false })
			const items = json_response['Items']
			//console.log("Json Items---->>", items)
			if (closeModal) {
				this.setState({ dataresponse: items, isAddModel: false }, () => {
					window.$('#add-modal').modal('hide')
				});
			} else {
				this.setState({ dataresponse: items });
			}
			this.setState({ loading: false });
		} catch (errorresponse) {
			console.table("Exception : ", errorresponse);
			this.setState({ loading: false });
		}
	}

	async componentDidMount() {
		this.listener = window.addEventListener('scroll', this.handleScroll, false);
		//console.log("context from landing page", this.context);
		// console.log("token from landingpage  : ", this.context.token);
		this.loadModelData()

	}


	renderTableData() {
		return this.state.dataresponse.map((model_data, index) => {
			const {
				Table_Name,
				Change_Type,
				Change_Time,
				Changed_By,
				Log_Identifier,
				Key_Columns,
				Key_Values,
				Old_Value,
				New_Value
			} = model_data;

			return (
				<tr>
					<td>
						<div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{Table_Name}</div>
					</td>
					<td>
						<div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{Change_Type}</div>
					</td>
					<td>
						<div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{Change_Time}</div>
					</td>
					<td>
						<div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{Changed_By}</div>
					</td>
					<td>
						<div style={{ whiteSpace: 'pre-wrap', overflowWrap: "anywhere" }}>{Log_Identifier}</div>
					</td>
					<td>
						<div style={{ whiteSpace: 'pre-wrap',wordBreak:"break-all", overflowWrap: "anywhere" }}>{Key_Columns}</div>
					</td>
					<td>
						<div style={{ whiteSpace: 'pre-wrap',wordBreak:"break-all", overflowWrap: 'anywhere' }}>{Key_Values}</div>
					</td>
					<td>
						<div style={{ whiteSpace: 'pre-wrap',wordBreak:"break-all", overflowWrap: 'anywhere' }}>{Old_Value}</div>
					</td>
					<td>
						<div style={{ whiteSpace: 'pre-wrap',wordBreak:"break-all", overflowWrap: 'anywhere' }}>{New_Value}</div>
					</td>
				</tr>
			);
		});
	}



	tohomepage = () => {
		window.location.assign(config.azure.LOGIN_URL);
	};

	handleScroll = () => {
		//console.log(this.isFilter, this.state.isFilter, this.state.F_limit, this.state.limit, this.state.loadmore, this.state.moreData)
		// console.log(window.innerHeight, window.scrollY, document.body.offsetHeight, this.state.loadmore)
		if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight & this.state.loadmore & this.state.moreData) {
			//console.log("you're at the bottom of the page");
			//console.log(window.innerHeight, window.scrollY, document.body.offsetHeight, this.state.loadmore)
			if (this.state.isFilter) {
				this.setState({ F_limit: this.state.F_limit + 5 })
				//console.log("RECORDS LOADED----->>>", this.state.F_limit)
				this.filteredloadModelData()
				//console.log("this.state.isFilter", this.state.isFilter)
			}
			else {
				this.setState({ limit: this.state.limit + 5 })
				//console.log("RECORDS LOADED----->>>", this.state.limit)
				this.loadModelData()
			}


		}
	};

	ascendingsort = () => {
		if (this.state.isFilter) {
			this.state.issort = true
			this.filteredloadModelData()
		}
		else {
			this.state.issort = true
			this.loadModelData()
		}
	}

	descendingsort = () => {
		if (this.state.isFilter) {
			this.state.issort = false
			this.filteredloadModelData()
		}
		else {
			this.state.issort = false
			this.loadModelData()
		}
	}

	onsort = () => {
		if (this.state.filtersort) {
			this.state.filtersort = false
			this.state.sortIcon = "▲"
			this.ascendingsort()
		}
		else {
			this.state.filtersort = true
			this.state.sortIcon = "▼"
			this.descendingsort()
		}
	}

	handlefilter = () => {
		this.setState({ F_limit: this.state.F_limit + 5 })
		//console.log("RECORDS LOADED----->>>", this.state.F_limit)
		this.filteredloadModelData()
		//console.log("this.state.isFilter", this.state.isFilter)
	};

	render() {
		if (this.context == undefined) {
			this.tohomepage();
			return false;
		}

		const {
			baseinputValue
		} = this.state
		return (
			<div onScroll={this.handleScroll}>
				<Container>
					<div role="tab" style={{ textAlign: 'center' }}>
						<h1 className="panel-title " style={{ fontSize: 20 }}>
							Audit Log
            </h1>
					</div>
					<Table style={{ alignContent: "center", width: 600, marginLeft: -100 }} >
						<thead>
							<tr>
								<td><Label>Filtering Method</Label></td>
								<td><Label>{baseinputValue}</Label></td>
							</tr>
							<tr>
								<td>
									<Input
										type='select'
										name='baseinputValue'
										value={baseinputValue}
										onChange={this.ChangeHandler}
									>

										<option>Select</option>
										<option>Table_Name</option>
										<option>Change_Type</option>
										<option>Change_Time</option>
										<option>Changed_By</option>
										<option>Log_Identifier</option>
										<option>Key_Columns</option>
										<option>Key_Values</option>
										<option>Old_Value</option>
										<option>New_Value</option>
									</Input>
								</td>
								<td>
									<Input
										type='text'
										name='inputvalue'
										value={this.inputValue}
										onChange={this.ChangeHandlerform}
									/>
								</td>
								<td>
									<button style={{ outline: "none" }} onClick={() => { this.filteredloadModelData(); }} id="submit-btn" type="button" className="btn btn-primary btn btn-secondary" >Search</button>
								</td>
							</tr>
						</thead>
					</Table>

					<div style={{ fontSize: 15 }}>
						<Table className="model-table-margin table table-bordered table-hover" style={{ alignContent: "center", width: 1360, height: 5, marginLeft: -100, "overflow": "hidden", "overflow-y": "scroll" }}>
							<thead className="tableFixHead">
								<tr>
									<th>Table Name</th>
									<th>Change Type</th>
									<th onClick={this.onsort}>Change Time
									{this.state.sortIcon}</th>
									<th>Changed By </th>
									<th style={{ width: 300 }}>Log Identifier</th>
									<th style={{ width: 400 }}>Key Columns</th>
									<th style={{ width: 400 }}>Key Values</th>
									<th>Old Value </th>
									<th>New Value </th>
								</tr>
							</thead>
							<tbody>{this.renderTableData()}</tbody>
						</Table>
						<ScrollToTop smooth showUnder={5} duration={1000}  color="#6f00ff" >
							<button
								style={{
									display: "inline-block",
									marginLeft: "15px"
								}} >UP
          </button>
						</ScrollToTop>
						<div className="load-spinner">
							<BeatLoader size={25} color='blue' loading={this.state.loading} />
						</div>
						<div className={this.state.moreData ? 'hidden' : ''}>
							<p><strong>No more data try again.</strong></p>
						</div>
					</div>
				</Container>
			</div>
		);

	}
}

export default Auditlog;
