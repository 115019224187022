const api_dynamo = 'https://n3hovoyvzg.execute-api.us-east-1.amazonaws.com/sbx/proxy+'
const api_esdl = 'https://ks6kk21ctd.execute-api.us-east-1.amazonaws.com/prod/'
const api_dynamo_dr = 'https://futcqhubo6.execute-api.us-west-2.amazonaws.com/sbx/proxy+'
const api_esdl_dr = 'https://pqbn8sacre.execute-api.us-west-2.amazonaws.com/prod/'
const api_dynamo_nonprod = 'https://5jnqq1goed.execute-api.us-east-1.amazonaws.com/nonprd/proxy+'
const api_esdl_nonprod = 'https://hiti2b4a96.execute-api.us-east-1.amazonaws.com/prod/'
const api_dynamo_nonprod_dr = 'https://ni5pdj9kbg.execute-api.us-west-2.amazonaws.com/nonprd/proxy+'
const api_esdl_nonprod_dr = 'https://w001zfymi5.execute-api.us-west-2.amazonaws.com/prod/'
const api_dynamo_prod = 'https://ynlajg14a0.execute-api.us-east-1.amazonaws.com/prd/proxy+'
const api_esdl_prod = 'https://nnlvjzxtkj.execute-api.us-east-1.amazonaws.com/prod/'
const api_dynamo_prod_dr = 'https://to5aulwscd.execute-api.us-west-2.amazonaws.com/prd/proxy+'
const api_esdl_prod_dr = 'https://9jdvh8x9sl.execute-api.us-west-2.amazonaws.com/prod/'
const local_api = {
  DataListFundManageAddPOST: 'http://127.0.0.1:8000/xsp/DataList_fund_manager/',
  DataListInvestMentStrategyPOST: 'http://127.0.0.1:8000/xsp/DataList_Investment_Strategy/',
  DataListStandingInstructionPOST: 'http://127.0.0.1:8000/xsp/DataList_Standing_instruction_type/',
  RecipentInvestmentAccountPOST: 'http://localhost:8000/xsp/Account_Inv_Strategy/',
  RecipentInvestmentPOST: 'http://localhost:8000/xsp/Inv_Strategy/',
  StandingInstructionPOST: 'http://localhost:8000/xsp/standing_instruction/',
}

const local = {
  s3: {
    REGION: "us-east-1"
    // BUCKET: "pgi-orchestration-fron-umafrontendreactui152c86-1q6jnkhcba8vv",
  },

  error_dashboard:{     
    error:"https://pgi.pilot.theprincipal.net/odserrorui/"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_1NTEBt1Gd",
    APP_CLIENT_ID: "2djfmnfe9fgo545pk3s3djo0b5",
    USER_POOL_WEB_CLIENT_ID: "2djfmnfe9fgo545pk3s3djo0b5",
    USER_POOL_DOMAIN_NAME:
      "xsp-dashboard-userpool-domain.auth.us-east-1.amazoncognito.com",
    VIEW_ROLE: "XSP_DASHBOARD_VIEW_GROUP",
    UPDATE_ROLE: "XSP_DASHBOARD_UPDATE_GROUP",
    // IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID"
  },
  azure: {
    LOGIN_URL:
      "https://xsp-dashboard-userpool-domain.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=xsp-dashboard-identity-provider&redirect_uri=http://localhost:3000/&response_type=TOKEN&client_id=2djfmnfe9fgo545pk3s3djo0b5&state=thisisstate09283781918&scope=xsp-dashboard-user-pool-resource-server-identifier/access-dynamo-data aws.cognito.signin.user.admin email openid",

  },
  api: {
    REDIRECT_SIGN_IN: "http://localhost:3000/", // As seen in app do we need to change it
    REDIRECT_SIGN_OUT: "http://localhost:3000/",
    SI: api_dynamo + ':SI',
    RISA: api_dynamo + ':RISA',
    RIS: api_dynamo + ':RIS',
    FM: api_dynamo + ':FM',
    IS: api_dynamo + ':IS',
    SIAT: api_dynamo + ':SIAT',
    audit: api_dynamo + ':audit',
    ACKNAK: api_dynamo + ':ACKNAK',
    CountryList: api_esdl + 'countrylist?filters=',
    CurrencyList: api_esdl + 'currencylist?filters=',
    PortfoliosList: api_esdl + 'portfolios?filters=',
    CorpActiontype: api_esdl + 'corpactiontypelist?filters=',
  },
};

const local_dr = {
  s3: {
    REGION: "us-west-2",
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "",
  },
  error_dashboard:{     
    error:"https://pgi.pilot.theprincipal.net/odserrorui/"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_D2kmPD4B8",
    APP_CLIENT_ID: "mr8bfrph6lddobeq6jnj976rf",
    USER_POOL_WEB_CLIENT_ID: "mr8bfrph6lddobeq6jnj976rf",
    USER_POOL_DOMAIN_NAME:
      "xsp-dashboard-userpool-domain.auth.us-west-2.amazoncognito.com",
    VIEW_ROLE: "XSP_DASHBOARD_VIEW_GROUP",
    UPDATE_ROLE: "XSP_DASHBOARD_UPDATE_GROUP",
    // IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID"
  },
  azure: {
    LOGIN_URL:
      "https://xsp-dashboard-userpool-domain.auth.us-west-2.amazoncognito.com/oauth2/authorize?identity_provider=xsp-dashboard-identity-provider&redirect_uri=http://localhost:3000/&response_type=TOKEN&client_id=mr8bfrph6lddobeq6jnj976rf&state=thisisstate09283781918&scope=xsp-dashboard-user-pool-resource-server-identifier/access-dynamo-data aws.cognito.signin.user.admin email openid",
  },
  api: {
    REDIRECT_SIGN_IN: "http://localhost:3000/", // As seen in app do we need to change it
    REDIRECT_SIGN_OUT: "http://localhost:3000/",
    SI: api_dynamo_dr + ':SI',
    RISA: api_dynamo_dr + ':RISA',
    RIS: api_dynamo_dr + ':RIS',
    FM: api_dynamo_dr + ':FM',
    IS: api_dynamo_dr + ':IS',
    SIAT: api_dynamo_dr + ':SIAT',
    audit: api_dynamo_dr + ':audit',
    ACKNAK: api_dynamo_dr + ':ACKNAK',
    CountryList: api_esdl_dr + 'countrylist?filters=',
    CurrencyList: api_esdl_dr + 'currencylist?filters=',
    PortfoliosList: api_esdl_dr + 'portfolios?filters=',
    CorpActiontype: api_esdl_dr + 'corpactiontypelist?filters=',
  },
};

const sbx = {
  s3: {
    REGION: "us-east-1"
    // BUCKET: "pgi-orchestration-fron-umafrontendreactui152c86-1q6jnkhcba8vv",
  },
  error_dashboard:{     
    error:"https://pgi.pilot.theprincipal.net/odserrorui/"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_NILErZJE4",
    APP_CLIENT_ID: "vtv659lkuk4oo18j8qcm66e4o",
    USER_POOL_WEB_CLIENT_ID: "vtv659lkuk4oo18j8qcm66e4o",
    USER_POOL_DOMAIN_NAME:
      "https://xsp-dashboard-userpool-domain-sbx.auth.us-east-1.amazoncognito.com",
    VIEW_ROLE: "XSP_DASHBOARD_VIEW_GROUP",
    UPDATE_ROLE: "XSP_DASHBOARD_UPDATE_GROUP",
    // IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID"
  },
  azure: {
    LOGIN_URL:
      "https://xsp-dashboard-userpool-domain-sbx.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=xsp-dashboard-identity-provider&redirect_uri=https://d1wljueqiigm4c.cloudfront.net&response_type=TOKEN&client_id=vtv659lkuk4oo18j8qcm66e4o&state=thisisstate09283781918&scope=xsp-dashboard-user-pool-resource-server-identifier/access-dynamo-data aws.cognito.signin.user.admin email openid",
  },
  api: {
    REDIRECT_SIGN_IN: "https://d1wljueqiigm4c.cloudfront.net/", // As seen in app do we need to change it
    REDIRECT_SIGN_OUT: "https://d1wljueqiigm4c.cloudfront.net/",
    SI: api_dynamo + ':SI',
    RISA: api_dynamo + ':RISA',
    RIS: api_dynamo + ':RIS',
    FM: api_dynamo + ':FM',
    IS: api_dynamo + ':IS',
    SIAT: api_dynamo + ':SIAT',
    audit: api_dynamo + ':audit',
    ACKNAK: api_dynamo + ':ACKNAK',
    CountryList: api_esdl + 'countrylist?filters=',
    CurrencyList: api_esdl + 'currencylist?filters=',
    PortfoliosList: api_esdl + 'portfolios?filters=',
    CorpActiontype: api_esdl + 'corpactiontypelist?filters=',
  },
};

const sbx_dr = {
  s3: {
    REGION: "us-west-2"
    // BUCKET: "pgi-orchestration-fron-umafrontendreactui152c86-1q6jnkhcba8vv",
  },
  error_dashboard:{     
    error:"https://pgi.pilot.theprincipal.net/odserrorui/"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_D2kmPD4B8",
    APP_CLIENT_ID: "mr8bfrph6lddobeq6jnj976rf",
    USER_POOL_WEB_CLIENT_ID: "mr8bfrph6lddobeq6jnj976rf",
    USER_POOL_DOMAIN_NAME:
      "https://xsp-dashboard-userpool-domain-sbx.auth.us-west-2.amazoncognito.com",
    VIEW_ROLE: "XSP_DASHBOARD_VIEW_GROUP",
    UPDATE_ROLE: "XSP_DASHBOARD_UPDATE_GROUP",
    // IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID"
  },
  azure: {
    LOGIN_URL:
      "https://xsp-dashboard-userpool-domain-sbx.auth.us-west-2.amazoncognito.com/oauth2/authorize?identity_provider=xsp-dashboard-identity-provider&redirect_uri=https://d1wljueqiigm4c.cloudfront.net&response_type=TOKEN&client_id=mr8bfrph6lddobeq6jnj976rf&state=thisisstate09283781918&scope=xsp-dashboard-user-pool-resource-server-identifier/access-dynamo-data aws.cognito.signin.user.admin email openid",
  },
  api: {
    REDIRECT_SIGN_IN: "https://d1wljueqiigm4c.cloudfront.net/", // As seen in app do we need to change it
    REDIRECT_SIGN_OUT: "https://d1wljueqiigm4c.cloudfront.net/",
    SI: api_dynamo_dr + ':SI',
    RISA: api_dynamo_dr + ':RISA',
    RIS: api_dynamo_dr + ':RIS',
    FM: api_dynamo_dr + ':FM',
    IS: api_dynamo_dr + ':IS',
    SIAT: api_dynamo_dr + ':SIAT',
    audit: api_dynamo_dr + ':audit',
    ACKNAK: api_dynamo_dr + ':ACKNAK',
    CountryList: api_esdl_dr + 'countrylist?filters=',
    CurrencyList: api_esdl_dr + 'currencylist?filters=',
    PortfoliosList: api_esdl_dr + 'portfolios?filters=',
    CorpActiontype: api_esdl_dr + 'corpactiontypelist?filters=',
  },
};

const nonprod = {
  s3: {
    REGION: "us-east-1"
    // BUCKET: "pgi-orchestration-fron-umafrontendreactui152c86-1q6jnkhcba8vv",
  },
  error_dashboard:{     
    error:"https://pgi.pilot.theprincipal.net/odserrorui/"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_ONqt4hDuX",
    APP_CLIENT_ID: "1q37lntpth5vrecuo5thnsj6vi",
    USER_POOL_WEB_CLIENT_ID: "1q37lntpth5vrecuo5thnsj6vi",
    USER_POOL_DOMAIN_NAME:
      "xsp-dashboard-userpool-domain-non-prod.auth.us-east-1.amazoncognito.com",
    VIEW_ROLE: "XSP_DASHBOARD_VIEW_GROUP",
    UPDATE_ROLE: "XSP_DASHBOARD_UPDATE_GROUP",
    // IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID"
  },
  azure: {
    LOGIN_URL:
      "https://xsp-dashboard-userpool-domain-non-prod.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=xsp-dashboard-identity-provider&redirect_uri=https://d3kwwpak0kdz21.cloudfront.net&response_type=TOKEN&client_id=1q37lntpth5vrecuo5thnsj6vi&state=thisisstate09283781918&scope=xsp-dashboard-user-pool-resource-server-identifier/access-dynamo-data aws.cognito.signin.user.admin email openid",
  },
  api: {
    REDIRECT_SIGN_IN: "https://d3kwwpak0kdz21.cloudfront.net/", // As seen in app do we need to change it
    REDIRECT_SIGN_OUT: "https://d3kwwpak0kdz21.cloudfront.net/",
    SI: api_dynamo_nonprod + ':SI',
    RISA: api_dynamo_nonprod + ':RISA',
    RIS: api_dynamo_nonprod + ':RIS',
    FM: api_dynamo_nonprod + ':FM',
    IS: api_dynamo_nonprod + ':IS',
    SIAT: api_dynamo_nonprod + ':SIAT',
    audit: api_dynamo_nonprod + ':audit',
    ACKNAK: api_dynamo_nonprod + ':ACKNAK',
    CountryList: api_esdl_nonprod + 'countrylist?filters=',
    CurrencyList: api_esdl_nonprod + 'currencylist?filters=',
    PortfoliosList: api_esdl_nonprod + 'portfolios?filters=',
    CorpActiontype: api_esdl_nonprod + 'corpactiontypelist?filters=',
  },
};

const nonprod_dr = {
  s3: {
    REGION: "us-west-2"
    // BUCKET: "pgi-orchestration-fron-umafrontendreactui152c86-1q6jnkhcba8vv",
  },
  error_dashboard:{     
    error:"https://pgi.theprincipal.net/odserrorui/"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_H3K6Z2Luc",
    APP_CLIENT_ID: "1fhq0vin35squdn910rlngd3d9",
    USER_POOL_WEB_CLIENT_ID: "1fhq0vin35squdn910rlngd3d9",
    USER_POOL_DOMAIN_NAME:
      "xsp-dashboard-userpool-domain-non-prod.auth.us-west-2.amazoncognito.com",
    VIEW_ROLE: "XSP_DASHBOARD_VIEW_GROUP",
    UPDATE_ROLE: "XSP_DASHBOARD_UPDATE_GROUP",
    // IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID"
  },
  azure: {
    LOGIN_URL:
      "https://xsp-dashboard-userpool-domain-non-prod.auth.us-west-2.amazoncognito.com/oauth2/authorize?identity_provider=xsp-dashboard-identity-provider&redirect_uri=https://d3kwwpak0kdz21.cloudfront.net&response_type=TOKEN&client_id=1fhq0vin35squdn910rlngd3d9&state=thisisstate09283781918&scope=xsp-dashboard-user-pool-resource-server-identifier/access-dynamo-data aws.cognito.signin.user.admin email openid",
  },
  api: {
    REDIRECT_SIGN_IN: "https://d3kwwpak0kdz21.cloudfront.net/", // As seen in app do we need to change it
    REDIRECT_SIGN_OUT: "https://d3kwwpak0kdz21.cloudfront.net/",
    SI: api_dynamo_nonprod_dr + ':SI',
    RISA: api_dynamo_nonprod_dr + ':RISA',
    RIS: api_dynamo_nonprod_dr + ':RIS',
    FM: api_dynamo_nonprod_dr + ':FM',
    IS: api_dynamo_nonprod_dr + ':IS',
    SIAT: api_dynamo_nonprod_dr + ':SIAT',
    audit: api_dynamo_nonprod_dr + ':audit',
    ACKNAK: api_dynamo_nonprod_dr + ':ACKNAK',
    CountryList: api_esdl_nonprod_dr + 'countrylist?filters=',
    CurrencyList: api_esdl_nonprod_dr + 'currencylist?filters=',
    PortfoliosList: api_esdl_nonprod_dr + 'portfolios?filters=',
    CorpActiontype: api_esdl_nonprod_dr + 'corpactiontypelist?filters=',
  },
};

const prod = {
  s3: {
    REGION: "us-east-1"
    // BUCKET: "pgi-orchestration-fron-umafrontendreactui152c86-1q6jnkhcba8vv",
  },
  error_dashboard:{     
    error:"https://pgi.theprincipal.net/odserrorui/"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_RP7YH9EBQ",
    APP_CLIENT_ID: "4rti5fmj7toueplmgm1041usnt",
    USER_POOL_WEB_CLIENT_ID: "4rti5fmj7toueplmgm1041usnt",
    USER_POOL_DOMAIN_NAME:
      "xsp-dashboard-userpool-domain-prd.auth.us-east-1.amazoncognito.com",
    VIEW_ROLE: "XSP_DASHBOARD_VIEW_GROUP",
    UPDATE_ROLE: "XSP_DASHBOARD_UPDATE_GROUP",
    // IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID"
  },
  azure: {
    LOGIN_URL:
      "https://xsp-dashboard-userpool-domain-prd.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=xsp-dashboard-identity-provider&redirect_uri=https://dwt9m2tq4sww3.cloudfront.net&response_type=TOKEN&client_id=4rti5fmj7toueplmgm1041usnt&state=thisisstate09283781918&scope=xsp-dashboard-user-pool-resource-server-identifier/access-dynamo-data aws.cognito.signin.user.admin email openid",
  },
  api: {
    REDIRECT_SIGN_IN: "https://dwt9m2tq4sww3.cloudfront.net/", // As seen in app do we need to change it
    REDIRECT_SIGN_OUT: "https://dwt9m2tq4sww3.cloudfront.net/",
    SI: api_dynamo_prod + ':SI',
    RISA: api_dynamo_prod + ':RISA',
    RIS: api_dynamo_prod + ':RIS',
    FM: api_dynamo_prod + ':FM',
    IS: api_dynamo_prod + ':IS',
    SIAT: api_dynamo_prod + ':SIAT',
    audit: api_dynamo_prod + ':audit',
    ACKNAK: api_dynamo_prod + ':ACKNAK',
    CountryList: api_esdl_prod + 'countrylist?filters=',
    CurrencyList: api_esdl_prod + 'currencylist?filters=',
    PortfoliosList: api_esdl_prod + 'portfolios?filters=',
    CorpActiontype: api_esdl_prod + 'corpactiontypelist?filters=',
  },
};

const prod_dr = {
  s3: {
    REGION: "us-west-2"
    // BUCKET: "pgi-orchestration-fron-umafrontendreactui152c86-1q6jnkhcba8vv",
  },
  error_dashboard:{     
    error:"https://pgi.theprincipal.net/odserrorui/"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_kXxqOK1MT",
    APP_CLIENT_ID: "1nndc526en6f16vqdo3g23atmp",
    USER_POOL_WEB_CLIENT_ID: "1nndc526en6f16vqdo3g23atmp",
    USER_POOL_DOMAIN_NAME:
      "xsp-dashboard-userpool-domain-prd.auth.us-west-2.amazoncognito.com",
    VIEW_ROLE: "XSP_DASHBOARD_VIEW_GROUP",
    UPDATE_ROLE: "XSP_DASHBOARD_UPDATE_GROUP",
    // IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID"
  },
  azure: {
    LOGIN_URL:
      "https://xsp-dashboard-userpool-domain-prd.auth.us-west-2.amazoncognito.com/oauth2/authorize?identity_provider=xsp-dashboard-identity-provider&redirect_uri=https://dwt9m2tq4sww3.cloudfront.net&response_type=TOKEN&client_id=1nndc526en6f16vqdo3g23atmp&state=thisisstate09283781918&scope=xsp-dashboard-user-pool-resource-server-identifier/access-dynamo-data aws.cognito.signin.user.admin email openid",
  },
  api: {
    REDIRECT_SIGN_IN: "https://dwt9m2tq4sww3.cloudfront.net/", // As seen in app do we need to change it
    REDIRECT_SIGN_OUT: "https://dwt9m2tq4sww3.cloudfront.net/",
    SI: api_dynamo_prod_dr + ':SI',
    RISA: api_dynamo_prod_dr + ':RISA',
    RIS: api_dynamo_prod_dr + ':RIS',
    FM: api_dynamo_prod_dr + ':FM',
    IS: api_dynamo_prod_dr + ':IS',
    SIAT: api_dynamo_prod_dr + ':SIAT',
    audit: api_dynamo_prod_dr + ':audit',
    ACKNAK: api_dynamo_prod_dr + ':ACKNAK',
    CountryList: api_esdl_prod_dr + 'countrylist?filters=',
    CurrencyList: api_esdl_prod_dr + 'currencylist?filters=',
    PortfoliosList: api_esdl_prod_dr + 'portfolios?filters=',
    CorpActiontype: api_esdl_prod_dr + 'corpactiontypelist?filters=',
  },
};
// TODO: we will uncomment this whole code when we have all three env
const config =
  process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : process.env.REACT_APP_STAGE === 'prod_dr'
      ? prod_dr
      : process.env.REACT_APP_STAGE === 'nonprod'
        ? nonprod
        : process.env.REACT_APP_STAGE === 'nonprod_dr'
          ? nonprod_dr
          : process.env.REACT_APP_STAGE === 'sbx'
            ? sbx
            : process.env.REACT_APP_STAGE === 'sbx_dr'
              ? sbx_dr
              : local;

// TODO: Uncomment this for Non prod and sandbox
// const config =
//   process.env.REACT_APP_STAGE === 'nonprod'
//     ? nonprod
//     : process.env.REACT_APP_STAGE === 'nonprod_dr'
//       ? nonprod_dr
//       : process.env.REACT_APP_STAGE === 'sbx'
//         ? sbx
//         : process.env.REACT_APP_STAGE === 'sbx_dr'
//           ? sbx_dr
//           : local;


// const config =
//   process.env.REACT_APP_STAGE === 'sbx'
//     ? sbx
//     : process.env.REACT_APP_STAGE === 'sbx_dr'
//       ? sbx_dr
//       : local;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
