import React, { Component } from 'react';
import axios from 'axios'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap'
import UserContext from "../../../UserContext";
import apis from '../../../../apis';
import config from '../../../../auth_config';
import AsyncSelect from 'react-select/async'
import '../../error.css'
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';


class RecipentInvestmentCreate extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props)

        this.state = {
            Investment_Strategy: "",
            RecipentCode: "",
            NotificationTypeIRR: "",
            NotificationCreate: "",
            NotificationTypeANN: "",
            First_Name: "",
            Last_Name: "",
            Role_Name: "",
            Email_Address: "",
            SK: "",
            error: false,
            disable: false,
            reset: false,
            ishidden: false,
        }
        this.ChangeHandler = this.ChangeHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.deafult_investment_list = [];
    }

    componentDidMount() {
        //console.log("page loaded")
        // this.fetchDataFM_t("")
        this.fetchDataIS_t("")
    }

    validate = () => {
        let Investment_StrategyError = '';
        let RecipentCodeError = '';
        let NotificationTypeIRRError = '';
        let NotificationCreateError = '';
        let NotificationTypeANNError = '';
        let First_NameError = '';
        let Last_NameError = '';
        let Role_NameError = '';
        let Email_AddressError = '';
        if (!this.state.Investment_Strategy) {
            Investment_StrategyError = "Investment Strategy can not be blank";
        }
        if (!this.state.RecipentCode) {
            RecipentCodeError = "Recipent Code can not be blank";
        }
        if (!this.state.NotificationTypeIRR) {
            NotificationTypeIRRError = "Notification Type IRR can not be blank";
        }
        if (!this.state.NotificationCreate) {
            NotificationCreateError = "Notification Create can not be blank";
        }
        if (!this.state.NotificationTypeANN) {
            NotificationTypeANNError = "Notification Type ANN can not be blank";
        }
        if (!this.state.First_Name) {
            First_NameError = "First Name can not be blank";
        }
        if (!this.state.Last_Name) {
            Last_NameError = "Last Name can not be blank";
        }
        if (!this.state.Role_Name) {
            Role_NameError = "Role Name can not be blank";
        }
        if (!this.state.Email_Address) {
            Email_AddressError = "Email Address can not be blank";
        } else if (!this.state.Email_Address.includes('@')) {
            Email_AddressError = "Please enter a valid email address";
        }
        if (Investment_StrategyError || RecipentCodeError || NotificationTypeIRRError || NotificationCreateError ||
            NotificationTypeANNError || First_NameError || Last_NameError || Role_NameError || Email_AddressError) {
            this.setState({
                Investment_StrategyError, RecipentCodeError, NotificationTypeIRRError, NotificationCreateError,
                NotificationTypeANNError, First_NameError, Last_NameError, Role_NameError, Email_AddressError
            })
            return false;
        }
        else {
            this.setState(
                (prevState) => ({
                    Investment_StrategyError: '',
                    RecipentCodeError: '',
                    NotificationCreateError: '',
                    NotificationTypeIRRError: '',
                    NotificationTypeANNError: '',
                    First_NameError: '',
                    Last_NameError: '',
                    Role_NameError: '',
                    Email_AddressError: '',
                }));
        }
        return true;
    }


    //----------------------------- ------------------
    fetchDataIS = (inputValue, callback) => {
        if (!inputValue) {
            callback([]);
        } else {
            let params = {
                "filters": "Investment_Strategy",
                "param2": inputValue
            };
            let query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
            //console.log(params)
            setTimeout(() => {
                fetch(config.api.IS + "?" + query, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: this.context.token
                    },
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        const tempArray = [];
                        let len = data.length;
                        (data.length < len) ? len = data.length : len = data.length;
                        for (var i = 0; i < len; i++) {
                            const element = data[i]
                            tempArray.push({ label: `${element.Investment_Strategy}` })
                        }
                        callback(tempArray);
                    })
                    .catch((error) => {
                        //console.log(error, "catch the hoop")
                    });
            });
        }
    }

    fetchDataIS_t = (inputValue) => {
        // if (!inputValue) {
        //     callback([]);
        // } else {
        let params = {
            "param1": "Investment_Strategy",
            "param2": inputValue
        };
        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        //console.log(params)
        setTimeout(() => {
            fetch(config.api.IS + "?" + query, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: this.context.token
                },
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((result) => {
                    // const tempArray = [];
                    const data = result.sort((a, b) => (a.Investment_Strategy > b.Investment_Strategy ? 1 : -1))

                    let len = data.length;
                    (data.length < len) ? len = data.length : len = data.length;
                    for (var i = 0; i < len; i++) {
                        const element = data[i]
                        this.deafult_investment_list.push({ label: `${element.Investment_Strategy}` })
                        // tempArray.push({ label: `${element.Investment_Strategy}`})
                    }
                    // callback(tempArray);
                })
                .catch((error) => {
                    //console.log(error, "catch the hoop")
                });
        });
    }

    onSearchChangeIS = (selectedOption, name) => {
        if (selectedOption) {
            //console.log("-------------------", selectedOption.label, name)

            this.setState({ [name]: selectedOption.label, reset: false, ishidden: false, selectedOption: this.selectedOption })
        }
        //console.log("..............", this.Investment_Strategy)
    };

    ChangeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
        this.setState({ ['SK']: "#RecipentCode#" + this.state.RecipentCode })
        if (e.target.value == 'Read Only') {
            this.setState({ disable: true })
            this.setState({ NotificationCreate: 'N' })
            this.setState({ NotificationTypeIRR: 'N' })
            this.setState({ NotificationTypeANN: 'N' })
        }
        else {

            this.setState({ disable: false })


        }
        // //console.log("....",e.target.name)
    }

    clearHandler = e => {
        e.preventDefault();
        this.state.selectedOption = null
        this.state.ishidden = true
        this.setState({
            reset: true,
            Investment_Strategy: '',
            RecipentCode: '',
            Role_Name: '',
            NotificationTypeANN: '',
            NotificationTypeIRR: '',
            NotificationCreate: '',
            First_Name: '',
            Last_Name: '',
            Email_Address: '',
        })
    }

    confirmation_msg = (msg, cls) => {

        confirmAlert({
            customUI: ({ onClose }) => {
                return (

                    <div className="react-confirm-alert-body-msg">
                        <div className={cls}><h6 >{msg}</h6></div>


                    </div>
                );
            }
        });
    };

    submitHandler(e) {
        const isvalid = this.validate();
        if (isvalid) {
            e.preventDefault();
            const value = this.state;
            let alertmessage = '';
            this.setState({ alertmessage })
            this.setState({ SK: "RecipentCode#" + value["RecipentCode"] })
            const sk = "#RecipentCode#" + value["RecipentCode"] + '#'
            const updatedvalues = {
                "SK": sk, "Investment_Strategy": value["Investment_Strategy"], "NotificationTypeANN": value["NotificationTypeANN"],
                "RecipentCode": value["RecipentCode"], NotificationTypeIRR: value["NotificationTypeIRR"], "NotificationCreate": value["NotificationCreate"],
                "First_Name": value["First_Name"], "Last_Name": value["Last_Name"], "Role_Name": value["Role_Name"], "Email_Address": value["Email_Address"],
                "user_email": this.context.user_email
            }

            axios({
                method: 'POST',
                url: config.api.RIS,
                // 'https://uzro6za8jc.execute-api.us-east-1.amazonaws.com/new/proxy:RIS?filters=',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: this.context.token
                },
                data: JSON.stringify(updatedvalues)

            })
                .then(response => {
                    //console.log(response)
                    let alertmessage = 'Record has been successfully added.';
                    this.setState({ alertmessage })
                    this.setState({ error: false })
                    this.confirmation_msg(alertmessage, "isa_success")
                })
                .catch(error => {
                    //console.log(error.response)
                    if (error.response.status === 304) {
                        let alertmessage = 'Data already exists ...Try again';
                        this.setState({ alertmessage })
                        this.setState({ error: true })
                        this.confirmation_msg(alertmessage, "isa_warning")
                    }
                });
            window.scrollTo(0, 0)
        }
        else {
            e.preventDefault();
            this.setState({ showForm: true });
        }
    }

    tohomepage = () => {
        //console.log('Redirect user to home --->');
        window.location.assign(config.azure.LOGIN_URL);
    };

    render() {
        if (this.context == undefined) {
            //console.log('Context is undefined --->');
            this.tohomepage();
            return false;
        }
        const deafult_investment_list = [{ label: '3rd Party', value: '3rd Party' }, { label: 'CASH', value: 'CASH' }]
        const {
            Investment_Strategy, RecipentCode,
            NotificationTypeANN,
            NotificationTypeIRR,
            NotificationCreate,
            First_Name,
            Last_Name,
            Role_Name,
            Email_Address
        } = this.state
        return (
            <div className="container" >
                <div role="tab" style={{ textAlign: 'center' }}>
                    <h1 className="panel-title " style={{ fontSize: 20 }}>
                        Create Recipient Investment Strategy
                  </h1>
                </div>
                <div style={{ marginLeft: 900, marginBottom: -5 }}> <Link style={{ alignItems: 'center', marginRight: 2 }} className="btn btn-primary" to="/recipent_investment_streategy">Back</Link>
                    <Button style={{ outline: "none" }} type='submit' className="btn btn-primary" onClick={this.clearHandler}>Clear</Button></div>
                <Form className="form-group" style={{ alignContent: "center", width: 700 }} onSubmit={this.submitHandler} >
                    {/* <div className={this.state.error ? 'error' : 'success'}>{this.state.ishidden?null:this.state.alertmessage}</div> */}
                    <FormGroup>
                        <Label>Investment Strategy</Label>
                        <AsyncSelect
                            value={this.state.reset ? null : this.state.selectedOption}
                            maxMenuHeight={150}
                            loadOptions={this.fetchDataIS}
                            defaultOptions={this.deafult_investment_list.sort((a, b) => (a.label > b.label ? 1 : -1))}
                            placeholder="Investment Strategy"
                            maxMenuHeight={170}
                            name="Investment_Strategy"
                            onChange={(e) => {
                                this.onSearchChangeIS(e, "Investment_Strategy");
                            }}
                        />
                        <div style={{ color: "red", fontSize: 15 }}>{this.state.Investment_StrategyError}</div>
                    </FormGroup>

                    <FormGroup>
                        <Label>Recipent Code</Label>
                        <Input
                            type='text'
                            name='RecipentCode'
                            value={RecipentCode}
                            placeholder="Recipent Code"
                            onChange={this.ChangeHandler}

                        />
                        <div style={{ color: "red", fontSize: 15 }}>{this.state.RecipentCodeError}</div>
                    </FormGroup>

                    <FormGroup>
                        <Label>Notification Type ANN</Label>
                        <Input
                            type='select'
                            name='NotificationTypeANN'
                            placeholder="Notification Type ANN"
                            value={NotificationTypeANN}
                            disabled={this.state.disable === true ? true : false}
                            onChange={this.ChangeHandler} >

                            <option> </option>
                            <option>Y</option>
                            <option>N</option>
                        </Input>
                        <div style={{ color: "red", fontSize: 15 }}>{this.state.NotificationTypeANNError}</div>
                    </FormGroup>
                    <FormGroup>
                        <Label>Notification Type IRR</Label>
                        <Input
                            type='select'
                            name='NotificationTypeIRR'
                            placeholder="Notification Type IRR"
                            value={NotificationTypeIRR}
                            disabled={this.state.disable === true ? true : false}
                            onChange={this.ChangeHandler} >
                            <option> </option>
                            <option>Y</option>
                            <option>N</option>
                        </Input>
                        <div style={{ color: "red", fontSize: 15 }}>{this.state.NotificationTypeIRRError}</div>
                    </FormGroup>
                    <FormGroup>
                        <Label>Notification Create</Label>
                        <Input
                            type='select'
                            name='NotificationCreate'
                            placeholder="Notification Create"
                            value={NotificationCreate}
                            disabled={this.state.disable === true ? true : false}
                            onChange={this.ChangeHandler} >
                            <option> </option>
                            <option>Y</option>
                            <option>N</option>
                        </Input>
                        <div style={{ color: "red", fontSize: 15 }}>{this.state.NotificationCreateError}</div>
                    </FormGroup>

                    <FormGroup>
                        <Label>Role Name</Label>
                        <Input
                            type='select'
                            name='Role_Name'
                            value={Role_Name}
                            placeholder="Role Name"
                            onChange={this.ChangeHandler}>
                            <option> </option>
                            <option>Approver</option>
                            <option>Read Only</option>
                        </Input>

                        <div style={{ color: "red", fontSize: 15 }}>{this.state.Role_NameError}</div>
                    </FormGroup>
                    <FormGroup>
                        <Label>First Name</Label>
                        <Input
                            type='text'
                            name='First_Name'
                            value={First_Name}
                            placeholder="First Name"
                            onChange={this.ChangeHandler}
                        />
                        <div style={{ color: "red", fontSize: 15 }}>{this.state.First_NameError}</div>
                    </FormGroup>

                    <FormGroup>
                        <Label>Last Name</Label>
                        <Input
                            type='text'
                            name='Last_Name'
                            placeholder="Last Name"
                            value={Last_Name}
                            onChange={this.ChangeHandler}
                        />
                        <div style={{ color: "red", fontSize: 15 }}>{this.state.Last_NameError}</div>
                    </FormGroup>
                    <FormGroup>
                        <Label>Email Address</Label>
                        <Input
                            type='text'
                            name='Email_Address'
                            value={Email_Address}
                            placeholder="Email Address"
                            onChange={this.ChangeHandler}
                        />
                        <div style={{ color: "red", fontSize: 15 }}>{this.state.Email_AddressError}</div>
                    </FormGroup>

                    <Button style={{ outline: "none" }} type='submit' className="btn btn-primary">Save</Button>
                </Form>
            </div>

        )
    }
}

export default RecipentInvestmentCreate;