import React from "react";
import {
  Table,
  Container,
  Button,
  FormGroup,
  Input,
  Form,
  Label,
} from "reactstrap";
import "react-dropdown/style.css";
import "./Standinginstruction.css";
import UserContext from "../../../UserContext";
import config from "../../../../auth_config";
import { BeatLoader, ClockLoader, MoonLoader } from "react-spinners";
import { Link } from "react-router-dom";
import apis from "./../../../../apis";
import AsyncSelect from "react-select/async";
import ScrollToTop from "react-scroll-up";
import axios from "axios";
import "react-data-export";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ReactFileReader from "react-file-reader";
import { confirmAlert } from 'react-confirm-alert';

class StandingInstruction extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      //   token: null,
      dataresponse: [],
      responsedata: {},
      isAddModel: false,
      errorresponse: null,
      loading: false,
      statusValue: { 0: "UPDATEMODEL" },
      selectedIndex: 0,
      editing: false,
      parms1: "",
      parms2: "",
      moreData: true,
      loadmore: true,
      limit: 25,
      F_limit: 25,
      isFilter: false,
      Msgalert: false,
      issort: true,
      filtersort: true,
      sortIcon: "▲",
      exportData: [],
      del_msg: "",
      import_data: false,
      check: true,
      search_msg: "",
      export_flag: true,
      search_loader: false,
      butt_disable: false
    };

    this.onEditFormSubmit = this.onEditFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.ChangeHandler = this.ChangeHandler.bind(this);
    this.ChangeHandlerform = this.ChangeHandlerform.bind(this);
    this.deafult_account_list = [];
    this.deafult_countrycode_list = [];
    this.deafult_currencycode_list = [];
    this.deafult_corp_list = [];
    this.deafult_SI_Action_Type_list = [];
  }

  ChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.parms1 = e.target.value;
    this.setState({ F_limit: 25 });
  };

  ChangeHandlerform = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    this.parms2 = e.target.value;
    this.setState({ F_limit: 25 });
  };

  componentWillUnmount() {
    // remove event listener
    window.removeEventListener("scroll", this.handleScroll, false);
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  }

  onCreate() {
    document.getElementById("duplicateRecord").innerHTML = "";
  }

  handlerForSubmit = (sk) => {
    this.onEditFormSubmit(this.state, sk);
  };

  fetchDataPortCorp_t = (inputValue) => {
    setTimeout(() => {
      fetch(config.api.CorpActiontype + inputValue, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.context.token,
        },
      })
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
          data.forEach((element) => {
            this.deafult_corp_list.push({
              label: `${element.Name}`,
              value: element.MappingValue,
            });
          });
          this.deafult_account_list.sort((a, b) =>
            a.label > b.label ? 1 : -1
          );
        })
        .catch((error) => {
          //console.log(error, "catch the hoop");
        });
    });
  };

  //new logic for auto complete
  fetchDataPortfolio_t = (inputValue) => {
    setTimeout(() => {
      fetch(config.api.PortfoliosList + inputValue, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.context.token,
        },
      })
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
          data.forEach((element) => {
            const label_value = element.Name + " " + "(" + element.Code + ")";
            this.deafult_account_list.push({
              label: `${label_value}`,
              value: element.Code,
            });
          });
          this.deafult_account_list.sort((a, b) =>
            a.label > b.label ? 1 : -1
          );
          if (this.deafult_account_list.length > 1512) {
            this.setState({ check: false })

          }
        })
        .catch((error) => {
          //console.log(error, "catch the hoop");
        });
    });
  };

  fetchDataSIAT_t = (inputValue) => {
    let params = {
      filters: "SI_Action_Type",
      param2: inputValue,
    };
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    //console.log(params);
    setTimeout(() => {
      fetch(config.api.SIAT + "?" + query, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.context.token,
        },
      })
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
          // const tempArray = [];
          let len = data.length;
          data.length < len ? (len = data.length) : (len = data.length);
          for (var i = 0; i < len; i++) {
            const element = data[i];
            this.deafult_SI_Action_Type_list.push({
              label: `${element.SI_Action_Type}`,
            });
          }
          this.deafult_SI_Action_Type_list.sort((a, b) =>
            a.label > b.label ? 1 : -1
          );
        })

        .catch((error) => {
          //console.log(error, "catch the hoop");
        });
    });
  };

  fetchDataCurrency_t = (inputValue) => {
    setTimeout(() => {
      fetch(config.api.CurrencyList + inputValue, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.context.token,
        },
      })
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
          data.forEach((element) => {
            const label = element.Name + " " + "(" + element.Code + ")";
            this.deafult_currencycode_list.push({
              label: `${label}`,
              value: element.Code,
            });
          });
          this.deafult_currencycode_list.sort((a, b) =>
            a.label > b.label ? 1 : -1
          );
        })
        .catch((error) => {
          //console.log(error, "catch the hoop");
        });
    });
  };

  fetchData_t = (inputValue) => {
    setTimeout(() => {
      fetch(config.api.CountryList + inputValue, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.context.token,
        },
      })
        .then((resp) => {
          //console.log(resp);
          return resp.json();
        })
        .then((data) => {
          data.forEach((element) => {
            const label = element.Name + "(" + element.ShortCode + ")";
            this.deafult_countrycode_list.push({
              label: `${label}`,
              value: element.ShortCode,
            });
          });
          this.deafult_countrycode_list.sort((a, b) =>
            a.label > b.label ? 1 : -1);

          //console.log("length of list",this.deafult_countrycode_list.length)

        }
        )
        .catch((error) => {
          //console.log(error, "catch the hoop");
        });
    });
  };

  fetchDataPortCorp = (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        fetch(config.api.CorpActiontype + inputValue, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.context.token,
          },
        })
          .then((resp) => {
            return resp.json();
          })
          .then((data) => {
            const tempArray = [];
            data.forEach((element) => {
              tempArray.push({
                label: `${element.Name}`,
                value: element.MappingValue,
              });
            });
            callback(tempArray);
          })
          .catch((error) => {
            //console.log(error, "catch the hoop");
          });
      });
    }
  };

  onSearchChangeCorp = (selectedOption, name) => {
    if (selectedOption) {
      this.setState({
        [name]: selectedOption.value,
        selectedOption: this.selectedOption,
      });
    }
  };

  //new logic for auto complete For Portfolios
  fetchDataPortfolio = (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        fetch(config.api.PortfoliosList + inputValue, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.context.token,
          },
        })
          .then((resp) => {
            return resp.json();
          })
          .then((data) => {
            const tempArray = [];
            data.forEach((element) => {
              const labels = element.Name + "(" + element.Code + ")"
              tempArray.push({ label: `${labels}`, value: element.Code });
            });
            callback(tempArray);
          })
          .catch((error) => {
            //console.log(error, "catch the hoop");
          });
      });
    }
  };

  onSearchChangePortfolio = (selectedOption, name) => {
    if (selectedOption) {
      this.setState({
        [name]: selectedOption.value,
        selectedOption: this.selectedOption,
      });
    }
  };

  //new logic for auto complete
  fetchDataCurrency = (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        fetch(config.api.CurrencyList + inputValue, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.context.token,
          },
        })
          .then((resp) => {
            return resp.json();
          })
          .then((data) => {
            const tempArray = [];
            data.forEach((element) => {
              const labels = element.Name + "(" + element.Code + ")"
              tempArray.push({ label: `${labels}`, value: element.Code });
            });
            callback(tempArray);
          })
          .catch((error) => {
            //console.log(error, "catch the hoop");
          });
      });
    }
  };

  onSearchChangeCurrency = (selectedOption, name) => {
    if (selectedOption) {
      this.setState({
        [name]: selectedOption.value,
        selectedOption: this.selectedOption,
      });
    }
  };

  //Standing Instruction Action Type
  fetchDataSIAT = (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
    } else {
      let params = {
        filters: "SI_Action_Type",
        param2: inputValue,
      };
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      //console.log(params);
      setTimeout(() => {
        fetch(config.api.SIAT + "?" + query, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.context.token,
          },
        })
          .then((resp) => {
            return resp.json();
          })
          .then((data) => {
            const tempArray = [];
            let len = data.length;
            data.length < len ? (len = data.length) : (len = data.length);
            for (var i = 0; i < len; i++) {
              const element = data[i];
              tempArray.push({ label: `${element.SI_Action_Type}` });
            }
            callback(tempArray);
          })
          .catch((error) => {
            //console.log(error, "catch the hoop");
          });
      });
    }
  };

  onSearchChangeSIAT = (selectedOption, name) => {
    if (selectedOption) {
      this.setState({
        [name]: selectedOption.label,
        selectedOption: this.selectedOption,
      });
    }
  };

  //new logic for auto complete
  fetchData = (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        fetch(config.api.CountryList + inputValue, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.context.token,
          },
        })
          .then((resp) => {
            return resp.json();
          })
          .then((data) => {
            const tempArray = [];
            data.forEach((element) => {
              const labels = element.Name + "(" + element.ShortCode + ")"
              tempArray.push({
                label: `${labels}`,
                value: element.ShortCode,
              });
            });
            callback(tempArray);
            //console.log("temp array.....", tempArray);
          })
          .catch((error) => {
            //console.log(error, "catch the hoop");
          });
      });
    }
  };

  onSearchChange = (selectedOption, name) => {
    if (selectedOption) {
      this.setState({
        [name]: selectedOption.value,
        selectedOption: this.selectedOption,
      });
    }
  };

  //END OF API CALLS
  validate = () => {
    let AccountError = "";
    let Corp_Action_TypeError = "";
    let SI_Action_TypeError = "";
    let Business_EntityError = "";
    if (!this.state.Account) {
      AccountError = "Account can not be blank";
    }
    if (!this.state.Business_Entity) {
      Business_EntityError = "Business Entity can not be blank";
    }
    if (!this.state.Corp_Action_Type) {
      Corp_Action_TypeError = "corp Action Type can not be blank";
    }
    if (!this.state.SI_Action_Type) {
      SI_Action_TypeError = "Standing Instruction Action Type can not be blank";
    }
    if (
      AccountError ||
      Business_EntityError ||
      Corp_Action_TypeError ||
      SI_Action_TypeError
    ) {
      this.setState({
        AccountError,
        Business_EntityError,
        Corp_Action_TypeError,
        SI_Action_TypeError,
      });
      this.state.AccountError = AccountError;
      this.state.Business_EntityError = Business_EntityError;
      this.state.Corp_Action_TypeError = Corp_Action_TypeError;
      this.state.SI_Action_TypeError = SI_Action_TypeError;
      return false;
    }
    return true;
  };

  handleFiles = (e) => {
    var f = e[0];
    var name = e[0].name;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      //console.log("Data>>>" + data);
      //console.log(this.convertToJson(data))
    };
    reader.readAsBinaryString(f);
  };

  convertToJson(csv) {
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",");
    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");
      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }
      result.push(obj);
    }
    const excel_data = JSON.stringify(result);
    axios({
      method: "POST",
      url: config.api.SI,
      headers: {
        Authorization: this.context.token,
      },
      data: excel_data,
    })
      .then(response => {
        //console.log(response)

        this.loadModelData()
        let alertmessage = 'Record Imported Successfully.';
        this.confirmation_msg(alertmessage, "isa_success")
      })
      .catch(error => {
        let alertmessage = 'Error in processing data.';
        this.confirmation_msg(alertmessage, "isa_error")
      });
  }

  handlerForDelete = (sk) => {
    this.submitDelete(sk)
  };

  confirmation_msg = (msg, cls) => {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (

          <div className="react-confirm-alert-body-msg">
            <div className={cls}><h6 >{msg}</h6></div>


          </div>
        );
      }
    });
  };

  submitDelete = (sk) => {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (

          <div className="react-confirm-alert-body">
            <h3>Are you Sure?</h3>
            <h6>You want to delete this record?</h6>
            <Button style={{ marginRight: 5 }} className="btn btn-primary" onClick={onClose}>No</Button>
            <Button
              className="btn btn-primary"
              onClick={() => {
                this.onDeleteSubmit(sk);
                onClose();
              }}
            >
              Yes, Delete it!
                </Button>
          </div>

        );
      }
    });
  };



  onDeleteSubmit(sk) {

    const apiUrl = config.api.SI + ":$" + "DEL";
    //console.log(sk, JSON.stringify({ sk }));
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.context.token,
      },
      body: JSON.stringify({ sk, "user_email": this.context.user_email }),
    }).then((response) => {
      if (response.status === 200) {
        this.state.del_msg = "Record has been successfully deleted";
        this.loadModelData();
        const msg = "Record has been successfully deleted."
        this.confirmation_msg(msg, "isa_success")
      }
    });
  }

  onEditFormSubmit(value, sk) {
    const isvalid = this.validate();
    if (isvalid) {
      //console.log(sk);
      this.setState({ loading: true });
      const apiUrl = config.api.SI + ":$" + sk;
      // `https://uzro6za8jc.execute-api.us-east-1.amazonaws.com/new/proxy:SI:${sk}?filters=`;
      //console.log("value", apiUrl);
      const sknew =
        "#Account#" +
        this.state.Account +
        "#BE#" +
        this.state.Business_Entity +
        "#CAT#" +
        this.state.Corp_Action_Type +
        "#COIC#" +
        this.state.Country_Of_IssueCode +
        "#SIAT#" +
        this.state.SI_Action_Type +
        "#SICC#" +
        this.state.Standing_Instruction_Currency_Code +
        "#";
      const updatedvalues = {
        oldsk: value["selectedIndex"],
        Account: value["Account"],
        Business_Entity: value["Business_Entity"],
        Country_Of_IssueCode: value["Country_Of_IssueCode"],
        Corp_Action_Type: value["Corp_Action_Type"],
        SI_Action_Type: value["SI_Action_Type"],
        Standing_Instruction_Currency_Code:
          value["Standing_Instruction_Currency_Code"],
        SK: sknew,
        user_email: this.context.user_email,
      };
      fetch(apiUrl, {
        method: "PUT",
        headers: {
          Authorization: this.context.token,
        },
        body: JSON.stringify(updatedvalues),
      }).then((response) => {
        if (response.status === 304) {
          let alertmessage = "Data already exists";
          this.setState({ alertmessage });
          this.confirmation_msg(alertmessage, "isa_warning")
        } else {
          this.setState({ error: false });
          this.setState({ Msgalert: true });
          this.setState({ responsedata: response });
          const msg = "Record has been successfully edited."
          this.confirmation_msg(msg, "isa_success")
        }
        this.loadModelData();
      });

      this.setState((prevState) => ({
        editing: !prevState.editing,
      }));
      this.setState({ loading: false });
    } else {
      this.setState({ showForm: true });
      alert(
        this.state.AccountError +
        " " +
        this.state.Business_EntityError +
        " " +
        this.state.Corp_Action_TypeError +
        " " +
        this.state.Country_Of_IssueCodeError +
        " " +
        this.state.SI_Action_TypeError +
        " " +
        this.state.Standing_Instruction_Currency_CodeError
      );
    }
  }

  async sample_exportingData(closeModal) {
    const items = [{ "Account": "0113GMWBEQTRD", "Business_Entity": "BUS01", "Corp_Action_Type": "DRI", "Country_Of_IssueCode": "CA", "SI_Action_Type": "CASH", "Standing_Instruction_Currency_Code": "CAD" }]
    const csvData = items;
    const fileName = "StandingInstruction";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }



  async exportingData(closeModal) {
    try {
      this.setState({ loading: true });
      const api_response = await fetch(
        config.api.SI +
        ":" +
        this.state.issort +
        ":" +
        this.state.limit +
        ":" +
        this.state.export_flag,
        // `https://fuw9emc6dj.execute-api.us-east-1.amazonaws.com/prod/proxy+:FM`,
        {
          method: "GET",
          headers: {
            Authorization: this.context.token,
          },
        }
      );
      const json_response = await api_response.json();
      const items = json_response["Items"];
      const csvData = items;
      const fileName = "StandingInstruction";
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      if (closeModal) {
        this.setState({ dataresponse: items, isAddModel: false }, () => {
          window.$("#add-modal").modal("hide");
        });
      } else {
        this.setState({ dataresponse: items, exportData: items });
      }
      this.setState({ loading: false });
    } catch (errorresponse) {
      console.table("Exception : ", errorresponse);
      this.setState({ loading: false });
    }
  }

  async filteredloadModelData(closeModal) {
    this.setState({ search_loader: true })
    this.setState({ limit: 25 });

    try {
      let url = config.api.SI + ":" + this.state.issort + ":" + this.state.F_limit;
      this.setState({ loading: true });

      console.log(
      this.parms1,
        this.parms2,
        this.parms1 !== "Select",
        (this.parms1 !== undefined) & (this.parms2 !== undefined)
      );
      if (
        (this.parms1 !== undefined) &
        (this.parms2 !== undefined) &
        (this.parms1 !== "Select") &
        (this.parms1 !== "") &
        (this.parms2 !== "")
      ) {
        this.setState({ isFilter: true });
        this.setState({ butt_disable: true })
        const key = this.parms1.replace(/ /g, "_");
        this.setState({ search_msg: "" })
        let params = {
          filters: key,
          param2: this.parms2,
        };
        //console.log(this.param1, this.parms2);
        let query = Object.keys(params)
          .map(
            (k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k])
          )
          .join("&");
        //console.log(params);
        url =
          config.api.SI +
          ":" +
          this.state.issort +
          ":" +
          this.state.F_limit +
          "?" +
          query;
      } else {
        this.setState({ isFilter: false })
        const msg = "Please enter Filter criteria."
        this.confirmation_msg(msg, "isa_error")
      }
      const api_response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: this.context.token,
        },
      });
      const json_response = await api_response.json();
      'LastEvaluatedKey' in json_response ? this.setState({ loadmore: true }) : this.setState({ loadmore: false })
      'LastEvaluatedKey' in json_response ? this.setState({ search_loader: true }) : this.setState({ search_loader: false })
      json_response["Count"] === 0
        ? this.setState({ moreData: false })
        : this.setState({ moreData: true });
      const items = json_response["Items"];

      if (items.length > 25 || 'LastEvaluatedKey' in json_response == false) {
        this.setState({ search_loader: false })
      }
      if (items.length < 25 && this.state.loadmore) {
        this.handlefilter();
      }
      if (closeModal) {
        this.setState({ dataresponse: items, isAddModel: false }, () => {
          window.$("#add-modal").modal("hide");
        });
      } else {
        this.setState({ dataresponse: items });
      }
      this.setState({ loading: false });
      // this.setState({ search_loader: false });
    } catch (errorresponse) {
      console.table("Exception : ", errorresponse);
      this.setState({ loading: false });
      this.setState({ search_loader: false });
    }
  }

  async loadModelData(closeModal) {
    try {
      this.setState({ check: true });
      //console.log("TOKEN", this.context.token);
      const api_response = await fetch(
        config.api.SI + ":" + this.state.issort + ":" + this.state.limit,
        // `https://uzro6za8jc.execute-api.us-east-1.amazonaws.com/new/proxy:SI?`,
        {
          method: "GET",
          headers: {
            Authorization: this.context.token,
          },
        }
      );
      const json_response = await api_response.json();
      'LastEvaluatedKey' in json_response ? this.setState({ loadmore: true }) : this.setState({ loadmore: false })
      const items = json_response["Items"];
      this.setState({ check: false });
      this.setState({ exportData: items });

      if (closeModal) {
        this.setState({ dataresponse: items, isAddModel: false }, () => {
          window.$("#add-modal").modal("hide");
        });
      } else {
        this.setState({ dataresponse: items });
      }
      this.setState({ loading: false });
    } catch (errorresponse) {
      console.table("Exception : ", errorresponse);
      this.setState({ loading: false });
    }
  }

  async componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, false);
    this.loadModelData();
    this.fetchDataPortfolio_t("");
    this.fetchDataPortCorp_t("");
    this.fetchData_t("");
    this.is_initial = true;
    this.fetchDataCurrency_t("");
    this.fetchDataSIAT_t("");
    //console.log("account list==",this.deafult_account_list)
  }
  edit = (
    e,
    sk,
    Account,
    Business_Entity,
    Corp_Action_Type,
    Country_Of_IssueCode,
    SI_Action_Type,
    Standing_Instruction_Currency_Code
  ) => {
    if (e.target.textContent == "Edit") {
      this.setState((prevState) => ({
        editing: !prevState.editing,
        selectedIndex: sk,
        Account: Account,
        Business_Entity: Business_Entity,
        Corp_Action_Type: Corp_Action_Type,
        Country_Of_IssueCode: Country_Of_IssueCode,
        SI_Action_Type: SI_Action_Type,
        Standing_Instruction_Currency_Code: Standing_Instruction_Currency_Code,
      }));
    } else {
      this.setState((prevState) => ({
        editing: !prevState.editing,
        selectedIndex: sk,
        Account: "",
        Business_Entity: "",
        Corp_Action_Type: "",
        Country_Of_IssueCode: "",
        SI_Action_Type: "",
        Standing_Instruction_Currency_Code: "",
      }));
    }
  };
  ondropdownchange = (event, key) => {
    this.state.statusValue[parseInt(key)] = event.label;
  };

  clearHandler = e => {
    this.state.inputvalue = ''
    this.state.moreData = true
    this.loadModelData()
  }

  renderTableData() {
    return this.state.dataresponse.map((model_data, index) => {
      const {
        sk,
        Account,
        Business_Entity,
        Corp_Action_Type,
        Country_Of_IssueCode,
        SI_Action_Type,
        Standing_Instruction_Currency_Code,
      } = model_data;

      return (
        <tr>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (
              <AsyncSelect
                value={this.state.selectedOption}
                loadOptions={this.fetchDataPortfolio}
                defaultInputValue={Account || ""}
                name="Account"
                maxMenuHeight={150}
                onInputChange={(e) => {
                  this.onSearchChangePortfolio(e, "Account");
                }}
                onChange={(e) => {
                  this.onSearchChangePortfolio(e, "Account");
                }}
                defaultOptions={true}
                defaultOptions={this.deafult_account_list}
              />
            ) : (
                <div>{Account}</div>
              )}
          </td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (
              <Input
                style={{ height: 38 }}
                type="select"
                name="Business_Entity"
                id="Business_Entity"
                defaultValue={Business_Entity || ""}
                onChange={this.handleChange}
                required
              >
                <option> </option>
                <option>BUS01</option>
                <option>BUS02</option>
              </Input>
            ) : (
                <div>{Business_Entity}</div>
              )}
          </td>

          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (
              <AsyncSelect
                value={this.state.selectedOption}
                loadOptions={this.fetchDataPortCorp}
                defaultInputValue={Corp_Action_Type || ""}
                name="Corp_Action_Type"
                maxMenuHeight={150}
                onInputChange={(e) => {
                  this.onSearchChangeCorp(e, "Corp_Action_Type");
                }}
                onChange={(e) => {
                  this.onSearchChangeCorp(e, "Corp_Action_Type");
                }}
                defaultOptions={true}
                defaultOptions={this.deafult_corp_list.sort((a, b) =>
                  a.label > b.label ? 1 : -1
                )}
              />
            ) : (
                <div>{Corp_Action_Type}</div>
              )}
          </td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (
              <AsyncSelect
                value={this.state.selectedOption}
                loadOptions={this.fetchData}
                defaultInputValue={Country_Of_IssueCode || ""}
                name="Country_Of_IssueCode"
                maxMenuHeight={150}
                onInputChange={(e) => {
                  this.onSearchChange(e, "Country_Of_IssueCode");
                }}
                onChange={(e) => {
                  this.onSearchChange(e, "Country_Of_IssueCode");
                }}
                defaultOptions={this.deafult_countrycode_list.sort((a, b) =>
                  a.label > b.label ? 1 : -1
                )}
              />
            ) : (
                <div>{Country_Of_IssueCode}</div>
              )}
          </td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (
              <AsyncSelect
                value={this.state.selectedOption}
                loadOptions={this.fetchDataSIAT}
                defaultInputValue={SI_Action_Type || ""}
                name="SI_Action_Type"
                maxMenuHeight={150}
                onInputChange={(e) => {
                  this.onSearchChangeSIAT(e, "SI_Action_Type");
                }}
                onChange={(e) => {
                  this.onSearchChangeSIAT(e, "SI_Action_Type");
                }}
                defaultOptions={this.deafult_SI_Action_Type_list.sort((a, b) =>
                  a.label > b.label ? 1 : -1
                )}
              />
            ) : (
                <div>{SI_Action_Type}</div>
              )}
          </td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (
              <AsyncSelect
                value={this.state.selectedOption}
                loadOptions={this.fetchDataCurrency}
                defaultInputValue={Standing_Instruction_Currency_Code || ""}
                name="Standing_Instruction_Currency_Code"
                maxMenuHeight={150}
                onInputChange={(e) => {
                  this.onSearchChangeCurrency(
                    e,
                    "Standing_Instruction_Currency_Code"
                  );
                }}
                onChange={(e) => {
                  this.onSearchChangeCurrency(
                    e,
                    "Standing_Instruction_Currency_Code"
                  );
                }}
                defaultOptions={this.deafult_currencycode_list.sort((a, b) =>
                  a.label > b.label ? 1 : -1
                )}
              />
            ) : (
                <div>{Standing_Instruction_Currency_Code}</div>
              )}
          </td>
          {config.cognito.UPDATE_ROLE == this.context.user_grp ? (
            <td>
              {this.state.editing && this.state.selectedIndex == sk ? (
                <Button
                  style={{
                    marginLeft: 12,
                    marginBottom: 3,
                    marginBottom: 5,
                    height: 5,
                    width: 110,
                    textAlign: "center",
                    display: "grid",
                    outline: "none",
                    alignContent: "center",
                  }}
                  id={sk}
                  type="button"
                  className="btn btn-primary pull-left"
                  onClick={(e) => this.handlerForSubmit(sk)}
                >
                  Save
                </Button>
              ) : null}
              <div className="btn-toolbar pull-right">
                <Button
                  style={{
                    marginLeft: 15,
                    marginBottom: 3,
                    height: 5,
                    width: 110,
                    textAlign: "center",
                    display: "grid",
                    outline: "none",
                    alignContent: "center",
                  }}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) =>
                    this.edit(
                      e,
                      sk,
                      Account,
                      Business_Entity,
                      Corp_Action_Type,
                      Country_Of_IssueCode,
                      SI_Action_Type,
                      Standing_Instruction_Currency_Code
                    )
                  }
                >
                  {this.state.editing && this.state.selectedIndex == sk
                    ? "Cancel"
                    : "Edit"}
                </Button>

                <Button
                  style={{
                    marginLeft: 15,
                    height: 5,
                    width: 110,
                    textAlign: "center",
                    display: "grid",
                    outline: "none",
                    alignContent: "center",
                  }}
                  id={sk}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => this.handlerForDelete(sk)}
                >
                  Delete
                </Button>
              </div>
            </td>
          ) : null}
        </tr>
      );
    });
  }
  handleScroll = () => {
    // console.log(
    // this.isFilter,
    //   this.state.isFilter,
    //   this.state.F_limit,
    //   this.state.limit,
    //   this.state.loadmore,
    //   this.state.moreData
    // );
    // //console.log(window.innerHeight, window.scrollY, document.body.offsetHeight, this.state.loadmore)
    if (
      (window.innerHeight + window.scrollY >= document.body.offsetHeight) &
      this.state.loadmore &
      this.state.moreData
    ) {
      //console.log("you're at the bottom of the page");
      // console.log(
      // window.innerHeight,
      //   window.scrollY,
      //   document.body.offsetHeight,
      //   this.state.loadmore
      // );
      if (this.state.isFilter) {
        this.setState({ F_limit: this.state.F_limit + 5 });
        //console.log("RECORDS LOADED----->>>", this.state.F_limit);
        this.filteredloadModelData();
        //console.log("this.state.isFilter", this.state.isFilter);
      } else {
        this.setState({ limit: this.state.limit + 5 });
        //console.log("RECORDS LOADED----->>>", this.state.limit);
        this.loadModelData();
      }
    }
  };

  ascendingsort = () => {
    if (this.state.isFilter) {
      this.state.issort = true;
      this.filteredloadModelData();
    } else {
      this.state.issort = true;
      this.loadModelData();
    }
  };

  descendingsort = () => {
    if (this.state.isFilter) {
      this.state.issort = false;
      this.filteredloadModelData();
    } else {
      this.state.issort = false;
      this.loadModelData();
    }
  };

  onsort = () => {
    if (this.state.filtersort) {
      this.state.filtersort = false;
      this.state.sortIcon = "▼";
      this.descendingsort();
    } else {
      this.state.filtersort = true;
      this.state.sortIcon = "▲";
      this.ascendingsort();
    }
  };

  handlefilter = () => {
    this.setState({ F_limit: this.state.F_limit + 5 });
    this.setState({ search_msg: "The Input Field Can't Be Empty" })
    //console.log("RECORDS LOADED----->>>", this.state.F_limit);
    this.filteredloadModelData();
    //console.log("this.state.isFilter", this.state.isFilter);
  };

  tohomepage = () => {
    window.location.assign(config.azure.LOGIN_URL);
  };

  createRecored() {
    if (config.cognito.UPDATE_ROLE == this.context.user_grp) {
      return (
        <Link to="/create_standing_instruction_form">
          <button
            style={{ marginLeft: -100 }}
            type="button"
            className="btn btn-primary"
          >
            Create Record
          </button>
        </Link>
      );
    } else {
      return null;
    }
  }

  render() {
    if (this.context == undefined) {
      this.tohomepage();
      return false;
    }
    const { baseinputValue, inputvalue } = this.state;
    return (
      <div onScroll={this.handleScroll}>
        <Container>
          <div role="tab" style={{ textAlign: "center" }} class="fixed">
            <h1 className="panel-title " style={{ fontSize: 20 }}>
              Standing Instructions
            </h1>
          </div>
          <div style={{ textAlign: 'center', color: 'red' }}>
            {this.state.search_msg}

          </div>
          <div>
            <Table
              style={{ alignContent: "center", width: 600, marginLeft: -100 }}
            >
              <thead>
                <tr>
                  <td>
                    <Label>Filtering Method</Label>
                  </td>
                  <td>
                    <Label>{baseinputValue}</Label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Input
                      type="select"
                      name="baseinputValue"
                      value={baseinputValue}
                      onChange={this.ChangeHandler}
                      style={{ width: "300px" }}
                    >
                      <option>Select</option>
                      <option>Account</option>
                      <option>Business Entity</option>
                      <option>Corp Action Type</option>
                      <option>Country Of IssueCode</option>
                      <option>SI Action Type</option>
                      <option>Standing Instruction Currency Code</option>
                    </Input>
                  </td>
                  <td>
                    <Input
                      type="text"
                      name="inputvalue"
                      value={inputvalue}
                      onChange={this.ChangeHandlerform}
                      style={{ width: "220px" }}
                    />
                  </td>
                  <td>
                    <Button style={{ outline: "none" }} type='submit' className="btn btn-primary" onClick={this.clearHandler}>Clear</Button>
                  </td>
                  <td>
                    <button
                      style={{ outline: "none" }}
                      onClick={() => {
                        this.filteredloadModelData();
                      }}
                      id="submit-btn"
                      type="button"
                      className="btn btn-primary btn btn-secondary"
                    >
                      Search
                    </button>
                  </td>
                  <td>
                    <button
                      style={{ outline: "none", textAlign: "right" }}
                      onClick={(e) => {
                        this.exportingData();
                      }}
                      id="submit-btn"
                      type="button"
                      className="btn btn-primary btn btn-secondary"
                    >
                      Export
                    </button>
                  </td>
                  <td>
                    <ReactFileReader handleFiles={this.handleFiles} fileTypes={[".csv", ".xls", ".xlsx"]}>
                      <button
                        style={{ outline: "none", textAlign: "right" }}
                        className="btn btn-primary btn btn-secondary"
                      >
                        Import
                    </button>
                    </ReactFileReader>
                  </td>
                  {this.state.butt_disable ? (
                    <td >
                      <ClockLoader size={25} color='blue' loading={this.state.search_loader} />
                    </td>
                  ) : null}
                  <td>
                    <button style={{ outline: "none", textAlign: "right", marginLeft: 257 }} onClick={(e) => { this.sample_exportingData() }}
                      id="submit-btn" type="button" className="btn btn-primary btn-secondary">
                      Template
                    </button>
                  </td>
                </tr>
              </thead>

            </Table>
          </div>
          {
            <div style={{ margin: 10 }}>
              {this.createRecored()}


            </div>
          }
          <div style={{ fontSize: 15 }}>
            <Table
              className="model-table-margin table table-bordered table-hover"
              style={{
                alignContent: "center",
                width: 1300,
                height: 5,
                marginLeft: -100,
                tableLayout: "fixed",
                overflow: "hidden",
                "overflow-y": "scroll",
              }}
            >
              <thead className="tableFixHead">

                <tr>
                  <th onClick={this.onsort}>
                    Account
                    {this.state.sortIcon}
                  </th>
                  <th>Business Entity</th>
                  <th>Corp Action Type</th>
                  <th>Country Of Issue Code</th>
                  <th>Standing Instruction Action Type</th>
                  <th>Standing Instruction Currency Code</th>
                  {config.cognito.UPDATE_ROLE == this.context.user_grp ? (
                    <th>Action</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>{this.renderTableData()}</tbody>
            </Table>
            <ScrollToTop
              smooth
              showUnder={5}
              duration={1000}
              smooth
              color="#6f00ff"
            >
              <button
                style={{
                  display: "inline-block",
                  marginLeft: "15px",
                }}
              >
                UP
              </button>

            </ScrollToTop>
            <div className="load-spinner">
              <BeatLoader size={25} color="blue" loading={this.state.loading} />
              <MoonLoader size={25} color="blue" loading={this.state.check} />
            </div>
            <div className={this.state.search_loader ? 'hidden' : ''}>
              <div className={this.state.moreData ? 'hidden' : ''}>
                <p><strong>No Records Found.</strong></p>

              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default StandingInstruction;
