import React from "react";
import { Table, Container, Button, FormGroup, Input, Form, Label } from "reactstrap";
import "react-dropdown/style.css";
import "./fundmanager.css";
import { BeatLoader, MoonLoader, CircleLoader, ClockLoader } from "react-spinners";
import { Link } from "react-router-dom";
import apis from "../../../../../apis";
import UserContext from "../../../../UserContext";
import config from "../../../../../auth_config";
import '../../../error.css'
import ScrollToTop from 'react-scroll-up';
import "react-data-export";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { confirmAlert } from 'react-confirm-alert';

class FundManager extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      //   token: null,
      dataresponse: [],
      responsedata: {},
      isAddModel: false,
      errorresponse: null,
      loading: false,
      statusValue: { 0: "UPDATEMODEL" },
      selectedIndex: 0,
      editing: false,
      parms1: '',
      parms2: '',
      moreData: true,
      isActive: true,
      moreData: true,
      loadmore: true,
      limit: 25,
      F_limit: 25,
      isFilter: false,
      issort: true,
      filtersort: true,
      sortIcon: '▲',
      exportData: [],
      export_flag: true,
      search_msg: "",
      del_msg: "",
      search_loader: false,
      butt_disable: false
    };

    this.onEditFormSubmit = this.onEditFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.ChangeHandler = this.ChangeHandler.bind(this);
    this.ChangeHandlerform = this.ChangeHandlerform.bind(this);
  }

  ChangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
    this.parms1 = e.target.value
    this.setState({ F_limit: 25 })
  }

  ChangeHandlerform = e => {
    this.setState({ [e.target.name]: e.target.value })
    this.state.parms2 = e.target.value
    this.setState({ F_limit: 25 })
  }

  handleChange(event) {
    //console.log('event.target::', event.target);
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  }

  onCreate() {
    document.getElementById("duplicateRecord").innerHTML = "";
  }

  handlerForSubmit = (sk) => {
    this.onEditFormSubmit(this.state, sk);
    //console.log("test", sk)
  };

  handlerForDelete = (sk) => {
    this.submitDelete(sk)
  };

  confirmation_msg = (msg, cls) => {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (

          <div className="react-confirm-alert-body-msg">
            <div className={cls}><h6 >{msg}</h6></div>


          </div>
        );
      }
    });
  };

  submitDelete = (sk) => {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (

          <div className="react-confirm-alert-body">
            <h3>Are you Sure?</h3>
            <h6>You want to delete this record?</h6>
            <Button style={{ marginRight: 5 }} className="btn btn-primary" onClick={onClose}>No</Button>
            <Button
              className="btn btn-primary"
              onClick={() => {
                this.onDeleteSubmit(sk);
                onClose();
              }}
            >
              Yes, Delete it!
                </Button>
          </div>

        );
      }
    });
  };

  onDeleteSubmit(sk) {
    const apiUrl = config.api.FM + ':$' + 'DEL';
    //console.log(sk)
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.context.token
      },
      body: JSON.stringify({ sk })

    })
      .then(response => {
        if (response.status === 200) {

          this.loadModelData()
          const msg = "Record has been successfully deleted."
          this.confirmation_msg(msg, "isa_success")
        }

      })
  }


  componentWillUnmount() {
    // remove event listener
    window.removeEventListener('scroll', this.handleScroll, false);
  }

  validate = () => {
    let FundError = '';
    if (!this.state.Fund_Manager) {
      FundError = "Fund Manager field can not be blank";
    }
    if (FundError) {
      this.setState({ FundError })
      this.state.FundError = FundError
      return false;
    }
    return true;
  }

  onEditFormSubmit(value, sk) {
    const isvalid = this.validate();
    if (isvalid) {
      //console.log(sk)
      this.setState({ loading: true });
      const apiUrl = config.api.FM + ":$" + sk
      // `https://fuw9emc6dj.execute-api.us-east-1.amazonaws.com/prod/proxy+:FM`;
      //console.log("value", value);
      const updatedvalues = {
        "sk": value["selectedIndex"], "Fund_Manager": value["Fund_Manager"], "user_email": this.context.user_email
      }

      fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          Authorization: this.context.token
        },
        body: JSON.stringify(updatedvalues)

      })
        .then(response => {
          //console.log("RESP", response)
          if (response.status === 304) {
            let alertmessage = 'Data already exists';
            this.setState({ alertmessage })
            //console.log('Data already exists');
            this.confirmation_msg(alertmessage, "isa_warning")
          }
          else {
            this.setState({ error: false })
            this.setState({ responsedata: response })
            const msg = "Record has been successfully edited."
            this.confirmation_msg(msg, "isa_success")
          }
          this.loadModelData()
        })

      this.setState((prevState) => ({
        editing: !prevState.editing,
      }));
      this.setState({ loading: false });
    }
    else {
      this.setState({ showForm: true });
      alert(this.state.FundError)
      //console.log("Invalid details")
    }
  }

  async filteredloadModelData(closeModal) {
    this.setState({ search_loader: true })
    this.setState({ limit: 25 })
    //console.log(this.state.search_loader)
    //console.log("RESET LIMIT - ",this.state.arms2,"<<<<",this.param1,">>>>>",this.state.F_limit)
    try {
      let url = config.api.FM + ':' + this.state.issort + ':' + this.state.F_limit
      this.setState({ loading: true });

      //console.log("{}{}",this.parms1, "pa2",this.state.parms2, this.parms1 !== 'Select', "without selecting",this.parms1 !== undefined & this.state.parms2 !== undefined)
      if (this.parms1 !== undefined & this.state.parms2 !== undefined & this.parms1 !== 'Select' & this.parms1 !== '' & this.state.parms2 !== '') {
        const key = (this.parms1).replace(/ /g, "_");
        this.setState({ search_msg: "" })
        this.setState({ butt_disable: true })
        let params = {
          "filters": key,
          "param2": this.state.parms2
        };

        let query = Object.keys(params)
          .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
          .join('&');
        //console.log(params)
        // url = apis.FM + "?" + query
        url = config.api.FM + ':' + this.state.issort + ':' + this.state.F_limit + "?" + query
      }
      else {
        this.setState({ isFilter: false })
        const msg = "Please enter Filter criteria."
        this.confirmation_msg(msg, "isa_error")
      }
      const api_response = await fetch(url,
        // `https://uzro6za8jc.execute-api.us-east-1.amazonaws.com/new/proxy:FM?`+query,
        {
          method: "GET",
          headers: {
            Authorization: this.context.token,
          },
        }
      );
      const json_response = await api_response.json();
      console.table("json_response --> ", json_response);
      json_response['Count'] === 0 ? this.setState({ moreData: false }) : this.setState({ moreData: true })
      //console.log('LastEvaluatedKey', json_response['LastEvaluatedKey'],json_response['Count'])
      'LastEvaluatedKey' in json_response ? this.setState({ loadmore: true }) : this.setState({ loadmore: false })
      'LastEvaluatedKey' in json_response ? this.setState({ loadmore: true }) : this.setState({ loadmore: false })

      const items = json_response['Items']
      //console.log("Json Items---->>", items)
      //console.log(items.length)
      //console.log("after getting response>>>>>>>>>>>>>>>>>>")
      if (items.length > 25 || 'LastEvaluatedKey' in json_response == false) {

        this.setState({ search_loader: false })
      }
      if (items.length < 25 && this.state.loadmore) {
        this.handlefilter()
      }
      // (json_response || []).length === 0 ? this.setState({ moreData: false}) :this.setState({ moreData: true})
      if (closeModal) {
        this.setState({ dataresponse: items, isAddModel: false }, () => {
          window.$('#add-modal').modal('hide')
          //console.log("In IF condition with closed model<<<<<<<<<<")
        });
      } else {
        this.setState({ dataresponse: items });
        //console.log("In ELSE condition AFTER CLOSED ******")
        // this.setState({ search_loader: false });
      }
      this.setState({ loading: false });
      this.setState({ search_loader: false });
    } catch (errorresponse) {
      console.table("Exception : ", errorresponse);
      this.setState({ loading: false });
      this.setState({ search_loader: false });
    }
  }

  async exportingData(closeModal) {
    try {
      this.setState({ loading: true });
      const api_response = await fetch(config.api.FM + ':' + this.state.issort + ':' + this.state.limit + ':' + this.state.export_flag,
        // `https://fuw9emc6dj.execute-api.us-east-1.amazonaws.com/prod/proxy+:FM`,
        {
          method: "GET",
          headers: {
            Authorization: this.context.token,
          },
        }
      );

      const json_response = await api_response.json();
      const items = json_response['Items']
      const csvData = items
      const fileName = 'FundManager'
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      if (closeModal) {
        this.setState({ dataresponse: items, isAddModel: false }, () => {
          window.$('#add-modal').modal('hide')
        });

      } else {
        this.setState({ dataresponse: items, exportData: items });

      }
      this.setState({ loading: false });
    } catch (errorresponse) {
      console.table("Exception : ", errorresponse);
      this.setState({ loading: false });
    }
  }


  async loadModelData(closeModal) {
    try {
      this.setState({ loading: true });

      const api_response = await fetch(config.api.FM + ':' + this.state.issort + ':' + this.state.limit,
        // `https://fuw9emc6dj.execute-api.us-east-1.amazonaws.com/prod/proxy+:FM`,
        {
          method: "GET",
          headers: {
            Authorization: this.context.token,
          },
        }
      );
      const json_response = await api_response.json();
      console.table("json_response --> ", json_response);
      //console.log('LastEvaluatedKey', json_response['LastEvaluatedKey'])
      'LastEvaluatedKey' in json_response ? this.setState({ loadmore: true }) : this.setState({ loadmore: false })
      const items = json_response['Items']
      //console.log("Json Items---->>", items)
      this.setState({ exportData: items });

      if (closeModal) {
        this.setState({ dataresponse: items, isAddModel: false }, () => {
          window.$('#add-modal').modal('hide')
        });

      } else {
        this.setState({ dataresponse: items, exportData: items });

      }
      this.setState({ loading: false });
    } catch (errorresponse) {
      console.table("Exception : ", errorresponse);
      this.setState({ loading: false });
    }
  }

  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
    //console.log("context from landing page", this.context);
    // //console.log("token from landingpage  : ", this.context.token);
    this.loadModelData()
  }
  edit = (e, sk, Fund_Manager) => {
    if (e.target.textContent == "Edit") {
      this.setState(
        (prevState) => ({
          editing: !prevState.editing,
          selectedIndex: sk,
          Fund_Manager: Fund_Manager
        }));
    }
    else {
      this.setState(
        (prevState) => ({
          editing: !prevState.editing,
          selectedIndex: sk,
          Fund_Manager: ''
        }));
    }
  };
  ondropdownchange = (event, key) => {
    //console.log("event : ", event);
    //console.log("key : ", key);
    this.state.statusValue[parseInt(key)] = event.label;
    //console.log("this.statusValue", this.statusValue);
  };


  handleScroll = () => {
    //console.log(this.isFilter,this.state.isFilter, this.state.F_limit, this.state.limit,this.state.loadmore,this.state.moreData)
    // //console.log(window.innerHeight, window.scrollY, document.body.offsetHeight, this.state.loadmore)
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight & this.state.loadmore & this.state.moreData) {
      //console.log("you're at the bottom of the page");
      //console.log(window.innerHeight, window.scrollY, document.body.offsetHeight, this.state.loadmore)
      if (this.state.isFilter) {
        this.setState({ F_limit: this.state.F_limit + 5 })
        //console.log("RECORDS LOADED----->>>", this.state.F_limit)
        this.filteredloadModelData()
        //console.log("this.state.isFilter", this.state.isFilter)
      }
      else {
        this.setState({ limit: this.state.limit + 5 })
        //console.log("RECORDS LOADED----->>>", this.state.limit)
        this.loadModelData()
      }


    }
  };

  handlefilter = () => {
    this.setState({ F_limit: this.state.F_limit + 5 })
    //console.log("RECORDS LOADED----->>>", this.state.F_limit)
    this.filteredloadModelData()
    //console.log("this.state.isFilter", this.state.isFilter)
  };

  ascendingsort = () => {
    if (this.state.isFilter) {
      this.state.issort = true
      this.filteredloadModelData()
    }
    else {
      this.state.issort = true
      this.loadModelData()
    }
  }

  descendingsort = () => {
    if (this.state.isFilter) {
      this.state.issort = false
      this.filteredloadModelData()
    }
    else {
      this.state.issort = false
      this.loadModelData()
    }
  }

  onsort = () => {
    if (this.state.filtersort) {
      this.state.filtersort = false
      this.state.sortIcon = "▼"
      this.descendingsort()
    }
    else {
      this.state.filtersort = true
      this.state.sortIcon = "▲"
      this.ascendingsort()
    }
  }

  clearHandler = e => {
    this.state.inputvalue = ''
    this.state.moreData = true
    this.loadModelData()
  }

  renderTableData() {
    return this.state.dataresponse.map((model_data, index) => {
      const {
        sk,
        Fund_Manager
      } = model_data;

      return (
        <tr>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <Input style={{ height: 38 }}
                type="text"
                name="Fund_Manager"
                id="Fund_Manager"
                defaultValue={Fund_Manager || ''}
                onChange={this.handleChange}
                required></Input>

            ) : (
                <div>{Fund_Manager}</div>
              )}
          </td>
          {this.context.user_grp == config.cognito.UPDATE_ROLE ? (
            <td>
              {this.state.editing && this.state.selectedIndex == sk ? (
                <Button style={{ marginBottom: 5, height: 5, width: 110, textAlign: "center", display: "grid", outline: "none", alignContent: "center" }}
                  id={sk}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => this.handlerForSubmit(sk)}>
                  Save
                </Button>
              ) : null}
              <Button style={{ height: 5, marginBottom: 3, width: 110, textAlign: "center", display: "grid", outline: "none", alignContent: "center" }}
                type="button"
                className="btn btn-primary"
                onClick={(e) => this.edit(e, sk, Fund_Manager)}>
                {this.state.editing && this.state.selectedIndex == sk ? "Cancel" : "Edit"}
              </Button>
              {/* <Button style={{ height: 5, width: 110, textAlign: "center", display: "grid", outline: "none", alignContent: "center" }}
                  id={sk}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => this.handlerForDelete(sk)}>
                  Delete
                </Button> */}
            </td>
          ) : null}
        </tr>
      );
    });
  }

  tohomepage = () => {
    window.location.assign(config.azure.LOGIN_URL);
  };


  createRecord() {
    if (config.cognito.UPDATE_ROLE == this.context.user_grp) {
      return (
        <Link to="/create_datalist_fund_manager_form">
          <button style={{}} type="button" className="btn btn-primary">
            Create Record
        </button>
        </Link>
      );
    } else {
      return null;
    }
  }

  render() {
    if (this.context == undefined) {
      this.tohomepage();
      return false;
    }
    const {
      baseinputValue, inputvalue
    } = this.state
    return (
      <Container>
        <div role="tab" style={{ textAlign: 'center' }}>
          <h1 className="panel-title " style={{ fontSize: 20 }}>
            XSP DataList - Fund Manager
            </h1>
        </div>
        <div style={{ textAlign: 'center', color: 'red' }}>
          {this.state.search_msg}

        </div>
        <Table style={{ alignContent: "center", width: 600, }} >
          <thead>
            <tr>
              <td><Label>Filtering Method</Label></td>
              <td><Label>{baseinputValue}</Label></td>
            </tr>
            <tr>
              <td>
                <Input
                  type='select'
                  name='baseinputValue'
                  value={baseinputValue}
                  onChange={this.ChangeHandler}
                  style={{ width: '180px' }}
                >
                  <option></option>
                  <option>Fund Manager</option>
                </Input>
              </td>
              <td>
                <Input
                  type='text'
                  name='inputvalue'
                  value={inputvalue}
                  onChange={this.ChangeHandlerform}
                  style={{ width: '180px' }}
                />
              </td>
              <td>
                <Button style={{ outline: "none" }} type='submit' className="btn btn-primary" onClick={this.clearHandler}>Clear</Button>
              </td>
              <td>
                <button style={{ outline: "none" }} onClick={() => { this.filteredloadModelData(); }} id="submit-btn" type="button" className="btn btn-primary btn btn-secondary" >Search</button>
              </td>
              <td>

                <button style={{ outline: "none", textAlign: 'right' }} onClick={() => { this.exportingData(); }} id="submit-btn" type="button" className="btn btn-primary btn btn-secondary">Export</button>

              </td>
              {this.state.butt_disable ? (
                <td >
                  <ClockLoader size={25} color='blue' loading={this.state.search_loader} />
                </td>) : null}
            </tr>

          </thead>
        </Table>
        <ScrollToTop smooth showUnder={5} duration={1000} smooth color="#6f00ff" >
          <button
            style={{
              display: "inline-block",
              marginLeft: "15px"
            }} >UP
          </button>
        </ScrollToTop>
        {<div style={{ margin: 10 }} >
          {this.createRecord()}
          {/* {this.ExportCSV(this.state.dataresponse,'FundManager')} */}
        </div>}
        <div style={{ textAlign: 'right', color: 'green' }}>
          {this.state.del_msg}
        </div>

        <div style={{ fontSize: 15 }}>
          <Table className="model-table-margin table table-bordered table-hover tabScrl" style={{ "overflow-y": "scroll" }} >
            <thead className="tableFixHead">
              <tr>
                <th onClick={this.onsort}>Fund Manager
                    {this.state.sortIcon}
                </th>
                {config.cognito.UPDATE_ROLE == this.context.user_grp ? (
                  <th style={{ width: 220, textAlign: "center" }}>Action</th>
                ) : null}
              </tr>
            </thead>
            <tbody>{this.renderTableData()}</tbody>
          </Table>
          <div className="load-spinner">
            <BeatLoader size={25} color='blue' loading={this.state.loading} />
          </div>
          <div className={this.state.moreData ? 'hidden' : ''}>
            <div className={this.state.search_loader ? 'hidden' : ''}>
              <p><strong>No Records Found.</strong></p>
            </div>
          </div>
        </div>
      </Container>
    );

  }
}

export default FundManager;
