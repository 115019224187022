import React from "react";
import { Table, Container, Button, FormGroup, Input, Form, Label } from "reactstrap";
import "react-dropdown/style.css";
import "./RISA.css";
import UserContext from "../../../UserContext";
import config from "../../../../auth_config";
import { BeatLoader, MoonLoader, ClockLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import apis from './../../../../apis';
import AsyncSelect from 'react-select/async';
import ScrollToTop from 'react-scroll-up';
import "react-data-export";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { confirmAlert } from 'react-confirm-alert';

// import Popup from 'react-popup';
// import PopUp from 'react-base-popup';
// import Dialog from 'react-bootstrap-dialog'

class RISA extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      //   token: null,
      dataresponse: [],
      responsedata: {},
      isAddModel: false,
      errorresponse: null,
      loading: false,
      statusValue: { 0: "UPDATEMODEL" },
      selectedIndex: 0,
      editing: false,
      parms1: '',
      parms2: '',
      Errors: '',
      moreData: true,
      loadmore: true,
      limit: 25,
      F_limit: 25,
      isFilter: false,
      issort: true,
      filtersort: true,
      sortIcon: '▲',
      exportData: [],
      export_flag: true,
      del_msg: '',
      check: true,
      search_msg: "",
      search_loader: false,
      butt_disable: false
    };

    this.onEditFormSubmit = this.onEditFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.ChangeHandler = this.ChangeHandler.bind(this);
    this.ChangeHandlerform = this.ChangeHandlerform.bind(this);
    this.deafult_account_list = []
    this.deafult_FM_list = []
    this.deafult_InvType_list = []
  }

  ChangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
    this.parms1 = e.target.value
    this.setState({ F_limit: 25 })
  }

  ChangeHandlerform = e => {
    this.setState({ [e.target.name]: e.target.value })
    this.parms2 = e.target.value
    this.setState({ F_limit: 25 })
  }

  componentWillUnmount() {
    // remove event listener
    window.removeEventListener('scroll', this.handleScroll, false);

  }

  handleChange(event) {
    //console.log('event.target::', event.target);
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  }

  onCreate() {
    document.getElementById("duplicateRecord").innerHTML = "";
  }

  handlerForSubmit = (sk) => {
    this.onEditFormSubmit(this.state, sk);
  };



  validate = () => {
    let AccountError = '';
    let Business_EntityError = '';
    let Fund_ManagerError = '';
    let Investment_StrategyError = '';
    if (!this.state.Account) {
      AccountError = "Account can not be blank";
    }
    if (!this.state.Investment_Strategy) {
      Investment_StrategyError = "Investment Strategy can not be blank";
    }
    if (!this.state.Fund_Manager) {
      Fund_ManagerError = "Fund Manager can not be blank";
    }
    if (!this.state.Business_Entity) {
      Business_EntityError = "Business Entity can not be blank";
    }
    if (AccountError || Business_EntityError || Fund_ManagerError || Investment_StrategyError) {
      this.setState({ AccountError, Business_EntityError, Fund_ManagerError, Investment_StrategyError })
      this.state.AccountError = AccountError
      this.state.Business_EntityError = Business_EntityError
      this.state.Fund_ManagerError = Fund_ManagerError
      this.state.Investment_StrategyError = Investment_StrategyError
      return false;
    }
    return true;
  }
  fetchDataPortfolio = (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        fetch(config.api.PortfoliosList + inputValue, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.context.token
          },
        })
          .then((resp) => {
            return resp.json()
          })
          .then((data) => {
            const tempArray = [];

            data.forEach((element) => {
              const labels = element.Name + "(" + element.Code + ")"
              tempArray.push({ label: `${labels}`, value: element.Code });
            });
            callback(tempArray);
          })
          .catch((error) => {
            //console.log(error, "catch the hoop")
          });
      });
    }
  }


  onSearchChangePortfolio = (selectedOption, name) => {
    if (selectedOption) {
      //console.log("-------------------", selectedOption.value, name)

      this.setState({ [name]: selectedOption.value, selectedOption: this.selectedOption })
    }
    //console.log("..............", this.Account)
  };

  handlerForDelete = (sk) => {
    this.submitDelete(sk)
  };
  confirmation_msg = (msg, cls) => {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (

          <div className="react-confirm-alert-body-msg">
            <div className={cls}><h6 >{msg}</h6></div>


          </div>
        );
      }
    });
  };

  submitDelete = (sk) => {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (

          <div className="react-confirm-alert-body">
            <h3>Are you Sure?</h3>
            <h6>You want to delete this record?</h6>
            <Button style={{ marginRight: 5 }} className="btn btn-primary" onClick={onClose}>No</Button>
            <Button
              className="btn btn-primary"
              onClick={() => {
                this.onDeleteSubmit(sk);
                onClose();
              }}
            >
              Yes, Delete it
              </Button>
          </div>

        );
      }
    });
  };


  onDeleteSubmit(sk) {
    const apiUrl = config.api.RISA + ':$' + 'DEL';
    //console.log(sk)
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.context.token
      },
      body: JSON.stringify({ sk, "user_email": this.context.user_email })

    })
      .then(response => {
        if (response.status === 200) {
          this.state.del_msg = "Record has been sucessfully deleted."
          this.loadModelData()
          const msg = "Record has been successfully deleted."
          this.confirmation_msg(msg, "isa_success")
        }

      })

  }

  onEditFormSubmit(value, sk) {
    const isvalid = this.validate();
    if (isvalid) {
      //console.log(sk)
      this.setState({ loading: true });
      const apiUrl = config.api.RISA + ':$' + sk;
      // `https://uzro6za8jc.execute-api.us-east-1.amazonaws.com/new/proxy:RISA:${sk}?filters=`;
      //console.log("value", value);
      const sknew = "#Account#" + value["Account"] + "#BE#" + value["Business_Entity"] + '#'
      const updatedvalues = {
        "oldsk": value["selectedIndex"], "Account": value["Account"], "Fund_Manager": value["Fund_Manager"],
        "Business_Entity": value["Business_Entity"], "Investment_Strategy": value["Investment_Strategy"], "SK": sknew, "user_email": this.context.user_email
      }

      fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          Authorization: this.context.token
        },
        body: JSON.stringify(updatedvalues)

      })
        .then(response => {
          //console.log("RESP", response)
          if (response.status === 304) {
            let alertmessage = 'Data already exists';
            this.setState({ alertmessage })
            //console.log('Data already exists');
            this.confirmation_msg(alertmessage, "isa_warning")
          }
          else {
            this.setState({ error: false })
            this.setState({ responsedata: response })
            const msg = "Record has been successfully edited."
            this.confirmation_msg(msg, "isa_success")
          }
          this.loadModelData()
        })

      this.setState((prevState) => ({
        editing: !prevState.editing,
      }));
      this.setState({ loading: false });
    }
    else {
      this.setState({ showForm: true });
      alert(this.state.AccountError + " " + this.state.Fund_ManagerError + " " + this.state.Business_EntityError + " " +
        this.state.Investment_StrategyError)
      //console.log("Invalid details")
    }
  }

  async filteredloadModelData(closeModal) {

    this.setState({ search_loader: true })
    this.setState({ limit: 25 })
    //console.log("RESET LIMIT - ",this.state.F_limit)
    try {
      let url = config.api.RISA + ':' + this.state.issort + ':' + this.state.F_limit
      this.setState({ loading: true });
      //console.log(this.parms1, this.parms2, this.parms1, this.parms1, this.parms2)
      //console.log(this.parms1, this.parms2, this.parms1 !== 'Select', this.parms1 !== undefined & this.parms2 !== undefined)
      if (this.parms1 !== undefined & this.parms2 !== undefined & this.parms1 !== 'Select' & this.parms1 !== '' & this.parms2 !== '') {
        this.setState({ isFilter: true })

        //console.log('filteredloadModelData', this.state.isFilter)
        const key = (this.parms1).replace(/ /g, "_");
        this.setState({ butt_disable: true })
        let params = {
          "filters": key,
          "param2": this.parms2
        };
        //console.log(this.param1, this.parms2)
        let query = Object.keys(params)
          .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
          .join('&');
        //console.log(params)
        url = config.api.RISA + ':' + this.state.issort + ':' + this.state.F_limit + "?" + query
      }
      else {
        this.setState({ isFilter: false })
        const msg = "Please enter Filter criteria"
        this.confirmation_msg(msg, "isa_error")
      }
      const api_response = await fetch(url,
        // `https://uzro6za8jc.execute-api.us-east-1.amazonaws.com/new/proxy:RIS?`+query,
        {
          method: "GET",
          headers: {
            Authorization: this.context.token,
          },
        }
      );
      const json_response = await api_response.json();
      console.table("json_response --> ", json_response);
      json_response['Count'] === 0 ? this.setState({ moreData: false }) : this.setState({ moreData: true })
      //console.log('LastEvaluatedKey', json_response['LastEvaluatedKey'],json_response['Count'])
      'LastEvaluatedKey' in json_response ? this.setState({ loadmore: true }) : this.setState({ loadmore: false })
      'LastEvaluatedKey' in json_response ? this.setState({ search_loader: true }) : this.setState({ search_loader: false })
      const items = json_response['Items']
      //console.log("Json Items---->>", items)
      //console.log(items.length)

      if (items.length > 25 || 'LastEvaluatedKey' in json_response == false) {

        this.setState({ search_loader: false })
      }
      if (items.length < 25 && this.state.loadmore) {
        this.handlefilter()
      }
      if (closeModal) {
        this.setState({ dataresponse: items, isAddModel: false }, () => {
          window.$('#add-modal').modal('hide')
        });
      } else {
        this.setState({ dataresponse: items });
      }
      this.setState({ loading: false });
      // this.setState({ search_loader: false });
    } catch (error) {
      console.table("Exception : ", error);
      this.setState({ Errors: error })
      this.setState({ loading: false });
      this.setState({ search_loader: false });
    }
  }

  //-----------------------------Fund Manager------------------
  fetchDataFM = (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
    } else {
      let params = {
        "filters": "Fund_Manager",
        "param2": inputValue
      };
      let query = Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
      //console.log(params)
      setTimeout(() => {
        fetch(config.api.FM + "?" + query, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.context.token
          },
        })
          .then((resp) => {
            return resp.json()
          })
          .then((data) => {
            const tempArray = [];
            let len = data.length;
            (data.length < len) ? len = data.length : len = data.length;
            for (var i = 0; i < len; i++) {
              const element = data[i]
              tempArray.push({ label: `${element.Fund_Manager}` })
            }
            callback(tempArray);
          })
          .catch((error) => {
            //console.log(error, "catch the hoop")
          });
      });
    }
  }


  onSearchChangeFM = (selectedOption, name) => {
    if (selectedOption) {
      //console.log("-------------------", selectedOption.label, name)

      this.setState({ [name]: selectedOption.label, selectedOption: this.selectedOption })
    }
    //console.log("..............", this.Fund_Manager)
  };

  //new logic for auto complete
  fetchDataPortfolio_t = (inputValue) => {

    setTimeout(() => {
      fetch(config.api.PortfoliosList + inputValue, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          Authorization: this.context.token
        },
      })
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {
          data.forEach((element) => {
            const label = element.Name + " " + "(" + element.Code + ")"
            this.deafult_account_list.push({ label: `${label}`, value: element.Code });
          });
          this.deafult_account_list.sort((a, b) =>
            a.label > b.label ? 1 : -1
          );
          if (this.deafult_account_list.length > 1512) {
            this.setState({ check: false })

          }
        })
        .catch((error) => {
          //console.log(error, "catch the hoop")
        });
    });
  }

  fetchDataFM_t = (inputValue) => {

    let params = {
      "filters": "Fund_Manager",
      "param2": inputValue
    };
    let query = Object.keys(params)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
    //console.log(params)
    setTimeout(() => {
      fetch(config.api.FM + "?" + query, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          Authorization: this.context.token
        },
      })
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {

          let len = data.length;
          (data.length < len) ? len = data.length : len = data.length;
          for (var i = 0; i < len; i++) {
            const element = data[i]
            this.deafult_FM_list.push({ label: `${element.Fund_Manager}` })
          }

        })
        .catch((error) => {
          //console.log(error, "catch the hoop")
        });
    });

  }
  //-----------------------------Investment_Strategy------------------
  fetchDataIS = (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
    } else {
      let params = {
        "filters": "Investment_Strategy",
        "param2": inputValue
      };
      let query = Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
      //console.log(params)
      setTimeout(() => {
        fetch(config.api.IS + "?" + query, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.context.token
          },
        })
          .then((resp) => {
            return resp.json()
          })
          .then((data) => {
            const tempArray = [];
            let len = data.length;
            (data.length < len) ? len = data.length : len = data.length;
            for (var i = 0; i < len; i++) {
              const element = data[i]
              tempArray.push({ label: `${element.Investment_Strategy}` })
            }
            callback(tempArray);
          })
          .catch((error) => {
            //console.log(error, "catch the hoop")
          });
      });
    }
  }

  fetchDataIS_t = (inputValue) => {
    let params = {
      "filters": "Investment_Strategy",
      "param2": inputValue
    };
    let query = Object.keys(params)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
    //console.log(params)
    setTimeout(() => {
      fetch(config.api.IS + "?" + query, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          Authorization: this.context.token
        },
      })
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {

          let len = data.length;
          (data.length < len) ? len = data.length : len = data.length;
          for (var i = 0; i < len; i++) {
            const element = data[i]
            this.deafult_InvType_list.push({ label: `${element.Investment_Strategy}` })
          }

        })
        .catch((error) => {
          //console.log(error, "catch the hoop")
        });
    });

  }


  onSearchChangeIS = (selectedOption, name) => {
    if (selectedOption) {
      //console.log("-------------------", selectedOption.label, name)

      this.setState({ [name]: selectedOption.label, selectedOption: this.selectedOption })
    }
    //console.log("..............", this.Investment_Strategy)
  };

  async loadModelData(closeModal) {
    try {
      this.setState({ check: true });
      this.setState({ loading: true });

      const api_response = await fetch(config.api.RISA + ':' + this.state.issort + ':' + this.state.limit,
        // `https://uzro6za8jc.execute-api.us-east-1.amazonaws.com/new/proxy:RISA?`,
        {
          method: "GET",
          headers: {
            Authorization: this.context.token,
          },
        }
      );
      const json_response = await api_response.json();
      console.table("json_response --> ", json_response);
      //console.log('LastEvaluatedKey', json_response['LastEvaluatedKey'])
      'LastEvaluatedKey' in json_response ? this.setState({ loadmore: true }) : this.setState({ loadmore: false })
      const items = json_response['Items']
      //console.log("Json Items---->>", items)
      this.setState({ exportData: items });
      this.setState({ check: false });
      if (closeModal) {
        this.setState({ dataresponse: items, isAddModel: false }, () => {
          window.$('#add-modal').modal('hide')
        });
      } else {
        this.setState({ dataresponse: items });
      }
      this.setState({ loading: false });
    } catch (error) {
      console.table("Exception : ", error);
      this.setState({ Errors: error });
      this.setState({ loading: false });
    }
  }

  async exportingData(closeModal) {
    try {
      this.setState({ loading: true });
      // this.setState({export_flag:true})
      // this.state.export_flag =true
      //console.log("state.........",this.state.export_flag,"kooooooo")

      const api_response = await fetch(config.api.RISA + ':' + this.state.issort + ':' + this.state.limit + ':' + this.state.export_flag,
        // `https://fuw9emc6dj.execute-api.us-east-1.amazonaws.com/prod/proxy+:FM`,
        {
          method: "GET",
          headers: {
            Authorization: this.context.token,
          },
        }
      );
      const json_response = await api_response.json();
      console.table("json_response --> ", json_response);
      //console.log('LastEvaluatedKey', json_response['LastEvaluatedKey'])
      'LastEvaluatedKey' in json_response ? this.setState({ loadmore: true }) : this.setState({ loadmore: false })
      const items = json_response['Items']
      //console.log("Json Items---->>", items)

      this.setState({ exportData: items });
      const csvData = items
      const fileName = 'RISA'
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      if (closeModal) {
        this.setState({ dataresponse: items, isAddModel: false }, () => {
          window.$('#add-modal').modal('hide')
        });

      } else {
        this.setState({ dataresponse: items, exportData: items });

      }
      this.setState({ loading: false });
    } catch (errorresponse) {
      console.table("Exception : ", errorresponse);
      this.setState({ loading: false });
    }
  }

  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, false);
    //console.log("context from landing page", this.context);
    // //console.log("token from landingpage  : ", this.context.token);

    this.fetchDataFM_t("")
    this.fetchDataPortfolio_t("")
    this.fetchDataIS_t("")
    this.loadModelData()
    //console.log("fm", this.deafult_FM_list)
    //console.log("inv", this.deafult_InvType_list)
    //console.log("acc", this.deafult_account_list)

  }
  edit = (e, sk, Account, Investment_Strategy, Business_Entity, Fund_Manager, ) => {
    if (e.target.textContent == "Edit") {
      this.setState(
        (prevState) => ({
          editing: !prevState.editing,
          selectedIndex: sk,
          Account: Account,
          Investment_Strategy: Investment_Strategy,
          Business_Entity: Business_Entity,
          Fund_Manager: Fund_Manager,
        }));
    }
    else {
      this.setState(
        (prevState) => ({
          editing: !prevState.editing,
          selectedIndex: sk,
          Account: '',
          Investment_Strategy: '',
          Business_Entity: '',
          Fund_Manager: '',
        }));
    }
  };
  ondropdownchange = (event, key) => {
    //console.log("event : ", event);
    //console.log("key : ", key);
    this.state.statusValue[parseInt(key)] = event.label;
    //console.log("this.statusValue", this.statusValue);
  };

  clearHandler = e => {
    this.state.inputvalue = ''
    this.loadModelData()
    this.state.moreData = true
  }

  renderTableData() {
    return this.state.dataresponse.map((model_data, index) => {
      const {
        sk,
        Account,
        Investment_Strategy,
        Business_Entity,
        Fund_Manager,
      } = model_data;

      return (
        <tr>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (
              <AsyncSelect
                value={this.state.selectedOption}
                loadOptions={this.fetchDataIS}
                defaultValue={this.state.selectedOption}
                defaultInputValue={Investment_Strategy || ''}
                name="Investment_Strategy"
                maxMenuHeight={150}
                onInputChange={(e) => {

                  this.onSearchChangeIS(e, "Investment_Strategy");
                }}
                onChange={(e) => {

                  this.onSearchChangeIS(e, "Investment_Strategy");
                }}
                defaultOptions={this.deafult_InvType_list.sort((a, b) => (a.label > b.label ? 1 : -1))}
              />

            ) : (
                <div>{Investment_Strategy}</div>
              )}
          </td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <AsyncSelect
                value={this.state.selectedOption}
                loadOptions={this.fetchDataPortfolio}
                defaultInputValue={Account || ''}
                name="Account"
                maxMenuHeight={150}
                onInputChange={(e) => {

                  this.onSearchChangePortfolio(e, "Account");
                }}
                onChange={(e) => {

                  this.onSearchChangePortfolio(e, "Account");
                }}
                defaultOptions={this.deafult_account_list.sort((a, b) => (a.label > b.label ? 1 : -1))}
              />

            ) : (
                <div>{Account}</div>
              )}
          </td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <Input style={{ height: 38 }}
                type="select"
                name="Business_Entity"
                id="Business_Entity"
                defaultValue={Business_Entity || ''}
                onChange={this.handleChange}
                required>
                <option> </option>
                <option>BUS01</option>
                <option>BUS02</option>

              </Input>

            ) : (
                <div>{Business_Entity}</div>
              )}</td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <AsyncSelect
                value={this.state.selectedOption}
                loadOptions={this.fetchDataFM}
                defaultInputValue={Fund_Manager || ''}
                name="Fund_Manager"
                maxMenuHeight={150}
                onInputChange={(e) => {

                  this.onSearchChangeFM(e, "Fund_Manager");
                }}
                onChange={(e) => {

                  this.onSearchChangeFM(e, "Fund_Manager");
                }}
                defaultOptions={this.deafult_FM_list.sort((a, b) => (a.label > b.label ? 1 : -1))}
              />

            ) : (
                <div>{Fund_Manager}</div>
              )}
          </td>
          {this.context.user_grp == config.cognito.UPDATE_ROLE ? (
            <td>
              {this.state.editing && this.state.selectedIndex == sk ? (
                <Button style={{ marginLeft: 15, marginBottom: 5, height: 5, width: 110, textAlign: "center", display: "grid", outline: "none", alignContent: "center" }}
                  id={sk}
                  type="button"
                  className="btn btn-primary pull-left"
                  onClick={(e) => this.handlerForSubmit(sk)}>
                  Save
                </Button>
              ) : null}
              <div className="btn-toolbar pull-right">

                <Button style={{ height: 5, width: 110, textAlign: "center", display: "grid", outline: "none", alignContent: "center" }}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => this.edit(e, sk, Account, Investment_Strategy, Business_Entity, Fund_Manager)}>
                  {this.state.editing && this.state.selectedIndex == sk ? "Cancel" : "Edit"}
                </Button>


                <Button style={{ height: 5, width: 110, textAlign: "center", display: "grid", outline: "none", alignContent: "center" }}
                  id={sk}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => this.handlerForDelete(sk)}>
                  Delete
                </Button>

              </div>
            </td>
          ) : null}
        </tr>
      );
    });
  }

  tohomepage = () => {
    window.location.assign(config.azure.LOGIN_URL);
  };
  handleScroll = () => {
    //console.log(this.isFilter,this.state.isFilter, this.state.F_limit, this.state.limit,this.state.loadmore,this.state.moreData)
    // //console.log(window.innerHeight, window.scrollY, document.body.offsetHeight, this.state.loadmore)
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight & this.state.loadmore & this.state.moreData) {
      //console.log("you're at the bottom of the page");
      //console.log(window.innerHeight, window.scrollY, document.body.offsetHeight, this.state.loadmore)
      if (this.state.isFilter) {
        this.setState({ F_limit: this.state.F_limit + 5 })
        //console.log("RECORDS LOADED----->>>", this.state.F_limit)
        this.filteredloadModelData()
        //console.log("this.state.isFilter", this.state.isFilter)
      }
      else {
        this.setState({ limit: this.state.limit + 5 })
        //console.log("RECORDS LOADED----->>>", this.state.limit)
        this.loadModelData()
      }


    }
  };

  ascendingsort = () => {
    if (this.state.isFilter) {
      this.state.issort = true
      this.filteredloadModelData()
    }
    else {
      this.state.issort = true
      this.loadModelData()
    }
  }

  descendingsort = () => {
    if (this.state.isFilter) {
      this.state.issort = false
      this.filteredloadModelData()
    }
    else {
      this.state.issort = false
      this.loadModelData()
    }
  }

  onsort = () => {
    if (this.state.filtersort) {
      this.state.filtersort = false
      this.state.sortIcon = "▼"
      this.descendingsort()
    }
    else {
      this.state.filtersort = true
      this.state.sortIcon = "▲"
      this.ascendingsort()
    }
  }

  handlefilter = () => {
    this.setState({ F_limit: this.state.F_limit + 5 })

    //console.log("RECORDS LOADED----->>>", this.state.F_limit)
    this.filteredloadModelData()
    //console.log("this.state.isFilter", this.state.isFilter)
  };

  createRecored() {
    if (config.cognito.UPDATE_ROLE == this.context.user_grp) {
      return (<Link to="/Add_RISA">
        <button style={{ marginLeft: -100 }} type="button" className="btn btn-primary">
          Create Record
        </button>
      </Link>);
    } else {
      return null;
    }
  }

  // exportToCSV = (e,csvData,fileName) => {
  //   // debugger
  // //console.log("file name and data",fileName ,"data....",csvData)
  //   const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  //   const fileExtension = '.xlsx';
  //   const ws = XLSX.utils.json_to_sheet(csvData);
  //   const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  //   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  //   const data = new Blob([excelBuffer], {type: fileType});
  //   FileSaver.saveAs(data, fileName + fileExtension);
  //   //console.log(fileName)

  // }

  render() {
    if (this.context == undefined) {
      this.tohomepage();
      return false;
    }
    const {
      baseinputValue, inputvalue
    } = this.state
    return (
      <div>
        <Container >
          <div role="tab" style={{ textAlign: 'center' }}>
            <h1 className="panel-title " style={{ fontSize: 20 }}>
              Recipient Investment Strategy Account
            </h1>
            <h5>{this.state.Error}</h5>
          </div>
          <div style={{ textAlign: 'center', color: 'red' }}>
            {this.state.search_msg}
          </div>
          <Table style={{ alignContent: "center", width: 600, marginLeft: -100 }} >
            <thead>
              <tr>
                <td><Label>Filtering Method</Label></td>
                <td><Label>{baseinputValue}</Label></td>
              </tr>
              <tr>
                <td>
                  <Input
                    type='select'
                    name='baseinputValue'
                    value={baseinputValue}
                    onChange={this.ChangeHandler}
                    style={{ width: '200px' }}
                  >
                    <option>Select</option>
                    <option>Account</option>
                    <option>Investment Strategy</option>
                    <option>Business Entity</option>
                    <option>Fund Manager</option>
                  </Input>
                </td>
                <td>
                  <Input
                    type='text'
                    name='inputvalue'
                    value={inputvalue}
                    onChange={this.ChangeHandlerform}
                    style={{ width: '190px' }}
                  />
                </td>
                <td>
                  <Button style={{ outline: "none" }} type='submit' className="btn btn-primary" onClick={this.clearHandler}>Clear</Button>
                </td>
                <td>
                  <button style={{ outline: "none" }} onClick={() => { this.filteredloadModelData(); }} id="submit-btn" type="button" className="btn btn-primary btn btn-secondary" >Search</button>
                </td>
                <td>

                  <button style={{ outline: "none", textAlign: 'right' }} onClick={() => { this.exportingData(); }} id="submit-btn" type="button" className="btn btn-primary btn btn-secondary">Export</button>

                </td>
                {this.state.butt_disable ? (
                  <td >
                    <ClockLoader size={25} color='blue' loading={this.state.search_loader} />
                  </td>) : null}
              </tr>
            </thead>

          </Table>
          {<div style={{ margin: 10 }} >
            {this.createRecored()}
          </div>}

          <div style={{ fontSize: 15 }}>
            <Table className="model-table-margin table table-bordered table-hover" style={{ alignContent: "center", width: 1300, height: 5, marginLeft: -100, "tableLayout": "fixed", "overflow": "hidden", "overflowY": "scroll" }}>
              <thead className="tableFixHead">
                <tr>
                  <th>Investment Strategy</th>
                  <th onClick={this.onsort}>Account
                    {this.state.sortIcon}
                  </th>
                  <th>Business Entity</th>
                  <th>Fund Manager</th>
                  {config.cognito.UPDATE_ROLE == this.context.user_grp ? (
                    <th>Action</th>

                  ) : null}
                </tr>
              </thead>
              <tbody>{this.renderTableData()}</tbody>
            </Table>
            <ScrollToTop smooth showUnder={5} duration={1000} smooth color="#6f00ff" >
              <button
                style={{
                  display: "inline-block",
                  marginLeft: "15px"
                }} >UP
          </button>
            </ScrollToTop>
            <div className="load-spinner">
              {/* <BeatLoader size={25} color='blue' loading={this.state.loading} /> */}
              <MoonLoader size={25} color="blue" loading={this.state.check} />
            </div>
            <div className={this.state.search_loader ? 'hidden' : ''}>
              <div className={this.state.moreData ? 'hidden' : ''}>
                <p><strong>No Records Found.</strong></p>

              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
export default RISA;