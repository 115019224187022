
import React, { Component } from 'react';
import axios from 'axios'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap'
import UserContext from '../../../UserContext';
import apis from './../../../../apis';
import config from '../../../../auth_config';
import AsyncSelect from 'react-select/async'
import '../../error.css'
import { Link } from 'react-router-dom';
import { RingLoader, ClimbingBoxLoader } from 'react-spinners';
import { confirmAlert } from 'react-confirm-alert';

class Add_RISA extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props)

        this.state = {
            Investment_Strategy: '',
            Account: '',
            Business_Entity: '',
            Fund_Manager: '',
            SK: '',
            error: false,
            Accountreset: false,
            Investment_Strategyreset: false,
            Business_Entityreset: false,
            Fund_Managerreset: false,
            ishidden: false,
            check: true
        }
        this.ChangeHandler = this.ChangeHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.deafult_account_list = [];
        this.deafult_fund_list = [];
        this.deafult_investment_list = [];
    }

    componentDidMount() {
        //console.log("page loaded")
        this.fetchDataFM_t("")
        this.fetchDataIS_t("")
        this.fetchDataPortfolio_t("")
        // this.fetchDataPortCorp_t("DRI")
        // this.fetchData_t("B")
        // this.is_initial = true
        // this.fetchDataCurrency_t("Dollar")
        // this.fetchDataSIAT_t("ne")
    }

    ChangeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
        this.setState({ ['SK']: "#Account#" + this.state.Account + "#BE#" + this.state.Business_Entity })
        //console.log({ ['SK']: "#Account#" + this.state.Account + "#BE#" + this.state.Business_Entity })
    }

    //-----------------------------Standing Instruction Action Type ------------------
    fetchDataFM = (inputValue, callback) => {
        if (!inputValue) {
            callback([]);
        } else {
            let params = {
                "filters": "Fund_Manager",
                "param2": inputValue
            };
            let query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
            //console.log(params)
            setTimeout(() => {
                fetch(config.api.FM + "?" + query, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: this.context.token
                    },
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        const tempArray = [];
                        let len = data.length;
                        (data.length < len) ? len = data.length : len = data.length;
                        for (var i = 0; i < len; i++) {
                            const element = data[i]
                            tempArray.push({ label: `${element.Fund_Manager}` })
                        }
                        callback(tempArray);
                    })
                    .catch((error) => {
                        //console.log(error, "catch the hoop")
                    });
            });
        }
    }

    fetchDataFM_t = (inputValue) => {

        let params = {
            "param1": "Fund_Manager",
            "param2": inputValue
        };
        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        //console.log(params)
        setTimeout(() => {
            fetch(config.api.FM + "?" + query, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: this.context.token
                },
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((result) => {
                    const data = result.sort((a, b) => (a.Fund_Manager > b.Fund_Manager ? 1 : -1))
                    // const tempArray = [];


                    let len = data.length;
                    (data.length < len) ? len = data.length : len = data.length;
                    for (var i = 0; i < len; i++) {
                        const element = data[i]
                        // tempArray.push({ label: `${element.Fund_Manager}`})
                        this.deafult_fund_list.push({ label: `${element.Fund_Manager}` });
                    }
                    // callback(tempArray);
                })
                .catch((error) => {
                    //console.log(error, "catch the hoop")
                });
        });
    }



    onSearchChangeFM = (selectedOption, name) => {
        if (selectedOption) {
            //console.log("-------------------", selectedOption.label, name)

            this.setState({ [name]: selectedOption.label, Fund_Managerreset: false, ishidden: false, selectedOption: this.selectedOption })
        }
        //console.log("..............", this.Fund_Manager)
    };


    //-----------------------------Standing Instruction Action Type ------------------
    fetchDataIS = (inputValue, callback) => {
        if (!inputValue) {
            callback([]);
        } else {
            let params = {
                "filters": "Investment_Strategy",
                "param2": inputValue
            };
            let query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
            //console.log(params)
            setTimeout(() => {
                fetch(config.api.IS + "?" + query, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: this.context.token
                    },
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        const tempArray = [];
                        let len = data.length;
                        (data.length < len) ? len = data.length : len = data.length;
                        for (var i = 0; i < len; i++) {
                            const element = data[i]
                            tempArray.push({ label: `${element.Investment_Strategy}` })
                        }
                        callback(tempArray);
                    })
                    .catch((error) => {
                        //console.log(error, "catch the hoop")
                    });
            });
        }
    }

    fetchDataIS_t = (inputValue) => {
        // if (!inputValue) {
        //     callback([]);
        // } else {
        let params = {
            "param1": "Investment_Strategy",
            "param2": inputValue
        };
        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        //console.log(params)
        setTimeout(() => {
            fetch(config.api.IS + "?" + query, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: this.context.token
                },
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((result) => {
                    // const tempArray = [];
                    const data = result.sort((a, b) => (a.Investment_Strategy > b.Investment_Strategy ? 1 : -1))

                    let len = data.length;
                    (data.length < len) ? len = data.length : len = data.length;
                    for (var i = 0; i < len; i++) {
                        const element = data[i]
                        this.deafult_investment_list.push({ label: `${element.Investment_Strategy}` })
                        // tempArray.push({ label: `${element.Investment_Strategy}`})
                    }
                    // callback(tempArray);
                })
                .catch((error) => {
                    //console.log(error, "catch the hoop")
                });
        });
    }



    onSearchChangeIS = (selectedOption, name) => {
        if (selectedOption) {
            //console.log("-------------------", selectedOption.label, name)

            this.setState({ [name]: selectedOption.label, Investment_Strategyreset: false, ishidden: false, selectedOption: this.selectedOption })
        }
        //console.log("..............", this.Investment_Strategy)
    };


    fetchDataPortfolio = (inputValue, callback) => {
        if (!inputValue) {
            callback([]);
        } else {
            setTimeout(() => {
                fetch(config.api.PortfoliosList + inputValue, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: this.context.token
                    },
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        const tempArray = [];
                        data.forEach((element) => {
                            const labels = element.Name + "(" + element.Code + ")"
                            tempArray.push({ label: `${labels}`, value: element.Code });
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        //console.log(error, "catch the hoop")
                    });
            });
        }
    }

    fetchDataPortfolio_t = (inputValue) => {
        // if (!inputValue) {
        //   callback([]);
        // } else {
        setTimeout(() => {
            fetch(config.api.PortfoliosList + inputValue, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: this.context.token
                },
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {

                    data.forEach((element) => {
                        const label_value = element.Name + " " + "(" + element.Code + ")"
                        this.deafult_account_list.push({ label: `${label_value}`, value: element.Code })

                    });
                    this.deafult_account_list.sort((a, b) => (a.label > b.label ? 1 : -1))
                    // callback(tempArray);
                    if (this.deafult_account_list.length > 1512) {
                        this.setState({ check: false })

                    }
                })
                .catch((error) => {
                    //console.log(error, "catch the hoop")
                });
        });
    }



    onSearchChangePortfolio = (selectedOption, name) => {
        if (selectedOption) {
            //console.log("-------------------", selectedOption.value, name)

            this.setState({ [name]: selectedOption.value, Accountreset: false, ishidden: false, selectedOption: this.selectedOption })
        }
        //console.log("..............", this.Country_Of_IssueCode)
    };


    validate = () => {
        let AccountError = '';
        let Business_EntityError = '';
        let Fund_ManagerError = '';
        let Investment_StrategyError = '';
        if (!this.state.Account) {
            AccountError = "Account can not be blank";
        }
        if (!this.state.Investment_Strategy) {
            Investment_StrategyError = "Investment Strategy can not be blank";
        }
        if (!this.state.Fund_Manager) {
            Fund_ManagerError = "Fund Manager can not be blank";
        }
        if (!this.state.Business_Entity) {
            Business_EntityError = "BusinessEntity can not be blank";
        }
        if (AccountError || Business_EntityError || Fund_ManagerError || Investment_StrategyError) {
            this.setState({ AccountError, Business_EntityError, Fund_ManagerError, Investment_StrategyError })
            return false;
        }
        else {
            this.setState(
                (prevState) => ({
                    AccountError: '',
                    Investment_StrategyError: '',
                    Business_EntityError: '',
                    Fund_ManagerError: '',
                }));
        }
        return true;
    }

    clearHandler = e => {
        e.preventDefault();
        this.state.selectedOption = null
        this.state.ishidden = true
        this.setState({
            Accountreset: true,
            Investment_Strategyreset: true,
            Business_Entityreset: true,
            Fund_Managerreset: true,
            Account: '',
            Investment_Strategy: '',
            Business_Entity: '',
            Fund_Manager: '',
        });
    }

    confirmation_msg = (msg, cls) => {

        confirmAlert({
            customUI: ({ onClose }) => {
                return (

                    <div className="react-confirm-alert-body-msg">
                        <div className={cls}><h6 >{msg}</h6></div>


                    </div>
                );
            }
        });
    };

    submitHandler(e) {
        const isvalid = this.validate();
        if (isvalid) {
            //console.log("Button clicked")
            e.preventDefault();
            e.target.reset();
            const value = this.state
            let alertmessage = '';
            this.setState({ alertmessage })
            //console.log("TOKEN", this.context.token)
            this.setState({ ['SK']: "#Account" + this.state.Account + "#BE" + this.state.Business_Entity })
            const sk = "#Account#" + this.state.Account + "#BE#" + this.state.Business_Entity + '#'
            //console.log("SK",sk)
            const updatedvalues = {
                "SK": sk, "Account": value["Account"],
                "Fund_Manager": value["Fund_Manager"], "Business_Entity": value["Business_Entity"],
                "Investment_Strategy": value["Investment_Strategy"], "user_email": this.context.user_email
            }
            //console.log("this.context","user_email",this.context.user_email)
            axios({
                method: 'POST',
                url: config.api.RISA,
                //  'https://uzro6za8jc.execute-api.us-east-1.amazonaws.com/new/proxy:RISA?filters=',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: this.context.token
                },
                data: JSON.stringify(updatedvalues)
            })
                .then(response => {
                    //console.log(response)
                    let alertmessage = 'Record has been successfully added.';
                    this.setState({ alertmessage })
                    this.setState({ error: false })
                    this.confirmation_msg(alertmessage, "isa_success")
                })
                .catch(error => {
                    //console.log(error.response)
                    if (error.response.status === 304) {
                        let alertmessage = 'Data already exists ...Try again';
                        this.setState({ alertmessage })
                        this.setState({ error: true })
                        this.confirmation_msg(alertmessage, "isa_warning")
                    }
                });
        }
        else {
            e.preventDefault();
            this.setState({ showForm: true });
        }
    }

    tohomepage = () => {
        //console.log('Redirect user to home --->');
        window.location.assign(config.azure.LOGIN_URL);
    };

    cancelCourse = () => {
        this.myFormRef.reset();
    }

    render() {
        if (this.context == undefined) {
            //console.log('Context is undefined --->');
            this.tohomepage();
            return false;
        }
        // const deafult_investment_list=[{label: '3rd Party',value: '3rd Party'},{label: 'CASH',value: 'CASH'}]
        // const deafult_fund_list=[{label: 'PGS_IT',value: 'PGS_IT'},{label: 'test5',value: 'test5'},{label: 'EDGE',value: 'EDGE'}]
        // const deafult_account_list=[ {value: '1008', label: 'PLSA MONEY MKT'}, {value: '1010CORP', label: 'PLSA BNDMTGCORP'}, {value: '1011', label: 'PLSA INTL STK'}, {value: '1012', label: 'PLSA STK INDEX'}]
        const {
            Investment_Strategy, Account, Business_Entity
            , Fund_Manager
        } = this.state
        return (
            <div className="container"  >
                <div role="tab" style={{ textAlign: 'center' }}>
                    <h1 className="panel-title " style={{ fontSize: 20 }}>
                        Create Recipient Investment Strategy Account
                  </h1>
                </div>

                <div style={{ marginLeft: 900, marginBottom: -5 }}><Link style={{ alignItems: 'center', marginRight: 2 }} className="btn btn-primary" to="/RISA">Back</Link><Button style={{ outline: "none" }} type='submit' className="btn btn-primary" onClick={this.clearHandler}>Clear</Button></div>
                <Form className="form-group" style={{ alignContent: "center", width: 600, padding: 30 }} onSubmit={this.submitHandler}>
                    {/* <div className={this.state.error ? 'error' : 'success'}>{this.state.ishidden?null:this.state.alertmessage}</div> */}
                    {/* <div>
                            <RingLoader size={30} color="blue" loading={this.state.check} />
                     </div> */}
                    <FormGroup>
                        <Label>Investment Strategy</Label>
                        <AsyncSelect
                            value={this.state.Investment_Strategyreset ? null : this.state.selectedOption}
                            loadOptions={this.fetchDataIS}
                            defaultOptions={this.deafult_investment_list.sort((a, b) => (a.label > b.label ? 1 : -1)) || ''}
                            placeholder="Investment Strategy"
                            maxMenuHeight={150}
                            name="Investment Strategy"
                            onChange={(e) => {
                                this.onSearchChangeIS(e, "Investment_Strategy");
                            }}
                        />
                        <div style={{ color: "red", fontSize: 15 }}>{this.state.Investment_StrategyError}</div>
                    </FormGroup>

                    <FormGroup>

                        <RingLoader size={18} color="blue" loading={this.state.check} />

                        <Label>Account</Label>
                        <AsyncSelect
                            value={this.state.Accountreset ? null : this.state.selectedOption}
                            loadOptions={this.fetchDataPortfolio}
                            defaultOptions={this.deafult_account_list.sort((a, b) => (a.label > b.label ? 1 : -1)) || ''}
                            name="Account"
                            maxMenuHeight={150}
                            placeholder="Account"
                            onChange={(e) => {

                                this.onSearchChangePortfolio(e, "Account");
                            }}
                        />
                        <div style={{ color: "red", fontSize: 15 }}>{this.state.AccountError}</div>
                    </FormGroup>

                    <FormGroup>
                        <Label>Business Entity</Label>
                        <Input
                            type='select'
                            name='Business_Entity'
                            placeholder="Business Entity"
                            value={Business_Entity}
                            onChange={this.ChangeHandler}
                        >
                            <option> </option>
                            <option>BUS01</option>
                            <option>BUS02</option>
                        </Input>
                        <div style={{ color: "red", fontSize: 15 }}>{this.state.Business_EntityError}</div>
                    </FormGroup>

                    <FormGroup>
                        <Label>Fund Manager</Label>
                        <AsyncSelect
                            value={this.state.Fund_Managerreset ? null : this.state.selectedOption}
                            loadOptions={this.fetchDataFM}
                            defaultOptions={this.deafult_fund_list || ''}
                            maxMenuHeight={150}
                            placeholder="Fund Manager"
                            maxMenuHeight={170}
                            name="Fund Manager"
                            onChange={(e) => {
                                this.onSearchChangeFM(e, "Fund_Manager");
                            }}
                        />
                        <div style={{ color: "red", fontSize: 15 }}>{this.state.Fund_ManagerError}</div>
                    </FormGroup>

                    <Button style={{ outline: "none" }} type='submit' className="btn btn-primary" onSubmit={this.submitHandler} >Save</Button>
                </Form>
            </div>

        )
    }
}

export default Add_RISA;