import React from "react";
import { Table, Container, Button, FormGroup, Input, Form, Label } from "reactstrap";
import "react-dropdown/style.css";
import "./RecipentInvestmentTable.css";
import config from "../../../../auth_config";
import { BeatLoader, ClockLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import apis from './../../../../apis';
import UserContext from "../../../UserContext";
import AsyncSelect from 'react-select/async';
import ScrollToTop from 'react-scroll-up';
import "react-data-export";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { confirmAlert } from 'react-confirm-alert';

class RecipentInvestmentTable extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      //   token: null,
      dataresponse: [],
      responsedata: {},
      isAddModel: false,
      errorresponse: null,
      loading: false,
      statusValue: { 0: "UPDATEMODEL" },
      selectedIndex: 0,
      editing: false,
      parms1: '',
      parms2: '',
      moreData: true,
      loadmore: true,
      limit: 25,
      F_limit: 25,
      isFilter: false,
      issort: true,
      filtersort: true,
      sortIcon: '▲',
      exportData: [],
      del_msg: '',
      search_msg: "",
      export_flag: true,
      search_loader: false,
      butt_disable: false

    };

    this.onEditFormSubmit = this.onEditFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.ChangeHandler = this.ChangeHandler.bind(this);
    this.ChangeHandlerform = this.ChangeHandlerform.bind(this);
    this.default_InvType_list = []
  }

  ChangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
    this.parms1 = e.target.value
    this.setState({ F_limit: 25 })
  }
  handleChangeRole = event => {
    //console.log("kk")
    //console.log('event.target::', event.target);
    const name = event.target.name;
    const value = 'no';
    this.setState({
      [name]: value,
    });
  }


  ChangeHandlerRole = event => {
    //console.log('event.target::', event.target.value);
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
    if (event.target.value == 'Read Only') {
      if (this.state.NotificationCreate === 'Y' || this.state.NotificationTypeIRR === 'Y' || this.state.NotificationTypeANN === 'Y') {
        alert("NotificationCreate, NotificationTypeIRR ,NotificationTypeANN will be set to NO as role name is Read Only")
      }
      this.state.isDisable = true
      this.state.NotificationCreate = 'N'
      this.state.NotificationTypeIRR = 'N'
      this.state.NotificationTypeANN = 'N'

    }
    else {

      this.setState({ isDisable: false })
    }
  }


  componentWillUnmount() {
    // remove event listener
    window.removeEventListener('scroll', this.handleScroll, false);

  }

  ChangeHandlerform = e => {
    this.setState({ [e.target.name]: e.target.value })
    this.parms2 = e.target.value
    this.setState({ F_limit: 25 })
  }

  handleChange(event) {
    //console.log('event.target::', event.target);
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  }

  handlerForSubmit = (sk) => {
    this.onEditFormSubmit(this.state, sk);
  };

  validate = () => {
    let Investment_StrategyError = '';
    let RecipentCodeError = '';
    let NotificationTypeIRRError = '';
    let NotificationCreateError = '';
    let NotificationTypeANNError = '';
    let First_NameError = '';
    let Last_NameError = '';
    let Role_NameError = '';
    let Email_AddressError = '';
    if (!this.state.Investment_Strategy) {
      Investment_StrategyError = "Investment Strategy can not be blank";
    }
    if (!this.state.RecipentCode) {
      RecipentCodeError = "Recipent Code can not be blank";
    }
    if (!this.state.NotificationTypeIRR) {
      NotificationTypeIRRError = "Notification Type IRR can not be blank";
    }
    if (!this.state.NotificationCreate) {
      NotificationCreateError = "Notification Create can not be blank";
    }
    if (!this.state.NotificationTypeANN) {
      NotificationTypeANNError = "Notification Type ANN can not be blank";
    }
    if (!this.state.First_Name) {
      First_NameError = "First Name can not be blank";
    }
    if (!this.state.Last_Name) {
      Last_NameError = "Last Name can not be blank";
    }
    if (!this.state.Role_Name) {
      Role_NameError = "Role Name can not be blank";
    }
    if (!this.state.Email_Address) {
      Email_AddressError = "Email Address can not be blank";
    } else if (!this.state.Email_Address.includes('@')) {
      Email_AddressError = "Please enter a valid email address";
    }
    if (Investment_StrategyError || RecipentCodeError || NotificationTypeIRRError || NotificationCreateError ||
      NotificationTypeANNError || First_NameError || Last_NameError || Role_NameError || Email_AddressError) {
      this.setState({
        Investment_StrategyError, RecipentCodeError, NotificationTypeIRRError, NotificationCreateError,
        NotificationTypeANNError, First_NameError, Last_NameError, Role_NameError, Email_AddressError
      })
      this.state.RecipentCodeError = RecipentCodeError
      this.state.Investment_StrategyError = Investment_StrategyError
      this.state.NotificationTypeIRRError = NotificationTypeIRRError
      this.state.NotificationCreateError = NotificationCreateError
      this.state.NotificationTypeANNError = NotificationTypeANNError
      this.state.First_NameError = First_NameError
      this.state.Last_NameError = Last_NameError
      this.state.Role_NameError = Role_NameError
      this.state.Email_AddressError = Email_AddressError

      return false;
    }
    return true;
  }

  //-----------------------------Investment Strategy ------------------
  fetchDataIS = (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
    } else {
      let params = {
        "filters": "Investment_Strategy",
        "param2": inputValue
      };
      let query = Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
      //console.log(params)
      setTimeout(() => {
        fetch(config.api.IS + "?" + query, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.context.token
          },
        })
          .then((resp) => {
            return resp.json()
          })
          .then((data) => {
            const tempArray = [];
            let len = data.length;
            (data.length < len) ? len = data.length : len = data.length;
            for (var i = 0; i < len; i++) {
              const element = data[i]
              tempArray.push({ label: `${element.Investment_Strategy}` })
            }
            callback(tempArray);
          })
          .catch((error) => {
            //console.log(error, "catch the hoop")
          });
      });
    }
  }

  fetchDataIS_t = (inputValue, callback) => {

    let params = {
      "filters": "Investment_Strategy",
      "param2": inputValue
    };
    let query = Object.keys(params)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
    //console.log(params)
    setTimeout(() => {
      fetch(config.api.IS + "?" + query, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          Authorization: this.context.token
        },
      })
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {

          let len = data.length;
          (data.length < len) ? len = data.length : len = data.length;
          for (var i = 0; i < len; i++) {
            const element = data[i]
            this.default_InvType_list.push({ label: `${element.Investment_Strategy}` })
          }

        })
        .catch((error) => {
          //console.log(error, "catch the hoop")
        });
    });

  }


  onSearchChangeIS = (selectedOption, name) => {
    if (selectedOption) {
      //console.log("-------------------",selectedOption.label, name)

      this.setState({ [name]: selectedOption.label, selectedOption: this.selectedOption })
    }
    //console.log("..............", this.Investment_Strategy)
  };

  handlerForDelete = (sk) => {
    this.submitDelete(sk)
  };

  confirmation_msg = (msg, cls) => {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (

          <div className="react-confirm-alert-body-msg">
            <div className={cls}><h6 >{msg}</h6></div>


          </div>
        );
      }
    });
  };

  submitDelete = (sk) => {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (

          <div className="react-confirm-alert-body">
            <h3>Are you Sure?</h3>
            <h6>You want to delete this record?</h6>
            <Button style={{ marginRight: 5 }} className="btn btn-primary" onClick={onClose}>No</Button>
            <Button
              className="btn btn-primary"
              onClick={() => {
                this.onDeleteSubmit(sk);
                onClose();
              }}
            >
              Yes, Delete it!
                </Button>
          </div>

        );
      }
    });
  };



  onDeleteSubmit(sk) {
    const apiUrl = config.api.RIS + ':$' + 'DEL';
    //console.log(sk)
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.context.token
      },
      body: JSON.stringify({ sk, "user_email": this.context.user_email })

    })
      .then(response => {
        if (response.status === 200) {
          this.loadModelData()
          const msg = "Record has been successfully deleted."
          this.confirmation_msg(msg, "isa_success")
        }

      })

  }


  onEditFormSubmit(value, sk) {
    const isvalid = this.validate();
    if (isvalid) {
      //console.log(sk)
      this.setState({ loading: true });
      const apiUrl = config.api.RIS + ":$" + sk
      // `https://uzro6za8jc.execute-api.us-east-1.amazonaws.com/new/proxy:RIS:${sk}?filters=`;
      //console.log("value", value);
      const sknew = "#RecipentCode#" + value["RecipentCode"] + '#'
      const updatedvalues = {
        "oldsk": value["selectedIndex"], "Investment_Strategy": value["Investment_Strategy"], "NotificationTypeANN": value["NotificationTypeANN"],
        "RecipentCode": value["RecipentCode"], NotificationTypeIRR: value["NotificationTypeIRR"], "NotificationCreate": value["NotificationCreate"],
        "First_Name": value["First_Name"], "Last_Name": value["Last_Name"], "Role_Name": value["Role_Name"], "Email_Address": value["Email_Address"],
        "SK": sknew, "user_email": this.context.user_email
      }

      fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          Authorization: this.context.token
        },
        body: JSON.stringify(updatedvalues)

      })
        .then(response => {
          //console.log("RESP", response)
          if (response.status === 304) {
            let alertmessage = 'Data already exists';
            this.setState({ alertmessage })
            //console.log('Data already exists');
            this.confirmation_msg(alertmessage, "isa_warning")
          }
          else {
            this.setState({ error: false })
            this.setState({ responsedata: response })
            const msg = "Record has been successfully edited."
            this.confirmation_msg(msg, "isa_success")
          }
          this.loadModelData()
        })

      this.setState((prevState) => ({
        editing: !prevState.editing,
      }));
      this.setState({ loading: false });
    }
    else {
      this.setState({ showForm: true });
      //console.log("Invalid details")
      alert(this.state.Investment_StrategyError + " " + this.state.RecipentCodeError + " " + this.state.NotificationCreateError + " " +
        this.state.NotificationTypeANNError + " " + this.state.NotificationTypeIRRError + " " + this.state.First_NameError + " " +
        this.state.Last_NameError + " " + this.state.Role_NameError + " " + this.state.Email_AddressError)
    }
  }

  async filteredloadModelData(closeModal) {
    this.setState({ search_loader: true })
    this.setState({ limit: 25 })
    //console.log("RESET LIMIT - ",this.state.F_limit)
    try {
      let url = config.api.RIS + ':' + this.state.issort + ':' + this.state.F_limit
      this.setState({ loading: true });
      //console.log("=====in try block ======")

      //console.log(this.parms1, this.parms2, this.parms1 !== 'Select', this.parms1 !== undefined & this.parms2 !== undefined)
      if (this.parms1 !== undefined & this.parms2 !== undefined & this.parms1 !== 'Select' & this.parms1 !== '' & this.parms2 !== '') {
        this.setState({ isFilter: true })
        this.setState({ butt_disable: true })
        //console.log("=====in if condition  ======")
        //console.log('filteredloadModelData', this.state.isFilter)
        const key = (this.parms1).replace(/ /g, "_");
        this.setState({ search_msg: "" })
        let params = {
          "filters": key,
          "param2": this.parms2
        };
        //console.log(this.param1, this.parms2)
        let query = Object.keys(params)
          .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
          .join('&');
        //console.log(params)
        url = config.api.RIS + ':' + this.state.issort + ':' + this.state.F_limit + "?" + query
      }
      else {
        this.setState({ isFilter: false })
        const msg = "Please enter Filter criteria."
        this.confirmation_msg(msg, "isa_error")
      }
      const api_response = await fetch(url,
        // `https://uzro6za8jc.execute-api.us-east-1.amazonaws.com/new/proxy:RIS?`+query,
        {
          method: "GET",
          headers: {
            Authorization: this.context.token,
          },
        }
      );
      const json_response = await api_response.json();
      console.table("json_response --> ", json_response);
      json_response['Count'] === 0 ? this.setState({ moreData: false }) : this.setState({ moreData: true })
      //console.log('LastEvaluatedKey', json_response['LastEvaluatedKey'],json_response['Count'])
      'LastEvaluatedKey' in json_response ? this.setState({ loadmore: true }) : this.setState({ loadmore: false })
      // 'LastEvaluatedKey' in json_response ? this.setState({ search_loader: true }) : this.setState({ search_loader: false })
      const items = json_response['Items']
      //console.log("Json Items---->>", 'LastEvaluatedKey' in json_response)
      //console.log(items.length)

      if (items.length > 25 || 'LastEvaluatedKey' in json_response == false) {

        this.setState({ search_loader: false })
      }

      if (items.length < 25 && this.state.loadmore) {
        this.handlefilter()
      }
      if (closeModal) {

        this.setState({ dataresponse: items, isAddModel: false }, () => {
          window.$('#add-modal').modal('hide')
        });
      } else {

        this.setState({ dataresponse: items });

      }
      this.setState({ loading: false });
      // this.setState({ search_loader: false });
    } catch (errorresponse) {
      console.table("Exception : ", errorresponse);
      this.setState({ search_loader: false });
      this.setState({ loading: false });
    }
  }

  async loadModelData(closeModal) {
    try {
      this.setState({ loading: true });

      const api_response = await fetch(config.api.RIS + ':' + this.state.issort + ':' + this.state.limit,
        // `https://uzro6za8jc.execute-api.us-east-1.amazonaws.com/new/proxy:RIS?`,
        {
          method: "GET",
          headers: {
            Authorization: this.context.token,
          },
        }
      );
      const json_response = await api_response.json();
      console.table("json_response --> ", json_response);
      //console.log('LastEvaluatedKey', json_response['LastEvaluatedKey'])
      'LastEvaluatedKey' in json_response ? this.setState({ loadmore: true }) : this.setState({ loadmore: false })
      const items = json_response['Items']
      //console.log("Json Items---->>", items)
      this.setState({ exportData: items });
      if (closeModal) {
        this.setState({ dataresponse: items, isAddModel: false }, () => {
          window.$('#add-modal').modal('hide')
        });
      } else {
        this.setState({ dataresponse: items });
      }
      this.setState({ loading: false });
    } catch (errorresponse) {
      console.table("Exception : ", errorresponse);
      this.setState({ loading: false });
    }
  }

  async componentDidMount() {
    this.listener = window.addEventListener('scroll', this.handleScroll, false);
    //console.log("context from landing page", this.context);
    // //console.log("token from landingpage  : ", this.context.token);
    this.loadModelData()
    this.fetchDataIS_t("")
  }
  edit = (e, sk, Investment_Strategy, RecipentCode, NotificationTypeANN, NotificationTypeIRR, NotificationCreate,
    First_Name, Last_Name, Role_Name, Email_Address) => {
    if (e.target.textContent == "Edit") {
      this.setState(
        (prevState) => ({
          editing: !prevState.editing,
          selectedIndex: sk,
          Investment_Strategy: Investment_Strategy,
          RecipentCode: RecipentCode,
          NotificationTypeANN: NotificationTypeANN,
          NotificationTypeIRR: NotificationTypeIRR,
          NotificationCreate: NotificationCreate,
          First_Name: First_Name,
          Last_Name: Last_Name,
          Role_Name: Role_Name,
          Email_Address: Email_Address,
        }));
    }
    else {
      this.setState(
        (prevState) => ({
          editing: !prevState.editing,
          selectedIndex: sk,
          Investment_Strategy: '',
          RecipentCode: '',
          NotificationTypeANN: '',
          NotificationTypeIRR: '',
          NotificationCreate: '',
          First_Name: '',
          Last_Name: '',
          Role_Name: '',
          Email_Address: '',
        }));
    }
  };
  ondropdownchange = (event, key) => {
    //console.log("event : ", event);
    //console.log("key : ", key);
    this.state.statusValue[parseInt(key)] = event.label;
    //console.log("this.statusValue", this.statusValue);
  };

  async exportingData(closeModal) {
    try {
      this.setState({ loading: true });
      // this.setState({export_flag:true})
      // this.state.export_flag =true
      //console.log("state.........",this.state.export_flag,"kooooooo")

      const api_response = await fetch(config.api.RIS + ':' + this.state.issort + ':' + this.state.limit + ':' + this.state.export_flag,
        // `https://fuw9emc6dj.execute-api.us-east-1.amazonaws.com/prod/proxy+:FM`,
        {
          method: "GET",
          headers: {
            Authorization: this.context.token,
          },
        }
      );
      const json_response = await api_response.json();
      const items = json_response['Items']

      const csvData = items
      const fileName = 'RIS'
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      if (closeModal) {
        this.setState({ dataresponse: items, isAddModel: false }, () => {
          window.$('#add-modal').modal('hide')
        });

      } else {
        this.setState({ dataresponse: items, exportData: items });

      }
      this.setState({ loading: false });
    } catch (errorresponse) {
      console.table("Exception : ", errorresponse);
      this.setState({ loading: false });
    }
  }

  clearHandler = e => {
    this.state.inputvalue = ''
    this.state.moreData = true
    this.loadModelData()
  }

  renderTableData() {
    return this.state.dataresponse.map((model_data, index) => {
      const {
        sk,
        Investment_Strategy,
        RecipentCode,
        NotificationTypeANN,
        NotificationTypeIRR,
        NotificationCreate,
        First_Name,
        Last_Name,
        Role_Name,
        Email_Address
      } = model_data;

      return (
        <tr>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <AsyncSelect
                value={this.state.selectedOption}
                loadOptions={this.fetchDataIS}
                defaultInputValue={Investment_Strategy || ''}
                name="Investment_Strategy"
                maxMenuHeight={150}
                onInputChange={(e) => {
                  this.onSearchChangeIS(e, "Investment_Strategy");
                }}
                onChange={(e) => {
                  this.onSearchChangeIS(e, "Investment_Strategy");
                }}
                defaultOptions={this.default_InvType_list.sort((a, b) => (a.label > b.label ? 1 : -1))}
              />

            ) : (
                <div style={{ width: 170 }}>{Investment_Strategy}</div>
              )}
          </td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <Input
                type="text"
                name="RecipentCode"
                id="RecipentCode"
                defaultValue={RecipentCode || ''}
                onChange={this.handleChange}
                required></Input>

            ) : (
                <div style={{ width: 105 }}>{RecipentCode}</div>
              )}
          </td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <Input
                type="select"
                name="NotificationTypeANN"
                id="NotificationTypeANN"
                defaultValue={NotificationTypeANN || ''}
                disabled={this.state.isDisable === true ? true : false}
                onChange={this.handleChange}
                required>
                <option> </option>
                <option>Y</option>
                <option>N</option>
              </Input>

            ) : (
                <div>{NotificationTypeANN}</div>
              )}</td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <Input
                type="select"
                name="NotificationTypeIRR"
                id="NotificationTypeIRR"
                defaultValue={NotificationTypeIRR || ''}
                disabled={this.state.isDisable === true ? true : false}
                onChange={this.handleChange}
                required>
                <option> </option>
                <option>Y</option>
                <option>N</option>
              </Input>

            ) : (
                <div>{NotificationTypeIRR}</div>
              )}</td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <Input
                type="select"
                name="NotificationCreate"
                id="NotificationCreate"
                defaultValue={this.state.isDisable === true ? 'no' : this.state.NotificationCreate || ''}
                disabled={this.state.isDisable === true ? true : false}
                onChange={this.handleChange}
                required>
                <option> </option>
                <option>Y</option>
                <option>N</option>
              </Input>
            ) : (
                <div>{NotificationCreate}</div>
              )}</td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <Input
                type="text"
                name="First_Name"
                id="First_Name"
                defaultValue={First_Name || ''}
                onChange={this.handleChange}
                required></Input>

            ) : (
                <div style={{ width: 105 }}>{First_Name}</div>
              )}</td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <Input
                type="text"
                name="Last_Name"
                id="Last_Name"
                defaultValue={Last_Name || ''}
                onChange={this.handleChange}
                required></Input>
            ) : (
                <div style={{ width: 105 }}>{Last_Name}</div>
              )}</td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <Input
                type="select"
                name="Role_Name"
                id="Role_Name"
                defaultValue={Role_Name || ''}
                onChange={this.ChangeHandlerRole}
                required>
                <option selected disabled hidden>Select</option>
                <option>Approver</option>
                <option>Read Only</option>
              </Input>

            ) : (
                <div style={{ width: 140 }}>{Role_Name}</div>
              )}</td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <Input
                type="text"
                name="Email_Address"
                id="Email_Address"
                defaultValue={Email_Address || ''}
                onChange={this.handleChange}
                required></Input>

            ) : (
                <div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', width: 220 }}>{Email_Address}</div>
              )}
          </td>
          {config.cognito.UPDATE_ROLE == this.context.user_grp ? (
            <td>
              {this.state.editing && this.state.selectedIndex == sk ? (
                <Button style={{ marginLeft: 15, marginBottom: 5, height: 5, width: 110, textAlign: "center", display: "grid", outline: "none", alignContent: "center", marginBottom: 5 }}
                  id={sk}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => this.handlerForSubmit(sk)}>
                  Save
                </Button>
              ) : null}
              <Button style={{ marginLeft: 15, height: 5, marginBottom: 3, width: 110, textAlign: "center", display: "grid", outline: "none", alignContent: "center" }}
                type="button"
                className="btn btn-primary"
                onClick={(e) => this.edit(e, sk, Investment_Strategy, RecipentCode, NotificationCreate, NotificationTypeIRR, NotificationTypeANN,
                  First_Name, Last_Name, Role_Name, Email_Address)}>
                {this.state.editing && this.state.selectedIndex == sk ? "Cancel" : "Edit"}
              </Button>
              <div className="btn-toolbar pull-right">

                <Button style={{ height: 5, width: 110, textAlign: "center", display: "grid", outline: "none", alignContent: "center" }}
                  id={sk}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => this.handlerForDelete(sk)}>
                  Delete
                  </Button>

              </div>
            </td>
          ) : null}
        </tr>
      );
    });
  }

  tohomepage = () => {
    window.location.assign(config.azure.LOGIN_URL);
  };

  handleScroll = () => {
    //console.log(this.isFilter,this.state.isFilter, this.state.F_limit, this.state.limit,this.state.loadmore,this.state.moreData)
    // //console.log(window.innerHeight, window.scrollY, document.body.offsetHeight, this.state.loadmore)
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight & this.state.loadmore & this.state.moreData) {
      //console.log("you're at the bottom of the page");
      //console.log(window.innerHeight, window.scrollY, document.body.offsetHeight, this.state.loadmore)
      if (this.state.isFilter) {
        this.setState({ F_limit: this.state.F_limit + 5 })
        //console.log("RECORDS LOADED----->>>", this.state.F_limit)
        this.filteredloadModelData()
        //console.log("this.state.isFilter", this.state.isFilter)
      }
      else {
        this.setState({ limit: this.state.limit + 5 })
        //console.log("RECORDS LOADED----->>>", this.state.limit)
        this.loadModelData()
      }


    }
  };

  ascendingsort = () => {
    if (this.state.isFilter) {
      this.state.issort = true
      this.filteredloadModelData()
    }
    else {
      this.state.issort = true
      this.loadModelData()
    }
  }

  descendingsort = () => {
    if (this.state.isFilter) {
      this.state.issort = false
      this.filteredloadModelData()
    }
    else {
      this.state.issort = false
      this.loadModelData()
    }
  }

  onsort = () => {
    if (this.state.filtersort) {
      this.state.filtersort = false
      this.state.sortIcon = "▼"
      this.descendingsort()
    }
    else {
      this.state.filtersort = true
      this.state.sortIcon = "▲"
      this.ascendingsort()
    }
  }

  handlefilter = () => {
    this.setState({ F_limit: this.state.F_limit + 5 })
    //console.log("RECORDS LOADED----->>>", this.state.F_limit)
    this.filteredloadModelData()
    //console.log("this.state.isFilter", this.state.isFilter)
  };

  createRecored() {
    if (config.cognito.UPDATE_ROLE == this.context.user_grp) {
      return (<Link to="/investmentStreategy">
        <button style={{ marginLeft: -100 }} type="button" className="btn btn-primary">
          Create Record
        </button>
      </Link>);
    } else {
      return null;
    }
  }

  render() {
    if (this.context == undefined) {
      this.tohomepage();
      return false;
    }

    const {
      baseinputValue, inputvalue
    } = this.state
    return (
      <div onScroll={this.handleScroll}>
        <Container>
          <div role="tab" style={{ textAlign: 'center' }}>
            <h1 className="panel-title " style={{ fontSize: 20 }}>
              Recipient Investment Strategy
            </h1>
          </div>
          <div style={{ textAlign: 'center', color: 'red' }}>
            {this.state.search_msg}
          </div>
          <Table style={{ alignContent: "center", width: 600, marginLeft: -100 }} >
            <thead>
              <tr>
                <td><Label>Filtering Method</Label></td>
                <td><Label>{baseinputValue}</Label></td>
              </tr>
              <tr>
                <td>
                  <Input
                    type='select'
                    name='baseinputValue'
                    value={baseinputValue}
                    onChange={this.ChangeHandler}
                    style={{ width: '200px' }}
                  >
                    <option>Select</option>
                    <option>Investment Strategy</option>
                    <option>RecipentCode</option>
                    <option>NotificationTypeANN</option>
                    <option>NotificationTypeIRR</option>
                    <option>NotificationCreate</option>
                    <option>First Name</option>
                    <option>Last Name</option>
                    <option>Role Name</option>
                    <option>Email Address</option>
                  </Input>
                </td>
                <td>
                  <Input
                    type='text'
                    name='inputvalue'
                    value={inputvalue}
                    onChange={this.ChangeHandlerform}
                    style={{ width: '190px' }}
                  />
                </td>
                <td>
                  <Button style={{ outline: "none" }} type='submit' className="btn btn-primary" onClick={this.clearHandler}>Clear</Button>
                </td>
                <td>
                  <button style={{ outline: "none" }} onClick={() => { this.filteredloadModelData(); }} id="submit-btn" type="button" className="btn btn-primary btn btn-secondary" >Search</button>
                </td>
                <td>

                  <button style={{ outline: "none", textAlign: 'right' }} onClick={(e) => { this.exportingData(); }} id="submit-btn" type="button" className="btn btn-primary btn btn-secondary">Export</button>

                </td>
                {this.state.butt_disable ? (
                  <td >
                    <ClockLoader size={25} color='blue' loading={this.state.search_loader} />
                  </td>) : null}
              </tr>
            </thead>
          </Table>
          {<div style={{ margin: 10 }} >
            {this.createRecored()}
          </div>}
          <div style={{ fontSize: 15 }}>
            <Table className="model-table-margin table table-bordered table-hover" style={{ alignContent: "center", width: 1360, height: 5, marginLeft: -100, "tableLayout": "auto", "overflow": "hidden", "overflow-y": "scroll" }}>
              <thead className="tableFixHead">
                <tr>
                  <th style={{ width: 170 }}>Investment Strategy</th>
                  <th onClick={this.onsort}>Recipent Code
                    {this.state.sortIcon}
                  </th>
                  <th style={{ width: 100 }}>Notification TypeANN</th>
                  <th style={{ width: 100 }}>Notification TypeIRR</th>
                  <th style={{ width: 100 }}>Notification Create</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th style={{ width: 140 }}>Role Name</th>
                  <th style={{ width: 220, textAlign: "left" }}>Email Address</th>
                  {config.cognito.UPDATE_ROLE == this.context.user_grp ? (
                    <th style={{ alignContent: "center" }}>Action</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>{this.renderTableData()}</tbody>
            </Table>
            <ScrollToTop smooth showUnder={5} duration={1000} smooth color="#6f00ff" >
              <button
                style={{
                  display: "inline-block",
                  marginLeft: "15px"
                }} >UP
          </button>
            </ScrollToTop>
            <div className="load-spinner">
              <BeatLoader size={25} color='blue' loading={this.state.loading} />
            </div>
            <div className={this.state.search_loader ? 'hidden' : ''}>
              <div className={this.state.moreData ? 'hidden' : ''}>
                <p><strong>No Records Found.</strong></p>

              </div>
            </div>
          </div>
        </Container>
      </div>
    );

  }
}

export default RecipentInvestmentTable;
