import React, { Component } from 'react';
import axios from 'axios'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap'
import UserContext from '../../../UserContext';
import config from '../../../../auth_config';
import apis from './../../../../apis';
import AsyncSelect from 'react-select/async'
import '../../error.css';
import { BeatLoader, CircleLoader, MoonLoader, RotateLoader, PulseLoader, RingLoader, ClimbingBoxLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
class StandinginstructionForm extends Component {
    static contextType = UserContext;
    constructor(props) {
        // //console.log(this.context);
        super(props)

        this.state = {
            Account: '',
            Business_Entity: '',
            Corp_Action_Type: '',
            Country_Of_IssueCode: '',
            SI_Action_Type: '',

            Standing_Instruction_Currency_Code: '',
            SK: '',
            error: false,
            Accountreset: false,
            Business_Entityreset: false,
            Corp_Action_Typereset: false,
            Country_Of_IssueCodereset: false,
            SI_Action_Typereset: false,
            Standing_Instruction_Currency_Codereset: false,
            ishidden: false,
            check: true
        }
        this.ChangeHandler = this.ChangeHandler.bind(this);
        this.routeChange = this.routeChange.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.deafult_account_list = []
        this.deafult_countrycode_list = []
        this.deafult_currencycode_list = []
        this.deafult_corp_list = []
        this.deafult_SI_Action_Type_list = []
    }
    routeChange() {
        let path = `/standing_instructions`;
        this.props.history.push(path);
    }

    componentDidMount() {
        //console.log("page loaded")
        this.fetchDataPortfolio_t("")
        this.fetchDataPortCorp_t("")
        this.fetchData_t("")
        this.is_initial = true
        this.fetchDataCurrency_t("")
        this.fetchDataSIAT_t("")
    }

    componentDidUpdate() {
        this.is_initial = false
    }


    fetchDataPortCorp_t = (inputValue) => {

        setTimeout(() => {
            fetch(config.api.CorpActiontype + inputValue, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: this.context.token
                },
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {

                    data.forEach((element) => {

                        this.deafult_corp_list.push({ label: `${element.Name}`, value: element.MappingValue });
                    });

                })
                .catch((error) => {
                    //console.log(error, "catch the hoop")
                });
        });
    }





    fetchDataPortCorp = (inputValue, callback) => {
        if (!inputValue) {
            callback([]);
        } else {
            setTimeout(() => {
                fetch(config.api.CorpActiontype + inputValue, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: this.context.token
                    },
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        const tempArray = [];
                        data.forEach((element) => {
                            tempArray.push({ label: `${element.Name}`, value: element.MappingValue });
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        //console.log(error, "catch the hoop")
                    });
            });
        }
    }



    onSearchChangeCorp = (selectedOption, name) => {
        if (selectedOption) {
            //console.log("-------------------", selectedOption.value, name)

            this.setState({ [name]: selectedOption.value, ishidden: false, Corp_Action_Typereset: false, selectedOption: this.selectedOption })
        }
        //console.log("Country of Issue Code", this.Country_Of_IssueCode)
    };


    //----------------------------------portfolios---------------------
    //--------------------Account-----------------------------------------
    //new logic for auto complete
    fetchDataPortfolio_t = (inputValue) => {

        setTimeout(() => {
            fetch(config.api.PortfoliosList + inputValue, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: this.context.token
                },
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    const tempArray = [];
                    data.forEach((element) => {
                        const label = element.Name + " " + "(" + element.Code + ")"
                        this.deafult_account_list.push({ label: `${label}`, value: element.Code });
                    });
                    this.deafult_account_list.sort((a, b) => (a.label > b.label ? 1 : -1))
                    //console.log("initial loaded..Account")
                    //console.log(this.deafult_account_list)
                    if (this.deafult_account_list.length > 1512) {
                        this.setState({ check: false })

                    }
                })
                .catch((error) => {
                    //console.log(error, "catch the hoop")
                });
        });
    }

    fetchDataPortfolio = (inputValue, callback) => {

        if (!inputValue) {
            callback([]);
        } else {
            setTimeout(() => {
                fetch(config.api.PortfoliosList + inputValue, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: this.context.token
                    },
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        const tempArray = [];
                        data.forEach((element) => {
                            const labels = element.Name + "(" + element.Code + ")"
                            tempArray.push({ label: `${labels}`, value: element.Code });
                            if (this.is_initial) {
                                //console.log("initial loaded..a.a.a.a.a.")
                                this.deafult_account_list.push({ label: `${labels}`, value: element.Code });
                                //console.log(this.deafult_account_list)
                            }

                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        //console.log(error, "catch the hoop")
                    });
            });
        }
    }


    onSearchChangePortfolio = (selectedOption, name) => {
        if (selectedOption) {
            //console.log("-------------------", selectedOption.value, name)

            this.setState({ [name]: selectedOption.value, ishidden: false, Accountreset: false, selectedOption: this.selectedOption })
        }
        //console.log("..............", this.Account)
    };

    //-----------------------------Standing Instruction Action Type ------------------
    fetchDataSIAT_t = (inputValue) => {
        // if (!inputValue) {
        //     callback([]);
        // } else {
        let params = {
            "filters": "SI_Action_Type",
            "param2": inputValue
        };
        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        //console.log(params)
        setTimeout(() => {
            fetch(config.api.SIAT + "?" + query, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: this.context.token
                },
            })
                .then((resp) => {
                    //console.log("data....", resp)
                    return resp.json()
                })
                .then((result) => {
                    // const tempArray = [];
                    const data = result.sort((a, b) => (a.SI_Action_Type > b.SI_Action_Type ? 1 : -1))

                    let len = data.length;
                    (data.length < len) ? len = data.length : len = data.length;
                    for (var i = 0; i < len; i++) {
                        const element = data[i]
                        // tempArray.push({ label: `${element.SI_Action_Type}`})
                        this.deafult_SI_Action_Type_list.push({ label: `${element.SI_Action_Type}` })
                    };
                    //console.log("------------------", this.deafult_SI_Action_Type_list)
                    // callback(tempArray);
                })

                .catch((error) => {
                    //console.log(error, "catch the hoop")
                });
        });
    }


    // -----------------------------Standing Instruction Action Type ------------------
    fetchDataSIAT = (inputValue, callback) => {
        if (!inputValue) {
            callback([]);
        } else {
            let params = {
                "filters": "SI_Action_Type",
                "param2": inputValue
            };
            let query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
            //console.log(params)
            setTimeout(() => {
                fetch(config.api.SIAT + "?" + query, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: this.context.token
                    },
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        const tempArray = [];
                        let len = data.length;
                        (data.length < len) ? len = data.length : len = data.length;
                        for (var i = 0; i < len; i++) {
                            const element = data[i]
                            tempArray.push({ label: `${element.SI_Action_Type}` })
                        }

                        callback(tempArray);
                    })
                    .catch((error) => {
                        //console.log(error, "catch the hoop")
                    });
            });
        }
    }
    onSearchChangeSIAT = (selectedOption, name) => {
        if (selectedOption) {

            this.setState({ [name]: selectedOption.label, ishidden: false, SI_Action_Typereset: false, selectedOption: this.selectedOption })
        }

    };


    //----------------------------------currency code ---------------------
    fetchDataCurrency_t = (inputValue) => {
        setTimeout(() => {
            fetch(config.api.CurrencyList + inputValue, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: this.context.token,
                },
            })
                .then((resp) => {
                    return resp.json();
                })
                .then((data) => {
                    //console.log(".currnecy.data....",data)
                    data.forEach((element) => {
                        const label = element.Name + " " + "(" + element.Code + ")";
                        this.deafult_currencycode_list.push({
                            label: `${label}`,
                            value: element.Code,
                        });
                    });
                    this.deafult_currencycode_list.sort((a, b) =>
                        a.label > b.label ? 1 : -1
                    );
                })
                .catch((error) => {
                    //console.log(error, "catch the hoop");
                });
        });
    };




    // new logic for auto complete
    fetchDataCurrency = (inputValue, callback) => {
        if (!inputValue) {
            callback([]);
        } else {
            setTimeout(() => {
                fetch(config.api.CurrencyList + inputValue, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: this.context.token
                    },
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        const tempArray = [];
                        data.forEach((element) => {
                            const labels = element.Name + "(" + element.Code + ")"
                            tempArray.push({ label: `${labels}`, value: element.Code });
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        //console.log(error, "catch the hoop")
                    });
            });
        }
    }


    onSearchChangeCurrency = (selectedOption, name) => {
        if (selectedOption) {
            //console.log("-------------------", selectedOption.value, name)

            this.setState({ [name]: selectedOption.value, ishidden: false, Standing_Instruction_Currency_Codereset: false, selectedOption: this.selectedOption })
        }
        //console.log("..............", this.Country_Of_IssueCode)
    };
    // -------------------------------country--------------------------------

    // new logic for auto complete  country list
    fetchData = (inputValue, callback) => {
        if (!inputValue) {
            callback([]);
        } else {
            setTimeout(() => {
                fetch(config.api.CountryList + inputValue, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: this.context.token
                    },
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        const tempArray = [];
                        data.forEach((element) => {
                            const labels = element.Name + "(" + element.ShortCode + ")"
                            tempArray.push({ label: `${labels}`, value: element.ShortCode });
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        //console.log(error, "catch the hoop")
                    });
            });
        }
    }

    fetchData_t = (inputValue) => {

        setTimeout(() => {
            fetch(config.api.CountryList + inputValue, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: this.context.token
                },
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {

                    data.forEach((element) => {
                        const label = element.Name + " " + "(" + element.ShortCode + ")"
                        this.deafult_countrycode_list.push({ label: `${label}`, value: element.ShortCode });
                    });
                    //console.log("initial loaded..data country")
                    //console.log(this.deafult_countrycode_list)
                })
                .catch((error) => {
                    //console.log(error, "catch the hoop")
                });
        });
    }



    onSearchChange = (selectedOption, name) => {
        if (selectedOption) {
            //console.log("-------------------", selectedOption.value, name)
            this.setState({ [name]: selectedOption.value, Country_Of_IssueCodereset: false, ishidden: false, selectedOption: this.selectedOption })
        }
        //console.log("Country of IssueCode", this.Country_Of_IssueCode)
    };


    validate = () => {
        let AccountError = '';
        let Business_EntityError = '';
        let Corp_Action_TypeError = '';
        // let Country_Of_IssueCodeError = '';
        let SI_Action_TypeError = '';
        // let Standing_Instruction_Currency_CodeError = '';
        if (!this.state.Account) {
            AccountError = "Account can not be blank";
        }
        if (!this.state.Business_Entity) {
            Business_EntityError = "Business Entity can not be blank";
        }
        if (!this.state.Corp_Action_Type) {
            Corp_Action_TypeError = "corp Action Type can not be blank";
        }
        if (!this.state.SI_Action_Type) {
            SI_Action_TypeError = "Standing Instruction Action Type can not be blank";
        }
        if (AccountError || Business_EntityError || Corp_Action_TypeError || SI_Action_TypeError) {
            this.setState({ AccountError, Business_EntityError, Corp_Action_TypeError, SI_Action_TypeError })
            return false;
        }
        else {
            this.setState(
                (prevState) => ({
                    AccountError: '',
                    Business_EntityError: '',
                    Corp_Action_TypeError: '',
                    Country_Of_IssueCodeError: '',
                    SI_Action_TypeError: '',
                    Standing_Instruction_Currency_CodeError: '',
                }));
        }
        return true;
    }
    ChangeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
        //console.log(".....e.target",e.target.value,e.target.name,this.state)

        this.setState({
            ['SK']: "#Account$" + this.state.Account + "#BE#" + this.state.Business_Entity + "#CAT$" + this.state.Corp_Action_Type +
                "#COIC$" + this.state.Country_Of_IssueCode + "#SIAT$" + this.state.SI_Action_Type +
                "#SICC$" + this.state.Standing_Instruction_Currency_Code
        })
        //console.log("state..business",this.state.Business_Entity,this.state)
    }

    clearHandler = e => {
        e.preventDefault();
        this.state.selectedOption = null
        this.state.ishidden = true
        this.setState({
            Accountreset: true,
            Business_Entityreset: true,
            SI_Action_Typereset: true,
            Country_Of_IssueCodereset: true,
            Corp_Action_Typereset: true,
            Standing_Instruction_Currency_Codereset: true,
            Account: '',
            Business_Entity: '',
            Corp_Action_Type: '',
            Country_Of_IssueCode: '',
            SI_Action_Type: '',
            Standing_Instruction_Currency_Code: '',

        });
    }


    confirmation_msg = (msg, cls) => {

        confirmAlert({
            customUI: ({ onClose }) => {
                return (

                    <div className="react-confirm-alert-body-msg">
                        <div className={cls}><h6 >{msg}</h6></div>


                    </div>
                );
            }
        });
    };
    submitHandler(e) {
        const isvalid = this.validate();
        if (isvalid) {
            e.preventDefault();
            const registerData = JSON.stringify(this.state);
            //console.log(registerData)
            let alertmessage = '';
            this.setState({ alertmessage })
            const sk = "#Account#" + this.state.Account + '#BE#' + this.state.Business_Entity + "#CAT#" + this.state.Corp_Action_Type +
                "#COIC#" + this.state.Country_Of_IssueCode + "#SIAT#" + this.state.SI_Action_Type +
                "#SICC#" + this.state.Standing_Instruction_Currency_Code + '#'
            //console.log("SK", sk)
            axios({
                method: 'POST',
                url: config.api.SI,
                // 'https://uzro6za8jc.execute-api.us-east-1.amazonaws.com/new/proxy:SI?filters=',
                headers: {

                    Authorization: this.context.token
                },
                data: JSON.stringify({
                    Account: this.state.Account,
                    Business_Entity: this.state.Business_Entity,
                    Country_Of_IssueCode: this.state.Country_Of_IssueCode,
                    Corp_Action_Type: this.state.Corp_Action_Type,
                    SI_Action_Type: this.state.SI_Action_Type,
                    SK: sk,
                    "user_email": this.context.user_email,
                    Standing_Instruction_Currency_Code: this.state.Standing_Instruction_Currency_Code
                })
            })
                .then(response => {
                    //console.log(response)
                    let alertmessage = 'Record has beem successfully added.';
                    this.setState({ alertmessage })
                    this.setState({ error: false })
                    this.confirmation_msg(alertmessage, "isa_success")
                })
                .catch(error => {
                    //console.log("ERROR RESP", error.response.body)
                    if (error.response.status === 304) {
                        let alertmessage = 'Data already exists ';
                        this.setState({ alertmessage })
                        this.setState({ error: true })
                        this.confirmation_msg(alertmessage, "isa_warning")
                    }
                });
        }
        else {
            e.preventDefault();
            this.setState({ showForm: true });
        }
    }

    tohomepage = () => {
        //console.log('Redirect user to home --->');
        window.location.assign(config.azure.LOGIN_URL);
    };

    render() {
        if (this.context == undefined) {
            //console.log('Context is undefined --->');
            this.tohomepage();
            return false;
        }

        const {
            Account, Business_Entity, Corp_Action_Type, Country_Of_IssueCode
            , SI_Action_Type, Standing_Instruction_Currency_Code
        } = this.state
        return (
            <div className="container"  >
                <div role="tab" style={{ textAlign: 'center' }}>
                    <h1 className="panel-title " style={{ fontSize: 20 }}>
                        Create Standing Instructions
                  </h1>
                </div>

                <div style={{ marginLeft: 901, marginBottom: -5 }}> <Link style={{ alignItems: 'center', marginRight: 2 }} className="btn btn-primary" to="/standing_instructions">Back</Link>

                    <Button style={{ outline: "none" }} type='submit' className="btn btn-primary" onClick={this.clearHandler}>Clear</Button></div>

                <Form className="form-group" style={{ alignContent: "center", width: 600, padding: 30 }} onSubmit={this.submitHandler}>
                    {/* <div className={this.state.error ? 'error' : 'success'}>{this.state.ishidden?null:this.state.alertmessage}</div> */}
                    <div>
                        <RingLoader size={18} color="blue" loading={this.state.check} />
                    </div>
                    <FormGroup>
                        <Label>Account</Label>
                        <AsyncSelect
                            value={this.state.Accountreset ? null : this.state.selectedOption}
                            defaultOptions={true}
                            defaultOptions={this.deafult_account_list.sort((a, b) => (a.label > b.label ? 1 : -1))}
                            loadOptions={this.fetchDataPortfolio}
                            maxMenuHeight={150}
                            placeholder="Account"

                            name="Account"
                            onChange={(e) => {
                                this.onSearchChangePortfolio(e, "Account");
                            }}

                        />
                        <div style={{ color: "red", fontSize: 15 }}>{this.state.AccountError}</div>
                    </FormGroup>
                    <FormGroup>
                        <Label> Business Entity</Label>
                        <Input
                            type='select'
                            name='Business_Entity'
                            value={Business_Entity}
                            // disabled={this.state.disable === true ? true : false}
                            onChange={this.ChangeHandler} >
                            <option> </option>
                            <option>BUS01</option>
                            <option>BUS02</option>
                        </Input>
                        <div style={{ color: "red", fontSize: 15 }}>{this.state.Business_EntityError}</div>
                    </FormGroup>
                    <FormGroup>
                        <Label>Corp Action Type</Label>
                        <AsyncSelect
                            value={this.state.Corp_Action_Typereset ? null : this.state.selectedOption}
                            loadOptions={this.fetchDataPortCorp}
                            placeholder="Corpaction Type"
                            name="Corp_Action_Type"
                            maxMenuHeight={150}
                            onChange={(e) => {
                                this.onSearchChangeCorp(e, "Corp_Action_Type");
                            }}
                            defaultOptions={false}
                            defaultOptions={this.deafult_corp_list.sort((a, b) => (a.label > b.label ? 1 : -1))}
                        />

                        <div style={{ color: "red", fontSize: 15 }}>{this.state.Corp_Action_TypeError}</div>
                    </FormGroup>
                    <FormGroup>
                        <Label>Country of Issue Code</Label>
                        <AsyncSelect
                            value={this.state.Country_Of_IssueCodereset ? null : this.state.selectedOption}
                            loadOptions={this.fetchData}
                            placeholder="Country Of Issue Code"
                            name="Country_Of_IssueCode"
                            maxMenuHeight={150}
                            onChange={(e) => {
                                this.onSearchChange(e, "Country_Of_IssueCode");
                            }}
                            defaultOptions={false}
                            defaultOptions={this.deafult_countrycode_list.sort((a, b) => (a.label > b.label ? 1 : -1))}
                        />
                        <div style={{ color: "red", fontSize: 15 }}>{this.state.Country_Of_IssueCodeError}</div>
                    </FormGroup>

                    <FormGroup>
                        <Label>Standing Instruction Action Type</Label>
                        <AsyncSelect
                            value={this.state.SI_Action_Typereset ? null : this.state.selectedOption}
                            loadOptions={this.fetchDataSIAT}
                            placeholder="SI Action Type"
                            name="SI_Action_Type"
                            maxMenuHeight={150}
                            onChange={(e) => {
                                this.onSearchChangeSIAT(e, "SI_Action_Type");
                            }}
                            maxMenuHeight={170}
                            defaultOptions={true}
                            defaultOptions={this.deafult_SI_Action_Type_list.sort((a, b) => (a.label > b.label ? 1 : -1))}
                        />
                        <div style={{ color: "red", fontSize: 15 }}>{this.state.SI_Action_TypeError}</div>
                    </FormGroup>
                    <FormGroup>
                        <Label>Standing Instruction Currency Code</Label>
                        <AsyncSelect
                            value={this.state.Standing_Instruction_Currency_Codereset ? null : this.state.selectedOption}
                            loadOptions={this.fetchDataCurrency}
                            placeholder="Currency Code"
                            name="Standing_Instruction_Currency_Code"
                            maxMenuHeight={150}
                            onChange={(e) => {
                                this.onSearchChangeCurrency(e, "Standing_Instruction_Currency_Code");
                            }}
                            defaultOptions={false}
                            maxMenuHeight={170}
                            defaultOptions={this.deafult_currencycode_list.sort((a, b) => (a.label > b.label ? 1 : -1))}
                        />
                        <div style={{ color: "red", fontSize: 15 }}>{this.state.Standing_Instruction_Currency_CodeError}</div>
                    </FormGroup>

                    {/* <Link><Button style={{ outline: "none" }} type='submit' className="btn btn-primary" >Back</Button></Link> */}
                    <Button style={{ outline: "none" }} type='submit' className="btn btn-primary">Save</Button>
                </Form>
                {/* <div> <Link style={{alignItems:'center'}} to="/standing_instructions">Back</Link></div> */}
            </div>

        )
    }
}

export default StandinginstructionForm;
