import React from 'react';
import '../style.css';
import './Home.css';
import UserContext from '../UserContext';
import { Table, Container, UncontrolledCollapse, Card, CardBody } from 'reactstrap';
import 'react-dropdown/style.css';
import config from '../../auth_config';
// import { Link } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';

class HomePage extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);
		//console.log("Here is is, home page");
		this.state = {
			loading: false,
			data: [],
			startDate: new Date(),
			EndDate: new Date(),
			status_counts: [],
			ACK: '',
			Sent: '',
			NAK: '',
			No_Response: '',
			Recevied: ''
		};
		//console.log(this.state);
	}

	async componentDidMount() {

		this.loaddatastatuscount()
	}

	convertDate(date) {
		var yyyy = date.getFullYear().toString();
		var mm = (date.getMonth() + 1).toString();
		var dd = date.getDate().toString();

		var mmChars = mm.split('');
		var ddChars = dd.split('');

		return yyyy + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + (ddChars[1] ? dd : "0" + ddChars[0]);
	}

	async loaddatastatuscount(closeModal) {
		try {

			let params = {
				"startdate": this.convertDate(this.state.startDate),
				"enddate": this.convertDate(this.state.EndDate)
			};

			let query = Object.keys(params)
				.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
				.join('&');
			//console.log(params)
			// const url = config.api.ACKNAK + "?"+ query

			// console.log(config.api.ACKNAK + ':' + this.state.issort + ':' + this.state.limit +':'+ query)
			const api_response = await fetch(config.api.ACKNAK + ':' + 'ST_COUNT' + ':' + this.state.issort + '?' + query,
				// `https://fuw9emc6dj.execute-api.us-east-1.amazonaws.com/prod/proxy+:FM`,

				{
					method: "GET",
					headers: {
						Authorization: this.context.token,
					},
				}
			);
			const json_response = await api_response.json();
			console.table("json_response------------ --> ", json_response);
			//console.log('LastEvaluatedKey', json_response['LastEvaluatedKey'])
			// 'LastEvaluatedKey' in json_response ? this.setState({ loadmore: true }) : this.setState({ loadmore: false })
			const items = json_response['Items']
			this.setState({ status_counts: items })
			//console.log("Json dkhfkshdkItems---->>", items)

			this.setState({ ACK: this.state.status_counts[0]['ACK'] });
			this.setState({ Sent: this.state.status_counts[0]['Sent'] });
			this.setState({ NAK: this.state.status_counts[0]['NAK'] });
			this.setState({ No_Response: this.state.status_counts[0]['No_Response'] });
			this.setState({ Recevied: this.state.status_counts[0]['Recevied'] });

		} catch (errorresponse) {
			console.table("Exception : ", errorresponse);
			this.setState({ loading: false });
		}
	}

	tohomepage = () => {
		//console.log('Redirect user to home --->');
		window.location.assign(config.azure.LOGIN_URL);
	};

	page() {
		if (this.context == undefined) {
			return <div></div>;
		} else {
			return <div className="container panel-primary panel-group accordion-collapse">
				<div className="panel panel-primary">

					<div className="panel-heading" role="tab" id="EMC_2">
						<h4 className="panel-title">
							<a data-toggle="collapse" href="123" aria-expanded="true" aria-controls="1234">
								<p>ACK/NAK Overview</p>
							</a>
						</h4>
					</div>
					<div className="panel-collapse" role="tabpanel">


						<Table className="model-table-margin">
							<thead className="table table-striped table-condensed" id="invAllocTable">
								<tr>
									<th>Date</th>
									<th>{this.convertDate(this.state.startDate)}</th>
								</tr>
								<tr>
									<th>Outbound MT565s Sent:</th>
									<th>{this.state.Sent}</th>
								</tr>
								<tr>
									<th>ACK/NAK Responses Received:</th>
									<th>{this.state.Recevied}</th>
								</tr>
								<tr>
									<th>ACK</th>
									<th>{this.state.ACK}</th>
								</tr>
								<tr>
									<th>NAK</th>
									<th>{this.state.NAK}</th>
								</tr>
								<tr>
									<th>No Response</th>
									<th>{this.state.No_Response}</th>
								</tr>
							</thead>

						</Table>
					</div>
				</div>
				<div className="load-spinner">
						<a href={config.error_dashboard.error}  target ='_blank'>
						
						Error Overview
									</a>
				</div>

			</div>

		}
	}

	render() {


		return (

			this.page()


		)

	}
}



export default HomePage;
