const api_dynamo = 'https://8otyzm4m0m.execute-api.us-east-1.amazonaws.com/sbx/proxy+'
const api_esdl = 'https://e1t4t73fd9.execute-api.us-east-1.amazonaws.com/prod/'
const api_dynamo_dr = 'https://wkzhlrlozk.execute-api.us-west-2.amazonaws.com/sbx/proxy+'
const api_esdl_dr = 'https://naiqlpoplj.execute-api.us-west-2.amazonaws.com/prod/'
const local_api = {
    DataListFundManageAddPOST: 'http://127.0.0.1:8000/xsp/DataList_fund_manager/',
    DataListInvestMentStrategyPOST: 'http://127.0.0.1:8000/xsp/DataList_Investment_Strategy/',
    DataListStandingInstructionPOST: 'http://127.0.0.1:8000/xsp/DataList_Standing_instruction_type/',
    RecipentInvestmentAccountPOST: 'http://localhost:8000/xsp/Account_Inv_Strategy/',
    RecipentInvestmentPOST: 'http://localhost:8000/xsp/Inv_Strategy/',
    StandingInstructionPOST: 'http://localhost:8000/xsp/standing_instruction/',
}



const sbx_api = {
    SI: api_dynamo + ':SI',
    RISA: api_dynamo + ':RISA',
    RIS: api_dynamo + ':RIS',
    FM: api_dynamo + ':FM',
    IS: api_dynamo + ':IS',
    SIAT: api_dynamo + ':SIAT',
    audit: api_dynamo + ':audit',
    CountryList: api_esdl + 'countrylist?filters=',
    CurrencyList: api_esdl + 'currencylist?filters=',
    PortfoliosList: api_esdl + 'portfolios?filters=',
    CorpActiontype: api_esdl + 'corpactiontypelist?filters=',
}
const sbx_api_dr = {
    SI: api_dynamo_dr + ':SI',
    RISA: api_dynamo_dr + ':RISA',
    RIS: api_dynamo_dr + ':RIS',
    FM: api_dynamo_dr + ':FM',
    IS: api_dynamo_dr + ':IS',
    SIAT: api_dynamo_dr + ':SIAT',
    audit: api_dynamo_dr + ':audit',
    CountryList: api_esdl_dr + 'countrylist?filters=',
    CurrencyList: api_esdl_dr + 'currencylist?filters=',
    PortfoliosList: api_esdl_dr + 'portfolios?filters=',
    CorpActiontype: api_esdl_dr + 'corpactiontypelist?filters=',
}

const apis = sbx_api;
// const apis =
//   process.env.REACT_APP_STAGE === 'sbx'
//     ? sbx_api
//     : process.env.REACT_APP_STAGE === 'sbx_dr'
//     sbx_api_dr;

export default apis