import React, {Component} from 'react';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

class RedirectCheck extends Component {
    constructor(props){
        super(props);
    }


    render() {
        function getQueryParams(){
            let url = window.location.href;
            let search_params = url.split('#')[1]
            console.log("Type of :::: ", typeof(search_params));
            if(search_params != undefined){
                let p = new URLSearchParams(search_params);
                return p.get('error_description');
 
            }else {
                return <Redirect exact path="/" to="/home" />;
            }
        }

        return(
            
            <div className="container" style={{color:'red'}}>{getQueryParams()}</div>
        );
    }
}
export default RedirectCheck;