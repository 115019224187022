import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from "./components/App/App.js";
import * as serviceWorker from './serviceWorker';
import { usePromiseTracker } from "react-promise-tracker";
import { BeatLoader } from 'react-spinners';
import config from './auth_config.js';
import Amplify from 'aws-amplify';

// const LoadingIndicator = props => {
//   const { promiseInProgress } = usePromiseTracker();
//    return (
//   promiseInProgress && 
//   <div className="load-spinner"><BeatLoader size={25} color='blue' loading/></div>
//   );  
// }

console.log('process.env.REACT_APP_STAGE :::', process.env.REACT_APP_STAGE);
// console.log(config);
serviceWorker.register(config);

Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		userPoolWebClientId: config.cognito.USER_POOL_WEB_CLIENT_ID,
		authenticationFlowType: 'USER_PASSWORD_AUTH',
	},
	oauth: {
		domain: config.cognito.USER_POOL_DOMAIN_NAME,
		scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
		redirectSignIn: config.api.REDIRECT_SIGN_IN,
		redirectSignOut: config.api.REDIRECT_SIGN_OUT,
		responseType: 'token',
	},
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
