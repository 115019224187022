import React, { Component } from 'react';
import ConnectToCognito from "../ConnectToCognito/connectToCognito";
import Amplify, { Auth, Hub } from 'aws-amplify';
import { UserProvider } from '../UserContext';
import LandingPage from '../LandingPage/LandingPage';


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authState: '',
            user: null,
            customState: null,
            token: '',
            user_name: '',
            user_email: '',
            user_grp: '',
        };
    }
    componentDidMount() {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            //    console.log('Event ' + event);
            switch (event) {
                case 'signIn':
                    this.setState({ user: data });
                    Auth.currentAuthenticatedUser()

                        .then((user) => {
                            //console.log(user);
                            for (const group of user.signInUserSession.idToken.payload["cognito:groups"]) {
                                if (group === "XSP_DASHBOARD_UPDATE_GROUP") {
                                    this.state.user_grp = "XSP_DASHBOARD_UPDATE_GROUP"
                                }
                                else if (group === "XSP_DASHBOARD_VIEW_GROUP") {
                                    // this.state.user_grp = "XSP_DASHBOARD_UPDATE_GROUP"
                                    this.state.user_grp = "XSP_DASHBOARD_VIEW_GROUP"
                                }

                            }
                            this.setState({
                                authState: 'signedIn',
                                token: user.signInUserSession.idToken.jwtToken,
                                user_name: user.attributes.name,
                                user_email: user.attributes.email,
                            });
                            console.log("USER GROUP :::::::", this.state.user_grp)
                        })
                        .catch((e) => {
                            //console.log(e);
                            this.setState({ authState: 'unAuthorized' });
                        });
                    break;
                case 'signOut':
                    this.setState({ authState: 'signOut' });
                    break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                case 'customState_failure':
                    this.setState({ authState: 'unAuthorized' })
                    break;
                default:
                    this.setState({ authState: null });
                //console.log('Default...');
            }
        })
    }

    render() {
        //console.log("IN render state object  : " + this.state);
        //console.log("value of process.env.REACT_APP_STAGE npm start :::> ", process.env.REACT_APP_STAGE);
        var render_page = <ConnectToCognito />
        if (this.state.authState == 'signedIn' && this.state.user_grp == "XSP_DASHBOARD_VIEW_GROUP") {
            render_page = <UserProvider value={this.state}>
                <LandingPage />
            </UserProvider>
        } else if (this.state.authState == 'signedIn' && this.state.user_grp == "XSP_DASHBOARD_UPDATE_GROUP") {
            render_page = <UserProvider value={this.state}>
                <LandingPage />
            </UserProvider>
        } else {
            render_page = <ConnectToCognito />
        }
        return (
            <div>
                {render_page}
            </div>
        )
    }

}

export default Login;