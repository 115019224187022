import React from 'react';
import { Link } from 'react-router-dom';
import UserContext from '../UserContext';
import config from '../../config';

class Menubar extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);
		this.state = {
			count: 0,
		};
	}
	// async getData(){
	// 	console.log("this.getData ->");
	// 	const pending_review_url = config.api.INVOKE_ROTATION_URL + '?identifier=pending_review_data_count';
    //     var api_response = await fetch(pending_review_url, {
	// 		method: 'GET',
	// 		headers: {
	// 			Authorization: 'Bearer ' + this.context.token
	// 		}
	// 	});
	// 	const json_response = await api_response.json();
	// 	console.log('json_response --> ', json_response);

	// 	this.setState({count : json_response.Count});
	// 	console.log("this state data =>", this.state.count);
	// 	this.intervalID = setTimeout(this.getData.bind(this), 60000);
	// }

	// componentDidMount() {
	// 	this.getData();
    // }
	
	componentWillUnmount() {
		clearInterval(this.intervalID);
	}

	render() {
		return (
			<div>
				<ul className="nav navbar-nav">
					<li className="dropdown">						
						<Link to="/home"><b>Home</b></Link>
					</li>
					<li className="dropdown">
						<a href="#" className="dropdown-toggle" data-toggle="dropdown" >
							Tables <i className="fa fa-angle-down" ></i>
						</a>
						<ul className="dropdown-menu" role="menu">
							<li>
								<Link to="/recipent_investment_streategy">Recipient Investment Strategy</Link>
								{/* <Link to="/">Pending Review Data</Link> */}
							</li>
							<li>
                				<Link to="/RISA">Recipient Investment Strategy Account</Link>
              				</li>
							<li>
                				<Link to="/standing_instructions">Standing Instructions</Link>
              				</li>
						</ul>
					</li>
					<li className="dropdown">
						
							 <Link to="/acknakresponse"><b>Ack/Nak</b></Link>
						
					</li>
					<li className="dropdown">
						<a href="#" className="dropdown-toggle" data-toggle="dropdown">
							Data Lists <i className="fa fa-angle-down"></i>
						</a>
						<ul className="dropdown-menu" role="menu">
							<li>
								<Link to="/data_list_investment_strategy">Investment Strategy</Link>
							</li>
							<li>
								<Link to="/datalist_fundmanager">Fund Manager</Link>
							</li>
							<li>
								<Link to="/datalist_StandingInstructionType">Standing Instruction Action Type</Link>
							</li>
						</ul>
					</li>
					<li className="dropdown">						
						<Link to="/audit_log"><b>Audit Log</b></Link>
					</li>
				</ul>
			</div>
		);
	}
}

export default Menubar;
