import React, { Component } from "react";
import "./App.css";
import { UserProvider } from "../UserContext";
import Login from "../Login/login"
import HomePage from '../Home/Home';


const signUpConfig = {
  defaultCountryCode: "91",
};

class App extends Component {


  render() {

    return (
      <div>
        <UserProvider>
          <Login />
        </UserProvider>
      </div>
    );
  }
}

export default App;
