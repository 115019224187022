import React, { useState } from "react";
import { Table, Container, Button, FormGroup, Input, Form, Label } from "reactstrap";
import "react-dropdown/style.css";
import { BeatLoader, MoonLoader, CircleLoader, ClockLoader } from "react-spinners";
import { Link } from "react-router-dom";
import apis from '../../../../apis'
import UserContext from '../../../UserContext'
import config from '../../../../auth_config'
import '../../error.css';
import "./acknakDetails.css";
import ScrollToTop from 'react-scroll-up';
import "react-data-export";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { confirmAlert } from 'react-confirm-alert';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import "jspdf-autotable";

class Acknak extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      //   token: null,
      dataresponse: [],
      responsedata: {},
      isAddModel: false,
      errorresponse: null,
      loading: false,
      statusValue: { 0: "UPDATEMODEL" },
      selectedIndex: 0,
      editing: false,
      parms1: '',
      parms2: '',
      moreData: true,
      isActive: true,
      moreData: true,
      loadmore: true,
      limit: 25,
      F_limit: 25,
      isFilter: false,
      issort: true,
      filtersort: true,
      sortIcon: '▲',
      exportData: [],
      export_flag: true,
      search_msg: "",
      no_record_msg: false,
      search_loader: false,
      butt_disable: false,
      startDate: new Date(),
      EndDate: new Date(),
      status_counts: [],
      ACK: '',
      Sent: '',
      NAK: '',
      No_Response: '',
      Recevied: ''

    };

    // this.onEditFormSubmit = this.onEditFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.ChangeHandler = this.ChangeHandler.bind(this);
    this.ChangeHandlerform = this.ChangeHandlerform.bind(this);
  }

  ChangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
    this.parms1 = e.target.value
    this.setState({ F_limit: 25 })
  }

  ChangeHandlerform = e => {
    this.setState({ [e.target.name]: e.target.value })
    this.parms2 = e.target.value
    this.setState({ F_limit: 25 })
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  }

  onCreate() {
    document.getElementById("duplicateRecord").innerHTML = "";
  }

  handlerForSubmit = (sk) => {
    this.onEditFormSubmit(this.state, sk);
    
  };



  confirmation_msg = (msg, cls) => {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (

          <div className="react-confirm-alert-body-msg">
            <div className={cls}><h6 >{msg}</h6></div>


          </div>
        );
      }
    });
  };



  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
  }



  convertDate(date) {
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth() + 1).toString();
    var dd = date.getDate().toString();

    var mmChars = mm.split('');
    var ddChars = dd.split('');

    return yyyy + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + (ddChars[1] ? dd : "0" + ddChars[0]);
  }



  async filteredloadModelData(closeModal) {
    this.setState({ search_loader: true })
    try {
      let params = {
        "startdate": this.convertDate(this.state.startDate),
        "enddate": this.convertDate(this.state.EndDate)
      };

      let query = Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
     
      this.loaddatastatuscount()
      const url = config.api.ACKNAK + ':' + this.state.issort + "?" + query
      console.log("Flitered url...",url)
      const api_response = await fetch(url,
       
        {
          method: "GET",
          headers: {
            Authorization: this.context.token,
          },
        }
      );
      const json_response = await api_response.json();


      console.table("json_response --> ", json_response);
      json_response['Count'] === 0 ? this.setState({ moreData: false }) : this.setState({ moreData: true })
      
      const items = json_response['Items']
      if (items.length == 0) {
        this.setState({ no_record_msg: true })
      } else if (items.length > 0) {
        this.setState({ no_record_msg: false })

      }

      
      if (items.length > 25 || 'LastEvaluatedKey' in json_response == false) {

        this.setState({ search_loader: false })

      }
      if (items.length < 25 && this.state.loadmore) {
        this.handlefilter()
      }
      if (closeModal) {
        this.setState({ dataresponse: items, isAddModel: false }, () => {
          window.$('#add-modal').modal('hide')
          
        });
      } else {
        this.setState({ dataresponse: items });
       
      }
      this.setState({ loading: false });
      this.setState({ search_loader: false });
      

    } catch (errorresponse) {
      console.table("Exception : ", errorresponse);
      this.setState({ loading: false });
      this.setState({ search_loader: false });
    }
  }


  async loaddatastatuscount() {
    try {
      
      let params = {
        "startdate": this.convertDate(this.state.startDate),
        "enddate": this.convertDate(this.state.EndDate)
      };

      
      let query = Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
      
      const url = config.api.ACKNAK + "?" + query
      console.log("url...",url)

      
      const api_response = await fetch(config.api.ACKNAK + ':' + 'ST_COUNT' + ':' + this.state.issort + '?' + query,
        

        {
          method: "GET",
          headers: {
            Authorization: this.context.token,
          },
        }
      );
      const json_response = await api_response.json();
      const items = json_response['Items']

      console.log("Json dkhfkshdkItems---->>", items)
      this.setState({ status_counts: items });
      console.log(this.state.status_counts)
      this.setState({ ACK: this.state.status_counts[0]['ACK'] });
      this.setState({ Sent: this.state.status_counts[0]['Sent'] });
      this.setState({ NAK: this.state.status_counts[0]['NAK'] });
      this.setState({ No_Response: this.state.status_counts[0]['No_Response'] });
      this.setState({ Recevied: this.state.status_counts[0]['Recevied'] });
      
    } catch (errorresponse) {
      console.table("Exception : ", errorresponse);
      this.setState({ loading: false });
    }
  }


  async loadModelData(closeModal) {
    try {
      this.setState({ loading: true });
      let params = {
        "startdate": this.convertDate(this.state.startDate),
        "enddate": this.convertDate(this.state.EndDate)
      };

      let query = Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
      
      const api_response = await fetch(config.api.ACKNAK + ':' + this.state.issort + '?' + query,
        
        {
          method: "GET",
          headers: {
            Authorization: this.context.token,
          },
        }
      );
      const json_response = await api_response.json();
      console.table("json_response --> ", json_response);
      const items = json_response['Items']

      
      this.setState({ exportData: items });

      if (closeModal) {
        this.setState({ dataresponse: items, isAddModel: false }, () => {
          window.$('#add-modal').modal('hide')
        });

      } else {
        this.setState({ dataresponse: items, exportData: items });

      }
      this.setState({ loading: false });
    } catch (errorresponse) {
      console.table("Exception : ", errorresponse);
      this.setState({ loading: false });
    }
  }
  exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "ACK NAK REPORT";
    const headers = [["SenderReference","BBHSearchKey","SentTimeStamp","SentMT565Message","LastUpdateTimestamp","ACKNAKStatus"]];
    
    const data = this.state.dataresponse.map(elt=> [ elt.Sender_Reference,elt.BBH_Search_Key
    ,elt.Sent_TimeStamp,elt.Sent_MT565_Message,elt.Last_Update_Timestamp,elt.ACKNAK_Status]);
    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    const col=["START DATE","END DATE","SENT","RECEVIED","ACK","NAK","NO RESPONSE"]
    const row=[[this.convertDate(this.state.startDate),this.convertDate(this.state.EndDate),this.state.Sent,this.state.Recevied ,this.state.ACK,this.state.NAK,this.state.No_Response]]

    const title1 = "ACK NAK Summary";    
    doc.text(title1, marginLeft, 35);
    doc.autoTable(col,row,{ startY: 60 });
    doc.addPage();
    doc.text(title, marginLeft, 35);
    doc.autoTable(content);
    doc.save("ACKNAK_Report.pdf")
  }


  async componentDidMount() {
    this.loaddatastatuscount()
    this.loadModelData()
    
  }



  handleScroll = () => {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight & this.state.loadmore & this.state.moreData) {
      if (this.state.isFilter) {
        this.setState({ F_limit: this.state.F_limit + 5 })
        }
      else {
        this.setState({ limit: this.state.limit + 5 })
        
      }


    }
  };

  handlefilter = () => {
    this.setState({ F_limit: this.state.F_limit + 5 })
    
  };

  ascendingsort = () => {
    if (this.state.isFilter) {
      this.state.issort = true
      this.filteredloadModelData()
    }
    else {
      this.state.issort = true
      this.loadModelData()
    }
  }

  descendingsort = () => {
    if (this.state.isFilter) {
      this.state.issort = false
      this.filteredloadModelData()
    }
    else {
      this.state.issort = false
      this.loadModelData()
    }
  }

  onsort = () => {
    if (this.state.filtersort) {
      this.state.filtersort = false
      this.state.sortIcon = "▼"
      this.descendingsort()
    }
    else {
      this.state.filtersort = true
      this.state.sortIcon = "▲"
      this.ascendingsort()
    }
  }



  renderTableData() {
    return this.state.dataresponse.map((model_data, index) => {
      const {

        ACKNAK_Status,
        BBH_Search_Key,
        Last_Update_Timestamp,
        Sender_Reference,
        Sent_MT565_Message,
        Sent_TimeStamp,
        sk

      } = model_data;

      return (
        <tr>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <Input style={{ height: 38 }}
                type="text"
                name="SEME"
                id="SEME"
                defaultValue={Sender_Reference || ''}
                onChange={this.handleChange}
                required></Input>

            ) : (
                <div>{Sender_Reference}</div>
              )}
          </td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <Input style={{ height: 38 }}
                type="text"
                name="bbh_key"
                id="bbh_key"
                defaultValue={BBH_Search_Key || ''}
                onChange={this.handleChange}
                required></Input>

            ) : (
                <div>{BBH_Search_Key}</div>
              )}
          </td>

          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <Input style={{ height: 38 }}
                type="text"
                name="lastUpdateTimestamp"
                id="lastUpdateTimestamp"
                defaultValue={Sent_TimeStamp || ''}
                onChange={this.handleChange}
                required></Input>

            ) : (
                <div>{Sent_TimeStamp}</div>
              )}
          </td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <Input style={{ height: 38, whiteSpace: 'pre-wrap', width: 200 }}
                type="text"
                name="Sent_MT565_Message"
                id="Sent_MT565_Message"
                defaultValue={Sent_MT565_Message || ''}
                onChange={this.handleChange}
                required></Input>

            ) : (
                <div>{Sent_MT565_Message}</div>
              )}
          </td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <Input style={{ height: 38 }}
                type="text"

                name="Last_Update_Timestamp"
                id="Last_Update_Timestamp"
                defaultValue={Last_Update_Timestamp || ''}
                onChange={this.handleChange}
                required></Input>

            ) : (
                <div>{Last_Update_Timestamp}</div>
              )}
          </td>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <Input style={{ height: 38 }}
                type="text"
                name="ACK_NAK_Status"
                id="ACK_NAK_Status"
                defaultValue={ACKNAK_Status || ''}
                onChange={this.handleChange}
                required></Input>

            ) : (
                <div>{ACKNAK_Status}</div>
              )}
          </td>
          
      </tr>
      );
    });
  }

  tohomepage = () => {
    window.location.assign(config.azure.LOGIN_URL);
  };

  handlechangedate(date) {
    this.setState({
      startDate: date
    })
  }
  render() {
    if (this.context == undefined) {
      this.tohomepage();
      return false;
    }
    
    const datehandleChange = date => this.setState({ startDate: date });
    const EnddatehandleChange = date => this.setState({ EndDate: date });
    return (
      <Container>
        <div role="tab" style={{ textAlign: 'center' }}>
          <h1 className="panel-title " style={{ fontSize: 20 }}>
            XSP ACK NAK Report
            </h1>

        </div>
        <br></br>
        <div>

        </div>
        <Table style={{ alignContent: "center",width:800, marginLeft: -100 }} >
          <thead>

            <tr>

              <td><Label>Start Date</Label></td>

              <td>
                <DatePicker
                  style={{ height: '100%' }}
                  selected={this.state.startDate}
                  onChange={datehandleChange}
                  isClearable
                  placeholderText="Start date"
                />

              </td>
              <td><Label>End Date</Label></td>
              <td>
                <DatePicker
                  selected={this.state.EndDate}
                  onChange={EnddatehandleChange}
                  isClearable
                  placeholderText="End date"

                />
              </td>
              <td>
                <button style={{
                  'outline': 'none',
                  'height': '29px',
                  'padding-top': 0,
                  'padding-block-end': 'initial'
                }} onClick={() => { this.filteredloadModelData(); }} type="button" className="btn btn-primary" >Refresh</button>
              </td>
              <td>
                <button style={{
                  'outline': 'none',
                  'height': '29px',
                  'padding-top': 0,
                  'padding-block-end': 'initial'
                }} onClick={() => { this.exportPDF(); }} type="button" className="btn btn-primary" >Print</button>
              </td>
              <td >
                <ClockLoader size={25} color='blue' loading={this.state.search_loader} />
              </td>
            </tr>
            <tr>
              <td><Label>Sent</Label></td>
              <td><Label>{this.state.Sent}</Label></td>
              <td><Label>ACK</Label></td>
              <td><Label>{this.state.ACK}</Label></td>
              <td><Label>No Response</Label></td>
              <td><Label>{this.state.No_Response}</Label></td>
              </tr>
            <tr>
              <td><Label>Received</Label></td>
              <td><Label>{this.state.Recevied}</Label></td>
              <td><Label>NAK</Label></td>
              <td><Label>{this.state.NAK}</Label></td>


            </tr>

          </thead>
        </Table>
        <ScrollToTop smooth showUnder={5} duration={1000}  color="#6f00ff" >
              <button
                style={{
                  display: "inline-block",
                  marginLeft: "15px"
                }} >SCROLL UP
          </button>
        </ScrollToTop>

        <div style={{ textAlign: 'right', color: 'green' }}>
          {this.state.del_msg}
        </div>

        <div style={{ fontSize: 15 }}>
          <Table className="model-table-margin table table-bordered table-hover tabScrl" style={{ alignContent: "center", width: 1360, height: 5, marginLeft: -100, "tableLayout": "auto", "overflow": "hidden", "overflow-y": "scroll" }} >
              
            <thead className="tableFixHead">
              <tr>
              <th style={{ width: 190 ,textAlign: 'center' ,whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>Sender's Reference
                </th>
                <th style={{ width: 190 ,textAlign: 'center' ,whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>BBH-Search-Key</th>
                <th onClick={this.onsort}  style={{ width: 160 ,textAlign: 'center' ,whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>SentTimeStamp{this.state.sortIcon}</th>
                <th style={{ width:300 ,textAlign: 'center' ,whiteSpace: 'unset', overflow: 'visible', wordBreak: 'break-all'}}>Sent_MT565_Message</th>
                <th style={{ width: 180 ,textAlign: 'center' ,whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>Acknowledged TimeStamp</th>
                <th style={{ width: 180 ,textAlign: 'center' ,whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>Status</th>
              
              </tr>
              
            </thead>
            <tbody>{this.renderTableData()}</tbody>
          </Table>
          
          <div className={this.state.no_record_msg ? '' : 'hidden'}>
            <p><strong>No Records Found.</strong></p>
          </div>
        </div>
      </Container>
    );

  }
}

export default Acknak;
