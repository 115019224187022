import React from "react";
import { Table, Container, Button, FormGroup, Input, Form, Label } from "reactstrap";
import "react-dropdown/style.css";
import "./standinginstructiontype.css";
import { BeatLoader, ClockLoader } from "react-spinners";
import { Link } from "react-router-dom";
import apis from "../../../../../apis";
import UserContext from "../../../../UserContext";
import config from "../../../../../auth_config";
import ScrollToTop from 'react-scroll-up';
import "react-data-export";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { confirmAlert } from 'react-confirm-alert';

class StandingInstructionType extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      //   token: null,
      dataresponse: [],
      responsedata: {},
      isAddModel: false,
      errorresponse: null,
      loading: false,
      statusValue: { 0: "UPDATEMODEL" },
      selectedIndex: 0,
      editing: false,
      parms1: '',
      parms2: '',
      isActive: true,
      moreData: true,
      loadmore: true,
      limit: 25,
      F_limit: 25,
      isFilter: false,
      issort: true,
      filtersort: true,
      sortIcon: '▲',
      exportData: [],
      del_msg: "",
      search_loader: false,
      butt_disable: false
    };

    this.onEditFormSubmit = this.onEditFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.ChangeHandler = this.ChangeHandler.bind(this);
    this.ChangeHandlerform = this.ChangeHandlerform.bind(this);
    // this.onSort = this.onSort.bind(this);
  }


  ChangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
    this.parms1 = e.target.value
    this.setState({ F_limit: 25 })
  }

  ChangeHandlerform = e => {
    this.setState({ [e.target.name]: e.target.value })
    this.parms2 = e.target.value
    this.setState({ F_limit: 25 })
  }
  componentWillUnmount() {
    // remove event listener
    window.removeEventListener('scroll', this.handleScroll, false);
  }



  handleChange(event) {
    //console.log('event.target::', event.target);
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  }

  onCreate() {
    document.getElementById("duplicateRecord").innerHTML = "";
  }

  handlerForSubmit = (sk) => {

    this.onEditFormSubmit(this.state, sk);
    //console.log("test", sk)

  };

  handlerForDelete = (sk) => {
    this.submitDelete(sk)
  };

  confirmation_msg = (msg, cls) => {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (

          <div className="react-confirm-alert-body-msg">
            <div className={cls}><h6 >{msg}</h6></div>


          </div>
        );
      }
    });
  };

  submitDelete = (sk) => {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (

          <div className="react-confirm-alert-body">
            <h3>Are you Sure?</h3>
            <h6>You want to delete this record?</h6>
            <Button style={{ marginRight: 5 }} className="btn btn-primary" onClick={onClose}>No</Button>
            <Button
              className="btn btn-primary"
              onClick={() => {
                this.onDeleteSubmit(sk);
                onClose();
              }}
            >
              Yes, Delete it!
                </Button>
          </div>

        );
      }
    });
  };


  onDeleteSubmit(sk) {


    const apiUrl = config.api.SIAT + ':$' + 'DEL';
    //console.log(sk)
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.context.token
      },
      body: JSON.stringify({ sk })

    })
      .then(response => {
        if (response.status === 200) {
          // this.state.del_msg="Record has been sucessfully deleted"
          this.loadModelData()
          const msg = "Record has been successfully deleted."
          this.confirmation_msg(msg, "isa_success")
        }

      })

  }


  validate = () => {
    let StandinginstructiontypeError = '';
    if (!this.state.SI_Action_Type) {
      StandinginstructiontypeError = "Standing instruction type field can not be blank";
    }
    if (StandinginstructiontypeError) {
      this.setState({ StandinginstructiontypeError })
      this.state.StandinginstructiontypeError = StandinginstructiontypeError
      return false;
    }
    return true;
  }

  onEditFormSubmit(value, sk) {

    const isvalid = this.validate();
    if (isvalid) {
      //console.log(sk)
      this.setState({ loading: true });
      const apiUrl = config.api.SIAT + ":$" + sk
      // `https://fuw9emc6dj.execute-api.us-east-1.amazonaws.com/prod/proxy+:SIAT`;
      //console.log("value", value);
      const updatedvalues = {
        "sk": value["selectedIndex"], "SI_Action_Type": value["SI_Action_Type"], "user_email": this.context.user_email
      }

      fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          Authorization: this.context.token
        },
        body: JSON.stringify(updatedvalues)

      })
        .then(response => {
          //console.log("RESP", response)
          if (response.status === 304) {
            let alertmessage = 'Data already exists ';
            this.setState({ alertmessage })
            //console.log('Data already exists ');
            // alert("Data already exists ");
            this.confirmation_msg(alertmessage, "isa_warning")
          }
          else {
            this.setState({ error: false })
            this.setState({ responsedata: response })
            const msg = "Record has been successfully edited."
            this.confirmation_msg(msg, "isa_success")
          }
          this.loadModelData()
        })
      this.setState((prevState) => ({
        editing: !prevState.editing,
      }));
      this.setState({ loading: false });
    }
    else {
      this.setState({ showForm: true });
      alert(this.state.StandinginstructiontypeError)
      //console.log("Invalid details")
    }
  }

  async filteredloadModelData(closeModal) {
    try {
      let url = config.api.SIAT + ':' + this.state.issort + ':' + this.state.F_limit
      this.setState({ loading: true });
      this.setState({ search_loader: true })

      //console.log(this.parms1, this.parms2, this.parms1 !== 'Select', this.parms1 !== undefined & this.parms2 !== undefined)
      if (this.parms1 !== undefined & this.parms2 !== undefined & this.parms1 !== 'Select' & this.parms1 !== '' & this.parms2 !== '') {
        this.parms1 = "SI_Action_Type"
        const key = (this.parms1).replace(/ /g, "_");
        this.setState({ butt_disable: true })
        let params = {
          "filters": key,
          "param2": this.parms2
        };
        //console.log(this.param1, this.parms2)
        let query = Object.keys(params)
          .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
          .join('&');
        //console.log(params)
        url = config.api.SIAT + ':' + this.state.issort + ':' + this.state.F_limit + "?" + query
      }
      else {
        const msg = "Please enter Filter criteria."
        this.confirmation_msg(msg, "isa_error")
        this.setState({ isFilter: false })
        // this.setState({ search_msg: "" })
      }
      const api_response = await fetch(url,
        // `https://uzro6za8jc.execute-api.us-east-1.amazonaws.com/new/proxy:FM?`+query,
        {
          method: "GET",
          headers: {
            Authorization: this.context.token,
          },
        }
      );
      const json_response = await api_response.json();
      console.table("json_response --> ", json_response);
      json_response['Count'] === 0 ? this.setState({ moreData: false }) : this.setState({ moreData: true })
      //console.log('LastEvaluatedKey', json_response['LastEvaluatedKey'], json_response['Count'])
      'LastEvaluatedKey' in json_response ? this.setState({ loadmore: true }) : this.setState({ loadmore: false })
      'LastEvaluatedKey' in json_response ? this.setState({ loadmore: true }) : this.setState({ loadmore: false })

      const items = json_response['Items']
      //console.log("Json Items---->>", items)
      //console.log(items.length)
      if (items.length > 25 || 'LastEvaluatedKey' in json_response == false) {

        this.setState({ search_loader: false })
      }
      if (items.length < 25 && this.state.loadmore) {
        this.handlefilter()
      }
      // (json_response || []).length === 0 ? this.setState({ moreData: false }) : this.setState({ moreData: true })
      if (closeModal) {
        this.setState({ dataresponse: items, isAddModel: false }, () => {
          window.$('#add-modal').modal('hide')
        });
      } else {
        this.setState({ dataresponse: items });
      }
      this.setState({ loading: false });
      this.setState({ search_loader: false });
    } catch (errorresponse) {
      console.table("Exception : ", errorresponse);
      this.setState({ loading: false });
      this.setState({ search_loader: false });
    }
  }

  async loadModelData(closeModal) {
    try {
      this.setState({ loading: true });

      const api_response = await fetch(config.api.SIAT + ':' + this.state.issort + ':' + this.state.limit,
        {
          method: "GET",
          headers: {
            Authorization: this.context.token,
          },
        }
      );
      const json_response = await api_response.json();
      console.table("json_response --> ", json_response);
      //console.log('LastEvaluatedKey', json_response['LastEvaluatedKey'])
      'LastEvaluatedKey' in json_response ? this.setState({ loadmore: true }) : this.setState({ loadmore: false })
      const items = json_response['Items']
      //console.log("Json Items---->>", items)
      this.setState({ exportData: items });
      if (closeModal) {
        this.setState({ dataresponse: items, isAddModel: false }, () => {
          window.$('#add-modal').modal('hide')
        });
      } else {
        this.setState({ dataresponse: items });
      }
      this.setState({ loading: false });
    } catch (errorresponse) {
      console.table("Exception : ", errorresponse);
      this.setState({ loading: false });
    }
  }

  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, false);
    //console.log("context from landing page", this.context);
    // //console.log("token from landingpage  : ", this.context.token);
    this.loadModelData()
  }
  edit = (e, sk, SI_Action_Type) => {
    if (e.target.textContent == "Edit") {
      this.setState(
        (prevState) => ({
          editing: !prevState.editing,
          selectedIndex: sk,
          SI_Action_Type: SI_Action_Type
        }));
    }
    else {
      this.setState(
        (prevState) => ({
          editing: !prevState.editing,
          selectedIndex: sk,
          SI_Action_Type: ''
        }));
    }
  };
  ondropdownchange = (event, key) => {
    //console.log("event : ", event);
    //console.log("key : ", key);
    this.state.statusValue[parseInt(key)] = event.label;
    //console.log("this.statusValue", this.statusValue);
  };

  handleScroll = () => {
    //console.log(this.isFilter, this.state.isFilter, this.state.F_limit, this.state.limit, this.state.loadmore, this.state.moreData)
    // //console.log(window.innerHeight, window.scrollY, document.body.offsetHeight, this.state.loadmore)
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight & this.state.loadmore & this.state.moreData) {
      //console.log("you're at the bottom of the page");
      //console.log(window.innerHeight, window.scrollY, document.body.offsetHeight, this.state.loadmore)
      if (this.state.isFilter) {
        this.setState({ F_limit: this.state.F_limit + 5 })
        //console.log("RECORDS LOADED----->>>", this.state.F_limit)
        this.filteredloadModelData()
        //console.log("this.state.isFilter", this.state.isFilter)
      }
      else {
        this.setState({ limit: this.state.limit + 5 })
        //console.log("RECORDS LOADED----->>>", this.state.limit)
        this.loadModelData()
      }


    }
  };

  ascendingsort = () => {
    if (this.state.isFilter) {
      this.state.issort = true
      this.filteredloadModelData()
    }
    else {
      this.state.issort = true
      this.loadModelData()
    }
  }

  descendingsort = () => {
    if (this.state.isFilter) {
      this.state.issort = false
      this.filteredloadModelData()
    }
    else {
      this.state.issort = false
      this.loadModelData()
    }
  }

  onsort = () => {
    if (this.state.filtersort) {
      this.state.filtersort = false
      this.state.sortIcon = "▼"
      this.descendingsort()
    }
    else {
      this.state.filtersort = true
      this.state.sortIcon = "▲"
      this.ascendingsort()
    }
  }

  handlefilter = () => {
    this.setState({ F_limit: this.state.F_limit + 5 })
    //console.log("RECORDS LOADED----->>>", this.state.F_limit)
    this.filteredloadModelData()
    //console.log("this.state.isFilter", this.state.isFilter)
  };

  async exportingData(closeModal) {
    try {
      this.setState({ loading: true });
      const api_response = await fetch(config.api.SIAT + ':' + this.state.issort + ':' + this.state.limit + ':' + this.state.export_flag,
        // `https://fuw9emc6dj.execute-api.us-east-1.amazonaws.com/prod/proxy+:FM`,
        {
          method: "GET",
          headers: {
            Authorization: this.context.token,
          },
        }
      );
      const json_response = await api_response.json();
      const items = json_response['Items']
      const csvData = items
      const fileName = 'SIAT'
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      if (closeModal) {
        this.setState({ dataresponse: items, isAddModel: false }, () => {
          window.$('#add-modal').modal('hide')
        });

      } else {
        this.setState({ dataresponse: items, exportData: items });

      }
      this.setState({ loading: false });
    } catch (errorresponse) {
      console.table("Exception : ", errorresponse);
      this.setState({ loading: false });
    }
  }

  clearHandler = e => {
    this.state.inputvalue = ''
    this.state.moreData = true
    this.loadModelData()
  }

  renderTableData() {

    return this.state.dataresponse.map((model_data, index) => {

      const {
        sk,
        SI_Action_Type
      } = model_data;

      return (
        <tr>
          <td>
            {this.state.editing && this.state.selectedIndex == sk ? (

              <Input style={{ height: 38 }}
                type="text"
                name="SI_Action_Type"
                id="SI_Action_Type"
                defaultValue={SI_Action_Type || ''}
                onChange={this.handleChange}
                required></Input>

            ) : (
                <div>{SI_Action_Type}</div>
              )}
          </td>
          {this.context.user_grp == config.cognito.UPDATE_ROLE ? (
            <td>
              {this.state.editing && this.state.selectedIndex == sk ? (
                <Button style={{ marginBottom: 5, height: 5, width: 110, textAlign: "center", display: "grid", outline: "none", alignContent: "center" }}
                  id={sk}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => this.handlerForSubmit(sk)}>
                  Save
                </Button>
              ) : null}
              <Button style={{ height: 5, marginBottom: 3, width: 110, textAlign: "center", display: "grid", outline: "none", alignContent: "center" }}
                type="button"
                className="btn btn-primary"
                onClick={(e) => this.edit(e, sk, SI_Action_Type)}>
                {this.state.editing && this.state.selectedIndex == sk ? "Cancel" : "Edit"}
              </Button>
              {/* <Button style={{ height: 5, width: 110, textAlign: "center", display: "grid", outline: "none", alignContent: "center" }}
                  id={sk}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => this.handlerForDelete(sk)}>
                  Delete
                </Button> */}
            </td>
          ) : null}
        </tr>
      );
    });
  }

  tohomepage = () => {
    window.location.assign(config.azure.LOGIN_URL);
  };

  createRecord() {
    if (this.context.user_grp == config.cognito.UPDATE_ROLE) {
      return (
        <Link to="/create_standing_instruction_type_form">
          <button type="button" className="btn btn-primary">
            Create Record
          </button>
        </Link>
      );
    } else {
      return null;
    }
  }

  render() {
    if (this.context == undefined) {
      this.tohomepage();
      return false;
    }
    const {
      baseinputValue, inputvalue
    } = this.state
    return (
      <Container>
        <div role="tab" style={{ textAlign: 'center' }}>
          <h1 className="panel-title " style={{ fontSize: 20 }}>
            XSP DataList - Standing Instruction Action Type
            </h1>
        </div>

        <Table style={{ alignContent: "center", width: 600 }} >
          <thead>
            <tr>
              <td><Label>Filtering Method</Label></td>
              <td><Label>{baseinputValue}</Label></td>
            </tr>
            <tr>
              <td>
                <Input
                  type='select'
                  name='baseinputValue'
                  value={baseinputValue}
                  onChange={this.ChangeHandler}
                  style={{ width: '180px' }}
                >
                  <option></option>
                  <option>Standing Instruction Action Type</option>
                </Input>
              </td>
              <td>
                <Input
                  type='text'
                  name='inputvalue'
                  value={inputvalue}
                  onChange={this.ChangeHandlerform}
                  style={{ width: '180px' }}
                />
              </td>
              <td>
                <Button style={{ outline: "none" }} type='submit' className="btn btn-primary" onClick={this.clearHandler}>Clear</Button>
              </td>
              <td>
                <button style={{ outline: "none" }} onClick={() => { this.filteredloadModelData(); }} id="submit-btn" type="button" className="btn btn-primary btn btn-secondary" >Search</button>
              </td>
              <td>

                <button style={{ outline: "none", textAlign: 'right' }} onClick={(e) => { this.exportingData(); }} id="submit-btn" type="button" className="btn btn-primary btn btn-secondary">Export</button>

              </td>
              {this.state.butt_disable ? (
                <td >
                  <ClockLoader size={25} color='blue' loading={this.state.search_loader} />
                </td>) : null}

            </tr>

          </thead>

        </Table>
        {<div style={{ margin: 10 }} >
          {this.createRecord()}
        </div>}

        <div style={{ fontSize: 15 }}>
          <Table className="model-table-margin table table-bordered table-hover tabScrl" style={{ "overflow-y": "scroll" }}  >
            <thead className="tableFixHead">
              <tr>
                <th onClick={this.onsort}>XSP DataList - Standing Instruction Action Type
                    {this.state.sortIcon}
                </th>
                {this.context.user_grp == config.cognito.UPDATE_ROLE ? (
                  <th style={{ width: 220, textAlign: "center" }}>Action</th>
                ) : null}
              </tr>
            </thead>
            <tbody>{this.renderTableData()}</tbody>
          </Table>
          <ScrollToTop smooth showUnder={5} duration={1000} smooth color="#6f00ff" >
            <button
              style={{
                display: "inline-block",
                marginLeft: "15px"
              }} >UP
          </button>
          </ScrollToTop>
          <div className="load-spinner">
            <BeatLoader size={25} color='blue' loading={this.state.loading} />
          </div>
          <div className={this.state.moreData ? 'hidden' : ''}>
            <div className={this.state.search_loader ? 'hidden' : ''}>
              <p><strong>No Records Found.</strong></p>
            </div>
          </div>
        </div>
      </Container>
    );

  }
}

export default StandingInstructionType;
