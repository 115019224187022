import React, { Component } from 'react';
import {withOAuth} from 'aws-amplify-react';
import Header from "../Header/Header";
import config from "../../auth_config";

class ConnectToCognito extends Component {
    handleClick(){
        window.location.assign(config.azure.LOGIN_URL);
    }

    render(){
        return(
            <div className="app">
				<Header />
				{/* <div className="row">
					<div className="col-md-4"></div>
					<div className="col-md-4">
						<button
							onClick={this.handleClick}
							id="continue-to-login"
							type="button"
							className="btn btn-default">
								Sign In
						</button>
					</div>
					<div className="col-md-4"></div>
				</div> */}
			</div>
        )
    }
}

export default withOAuth(ConnectToCognito);