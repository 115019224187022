import React, { Component } from 'react';
import Menubar from '../Menubar/Menubar.js';
import UserContext from '../UserContext';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import RISA from '../Table/RISA/ModelData/RISA';
import HomePage from '../Home/Home.js';
import Auditlog from '../Audit_log/Auditlog';
import config from '../../auth_config';
import StandingInstruction from '../Table/StandingInstruction/ModelData/Standinginstruction'
import InvestmentStrategy from '../Table/DataList/InvestmentStrategy/ModelData/investmentstrategy'
import DataListInvestmentStrategyForm from '../Table/DataList/InvestmentStrategy/Form/DataListinvestmentstrategyform'
import StandinginstructionForm from '../Table/StandingInstruction/Form/Standinginstructions'
import Add_RISA from '../Table/RISA/Form/Add_RISA'
import RecipentInvestmentCreate from '../Table/RecipentInvestmentStrategy/Form/RecipentCreate'
import RecipentInvestmentTable from '../Table/RecipentInvestmentStrategy/ModelData/RecipentInvestmentTable'
import DataListFundManagerForm from '../Table/DataList/FundManager/Form/DataListfundmanagerform'
import FundManager from '../Table/DataList/FundManager/ModelData/fundmanager'
import DataListStandinginstructiontypeForm from '../Table/DataList/Standinginstructiontype/Form/DataListstandinginstructiontypeform'
import StandingInstructionType from '../Table/DataList/Standinginstructiontype/ModelData/standinginstructiontype'
import RedirectCheck from '../Login/Redirect';
import Acknak from '../Table/Ack_Nak/acknak/acknakDetails'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

class Header extends Component {
	static contextType = UserContext;
	render() {
		var display_element = this.context ? <Menubar />:"";
		var name = this.context ? this.context.user_name:"";
		var Logout = this.context ? 'Logout':"Login";
		return (
			<div>
				<BrowserRouter>
					<nav className="navbar navbar-default">
						<div className="container-fluid">
							<div className="navbar-header">
								<button
									type="button"
									className="navbar-toggle collapsed"
									data-toggle="collapse"
									data-target="#bs-example-navbar-collapse-1"
									aria-expanded="false">
									<span className="sr-only">Toggle navigation</span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
								<a className="navbar-principal-brand" href="#"></a>
							</div>
							<div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
								{display_element}
								<ul className="nav navbar-nav navbar-right">
									<li className="navbar-text">{name}</li>
									<li data-toggle="modal" data-target=".bs-example-modal-std">
										<a data-testid="HelpLink" href="#">
											Help
										</a>
									</li>
									<li>
										<a
											data-testid="SignOut"
											onClick={() => {
												window.location.href = this.context ? config.api.REDIRECT_SIGN_OUT:config.azure.LOGIN_URL;
											}}>
											{Logout}
										</a>
									</li>
								</ul>
							</div>
						</div>

						<div
							className="modal fade bs-example-modal-std"
							tabIndex="-1"
							role="dialog"
							aria-labelledby="my-large-modal-label">
							<div className="modal-dialog">
								<div className="modal-content">
									<div className="modal-header">
										<button type="button" className="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">&times;</span>
										</button>
										<h3 className="modal-title" id="static-modal-label">
											Help
										</h3>
									</div>
									<div className="modal-body">
										<ul>
											<li>
												For all issues submit Jira ticket to{' '}
												<a
													href="https://support.principal.com/secure/CreateIssue.jspa?pid=52403"
													target="_blank">
													https://support.principal.com/secure/CreateIssue.jspa?pid=52403
												</a>
											</li>
											<br></br>
											<li>For urgent issues contact 844-225-2323</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</nav>
					<switch>
						<Route exact path="/RISA" component={RISA} />
						<Route exact path="/home" component={HomePage} />
						<Route exact path="/standing_instructions" component={StandingInstruction} />
						<Route exact path="/audit_log" component={Auditlog} />
						<Route exact path="/data_list_investment_strategy" component={InvestmentStrategy} />
						<Route exact path="/create_datalist_investment_strategy" component={DataListInvestmentStrategyForm}/>
						<Route exact path="/investmentStreategy" component={RecipentInvestmentCreate} />
						<Route exact path="/recipent_investment_streategy" component={RecipentInvestmentTable} />
						<Route exact path="/Add_RISA" component={Add_RISA} />
						<Route exact path="/create_standing_instruction_form" component={StandinginstructionForm} />
						<Route exact path="/create_datalist_fund_manager_form" component={DataListFundManagerForm} />
						<Route exact path="/datalist_fundmanager" component={FundManager} />
						<Route exact path="/create_standing_instruction_type_form" component={DataListStandinginstructiontypeForm} />
						<Route exact path="/datalist_StandingInstructionType" component={StandingInstructionType} />
						<Route exact path="/acknakresponse" component={Acknak} />
						{/* <Redirect path="/" to="/home" /> */}	
						<RedirectCheck />
					</switch>	
				</BrowserRouter>
			</div>
		);
	}
}

export default Header;
